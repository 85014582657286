import React from 'react';
import { IconButton } from '@material-ui/core';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import * as Icon from "react-feather";

import store from '../../store';
import history from '../../history';
import isEmpty from '../../validation';
import setAuthToken from '../../validation/setAuthToken';
import AuthActions from '../../actions/AuthActions';
import Axios from 'axios';

import ResetPassword from './Dialog/ResetPassword';

class Login extends React.Component {
    state = {
        openResetPassword: false,
        email: "",
        password: "",
        keepLoggedIn: false,
        showError: false,
        userNotFound: false,
        showPassword: false,
        sbTextContainers: [],
        content: []
    }

    getCustomerDetail = uid => {
        const { keepLoggedIn } = this.state;

        AuthActions.getCustomerDetail({
            uid
        }, (respond, err) => {
            if (!err.isEmpty && !err.customerIdNotFound) {
                const payload = {
                    ...respond,
                }; // Create JWT Payload

                const token = jwt.sign(
                    payload,
                    "SECRET",
                    !keepLoggedIn ? {
                        expiresIn: 3600
                    } : {}
                );

                localStorage.setItem("jwtToken", token);
                // Decode token to get user data
                const decoded = jwt_decode(token);

                store.dispatch(AuthActions.setCurrentUser(decoded));

                // Set token to Auth header
                setAuthToken(token);

                if (!isEmpty(respond.graduation_date)) {
                    history.push("/dashboard");
                } else {
                    history.push("/dashboard/profile");
                }
            }
        })
    }

    handleOpenResetPassword = () => {
        this.setState({
            openResetPassword: true
        })
    }

    handleCloseResetPassword = () => {
        this.setState({
            openResetPassword: false
        })
    }

    handleKeepMeLoggedIn = () => {
        this.setState({
            keepLoggedIn: !this.state.keepLoggedIn
        })
    }

    handleToggleViewPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    handleResendEmail = () => {
        const { email } = this.state;

        if (!isEmpty(email) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {

            store.dispatch({
                type: "SET_LOADING",
                payload: true
            });

            AuthActions.resendEmailCustomer({
                email
            }, (respond, err) => {
                if (!err.isEmpty) {
                    this.onSendEmail(email)
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    onSendEmail = email => {
        this.setState({
            openResetPassword: false,
            sentLinkSuccess: true,
            userNotFound: false,
            email
        })
    }

    onSubmit = e => {
        e.preventDefault();

        const { email, password } = this.state;

        this.setState({
            showError: isEmpty(email) || isEmpty(password),
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                AuthActions.loginCustomer({
                    email,
                    password
                }, (respond, err) => {
                    if (!err.isEmpty && !err.customerIdNotFound) {
                        this.getCustomerDetail(respond);
                    }

                    this.setState({ userNotFound: err.customerIdNotFound })

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                })
            }
        })
    }

    componentWillMount() {
        // Check for token
        if (localStorage.jwtToken) {
            window.location.href = "/dashboard";
        }
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2061449306)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { openResetPassword, email, password, keepLoggedIn, showError, userNotFound, sentLinkSuccess, showPassword, sbTextContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        })
        return (
            <div className="login ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>{content[2063100042]}</h2>
                                <div className="bar"></div>
                                <p>{content[2028041559]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-lg-6 col-md-12">

                            <form onSubmit={this.onSubmit}>
                                <div className="form-container">
                                    <div className="row">
                                        {userNotFound &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Email dan sandi Anda tidak sesuai, mohon dicoba kembali.
                                                </div>
                                            </div>
                                        }
                                        {sentLinkSuccess &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "30%" }}>
                                                        <Icon.Info />
                                                    </div>
                                                    <div style={{ height: 10 }}></div>
                                                    <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                                        <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2046246949]}<span className="required">*</span></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className={`form-control ${showError && isEmpty(email) ? "is-invalid" : ""}`}
                                                    value={email}
                                                    onChange={this.handleInputChange("email")}
                                                />
                                                <div className="invalid-feedback">
                                                    Cantumkan alamat email yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>{content[2068158606]} <span className="required">*</span></label>
                                                    </div>
                                                    <div className="forgot-password col-6">
                                                        <a className="nav-link" onClick={this.handleOpenResetPassword}>{content[2082803913]}</a>
                                                    </div>
                                                </div>
                                                <div className="input-icons">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        className={`form-control ${showError && isEmpty(password) ? "is-invalid" : ""}`}
                                                        value={password}
                                                        onChange={this.handleInputChange("password")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan sandi yang valid.
                                                    </div>
                                                    <IconButton onClick={this.handleToggleViewPassword} className="icon">
                                                        {showPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="keep-logged-in" value={keepLoggedIn} onChange={this.handleKeppMeLoggedIn} />
                                                <label className="form-check-label" htmlFor="keep-logged-in">{content[2086021907]}</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <button className="btn btn-primary submit-btn" type="submit" >
                                                        {content[2077679927]}
                                                    </button>
                                                </div>
                                                {/* <div className="col-lg-12 col-md-12">
                                                    <p className="or-login">or</p>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button className="btn btn-with-google" >
                                                        <img src="/assets/images/google.svg" style={{ width: 20, marginRight: 20, marginTop: -2 }} />
                                                            Login with Google
                                                    </button>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button className="btn btn-with-facebook" >
                                                        <img src="/assets/images/facebook.svg" style={{ width: 20, marginRight: 20, marginTop: -2 }} />
                                                            Login with Facebook
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <p className="bottom-text">
                                                {content[2049679301]}
                                                <a className="nav-link" href="/sign-up">{content[2036090889]}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="shape1">
                        <img src="/assets/images/abstract/shape1.png" alt="shape" />
                    </div>
                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>

                <ResetPassword open={openResetPassword} handleClose={this.handleCloseResetPassword} onSendEmail={this.onSendEmail} />
            </div>
        )
    }
}

export default Login