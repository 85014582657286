import React from 'react';
import * as Icon from "react-feather";
import Complain from '../../Dialog/Complain';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import history from "../../../../history";

class Help extends React.Component {
    state = {
        openComplain: false
    }

    handleOpenComplain = () => {
        this.setState({ openComplain: true })
    }

    handleCloseComplain = () => {
        this.setState({ openComplain: false })
    }

    render() {
        const { openComplain } = this.state;

        return (
            <div >
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <h2>Beritahu kami masalah Anda, kita akan bantu secepatnya</h2>
                            </div>
                        </div>
                    </div>

                    <div className="shape1"><img src="/assets/images/abstract/shape1.png" alt="shape" /></div>
                    <div className="shape2 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                    <div className="shape3"><img src="/assets/images/abstract/shape3.svg" alt="shape" /></div>
                    <div className="shape4"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape5"><img src="/assets/images/abstract/shape5.png" alt="shape" /></div>
                    <div className="shape6 rotateme"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape7"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape8 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                </div>
                <section className="help contact-info-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6" onClick={() => history.push("/admin-chat")}>
                                <div className="contact-info-box">
                                    <div className="icon">
                                        <Icon.MessageSquare />
                                    </div>
                                    <h3>Chat dengan kita</h3>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" onClick={this.handleOpenComplain}>
                                <div className="contact-info-box">
                                    <div className="icon">
                                        <Icon.Mail />
                                    </div>
                                    <h3>Email</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="help ptb-80 bg-f7fafd">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title">
                                    <h2>FAQ TUTOR</h2>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center">
                            <div className="container-expansion-panel">
                                <ExpansionPanel
                                    classes={{
                                        root: "root-expansion-panel"
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon.ChevronDown />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        Berapa lama proses selesksi menjadi Tutor?
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        Proses seleksi berdurasi 2x24 jam.
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel
                                    classes={{
                                        root: "root-expansion-panel"
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon.ChevronDown />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Bagaimana cara saya menjadi Tutor Expert?
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        Untuk menjadi expert tutor Gettutor akan menyeleksi sendiri Tutor mana yang masuk kualifikasi sebagai expert tutor.
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel
                                    classes={{
                                        root: "root-expansion-panel"
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon.ChevronDown />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        Apakah status saya menjadi pegawai tetap setelah lolos seleksi menjadi Tutor?
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        Tidak, apabila anda lolos seleksi, status anda adalah sebagai mitra pengajar Gettutor.
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="help ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title">
                                    <h2>FAQ USER</h2>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center">
                            <div className="container-expansion-panel" style={{ maxWidth: 847 }}>
                                <ExpansionPanel
                                    classes={{
                                        root: "root-expansion-panel"
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon.ChevronDown />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        Apakah saya bisa mengembalikan dana di saldo saya?
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        Tidak, dana yang sudah tertera di saldo tidak dapat direfund kembali
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel
                                    classes={{
                                        root: "root-expansion-panel"
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon.ChevronDown />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >
                                        Bagaimana jika pertanyaan saya belum dijawab oleh tutor pada saat waktu yang telah ditentukan, apakah saldo saya bisa kembali?
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        Bisa, gettutor akan mengembalikan dana yang telah terpakai ke saldo Gettutor Anda apabila tutor tidak menjawab sesuai waktu yang ditentukan di awal.
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="help ptb-80 bg-f7fafd">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title">
                                    <h2>Term & Condition</h2>
                                    <div className="bar"></div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin tempus sagittis velit vitae scelerisque.</p>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                                <p>Business-to-business metrics analytics value proposition funding angel investor entrepreneur alpha ramen equity gamification. Social proof partner network research.</p>
                            </div>
                        </div>
                        <div className="row">
                        </div>
                    </div>
                </section>
                <Complain open={openComplain} handleClose={this.handleCloseComplain} handleConfirm={this.handleCloseComplain} />
            </div>
        )
    }
}

export default Help