import Axios from "axios";
import crypto from 'crypto';
import store from "../store";
import isEmpty from "../validation";
import setAuthToken from "../validation/setAuthToken";

// Set logged in user
const setCurrentUser = decoded => {
    return {
        type: "SET_AUTH",
        payload: decoded
    };
};

export default {
    getOpenInquiry: (config, callback) => {
        var formData = new FormData();
        formData.append("nRLkxq", config.authId);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "DR7LpCF5GN26wVhn",
                    password: "V4UwWvftU8fTRSCZ"
                }
            }
        ).then(respond => {
            let err = {
                isEmpty: isEmpty(respond.data),
                customerIdNotFound: respond.data === "SpLtDH"
            }

            callback(respond.data, err)
        });
    },

    topUpBalance: (
        {
            authId,
            amount,
        },
        callback = () => { }
    ) => {
        var formData = new FormData();
        formData.append("VwxCYt", authId);
        formData.append("EzEZnB", amount);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/UqbHAbH3En55aHam",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p5Hym9fAguNh8FxK",
                    password: "TWEDHGZn7Fmx3wfW"
                }
            }
        ).then(respond => {
            let err = {
                isEmpty: isEmpty(respond.data),
                isError: respond.data === "ERR"
            }

            callback(respond.data, err)
        })
    },

    callbackMidtrans: ({
        snapToken,
        onSuccessCallback,
        onPendingCallback,
        onErrorCallback,
        onCloseCallback
    }) => {
        window.snap.pay(snapToken, {
            onSuccess: async function () {
                if (onSuccessCallback) {
                    onSuccessCallback()
                }
            },
            onPending: function () {
                if (onPendingCallback) {
                    onPendingCallback()
                }
            },
            onError: function () {
                if (onErrorCallback) {
                    onErrorCallback()
                }
            },
            onClose: function () {
                if (onCloseCallback) {
                    onCloseCallback()
                }
            }
        })
    }
}