import React from 'react';
import * as Icon from 'react-feather';
import isEmpty from '../../validation';
import Axios from 'axios';
import store from '../../store';
import crypto from 'crypto';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import setAuthToken from '../../validation/setAuthToken';
import AuthActions from '../../actions/AuthActions';
import VerificationInvalid from './Dialog/VerificationInvalid';
import history from '../../history';
import swal from 'sweetalert';
import { IconButton } from '@material-ui/core';

class ChangeTutorPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            showError: false,
            userNotFound: false,
            changeSuccess: false,
            verificationKeyInvalid: false,
            verificationKeyNotFound: false,
            verifyCompleted: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false
        }
    }

    toggleViewPassword = password => () => {
        this.setState({
            [password]: !this.state[password]
        })
    }

    passwordValidation = password => {
        return isEmpty(password) || String(password).length < 8 || !(/^((?=.*\d)(?=.*?[a-z])(?=.*[A-Z])(?=.*\W).*)$/.test(password)) || password == this.state.oldPassword;
    }

    confirmPasswordValidation = password => {
        const { confirmNewPassword } = this.state;

        return isEmpty(confirmNewPassword) || password !== confirmNewPassword;
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        const { oldPassword, newPassword } = this.state;

        this.setState({
            showError: this.passwordValidation(newPassword) || this.confirmPasswordValidation(newPassword),
        }, () => {
            if (!this.state.showError) {
                let email = this.props.auth.email;

                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                const hashPassword = crypto.createHash('sha256').update(oldPassword).digest('hex');
                var formData = new FormData();
                formData.append("SMxBNK", email)
                formData.append("ZPaFLz", hashPassword)
                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/U6zJjyK8as7cvhrR",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "UEhDPb4WuNb9tvrT",
                            password: "uMXStpz6g5HWZVgR"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "wUySPg") {

                            const newHashPassword = crypto.createHash('sha256').update(newPassword).digest('hex');

                            var formData = new FormData();
                            formData.append("byyGbc", this.props.auth.id);
                            formData.append("eyxgCZ", newHashPassword);

                            Axios.post(
                                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/fyTp9eLM7tabBN7K",
                                formData,
                                {
                                    headers: {
                                        'Content-Type': "multiple/form-data"
                                    },
                                    auth: {
                                        username: "aDcJD8JEaPuEaA6c",
                                        password: "TN3VWbM66LHJzL3b"
                                    }
                                }
                            ).then(respond => {
                                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                                    if (respond.data !== "aKrABp" && respond.data !== "NAKeVu" && respond.data !== "yeQhjr" && respond.data !== "vrCaQp") {
                                        swal("Success!", "Berhasil Ganti Sandi.", "success", {
                                            timer: 3000,
                                        }).then((value) => {
                                            history.push("/tutor-dashboard/profile")
                                        });
                                    }
                                }
                            });
                        }


                        this.setState({
                            userNotFound: respond.data === "wUySPg",
                            isPasswordSame: respond.data === "vrCaQp"
                        })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    handleClose = () => {
        history.push("/")
    }

    render() {
        const { oldPassword, newPassword, confirmNewPassword, showError, userNotFound, changeSuccess, isPasswordSame, showOldPassword, showNewPassword, showConfirmPassword } = this.state;

        return (
            <div className="reset-password ptb-80">
                <div className="container h-100">
                    <div className="row justify-content-center align-items-center" style={{ minHeight: "calc(100vh - 273px)" }}>
                        <div className="col-lg-6 col-md-12">
                            <div className="explanation row h-100 justify-content-center align-items-center">
                                <div>
                                    <h4></h4>
                                    <h2>Ganti Sandi</h2>
                                    <ul>
                                        <li><div><Icon.Check /> <span>Sandi harus terdiri dari 8 karakter</span></div></li>
                                        <li><div><Icon.Check /> <span>Sandi harus menggunakan campuran huruf kapital, huruf kecil, angka & simbol.</span></div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                    <div className="row">
                                        {userNotFound &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Sandi Salah.
                                            </div>
                                            </div>
                                        }
                                        {changeSuccess &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.Info /><div style={{ width: 10 }}> </div>Berhasil Ganti Sandi.
                                            </div>
                                            </div>
                                        }
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Ketik sandi lama Anda<span className="required">*</span></label>
                                                <div className="input-icons">
                                                    <input
                                                        type={showOldPassword ? "text" : "password"}
                                                        name="old-password"
                                                        className={`form-control ${showError && isEmpty(oldPassword) ? "is-invalid" : ""}`}
                                                        value={oldPassword}
                                                        onChange={this.handleChange("oldPassword")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan sandi lama yang valid.
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword("showOldPassword")} className="icon">
                                                        {showOldPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>ketik sandi terbaru Anda<span className="required">*</span></label>
                                                <div className="input-icons">
                                                    <input
                                                        type={showNewPassword ? "text" : "password"}
                                                        name="new-password"
                                                        className={`form-control ${showError && this.passwordValidation(newPassword) ? "is-invalid" : ""}`}
                                                        value={newPassword}
                                                        onChange={this.handleChange("newPassword")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {(isPasswordSame || newPassword == oldPassword) ?
                                                            "Sandi baru tidak bisa sama dengan yang lama." :
                                                            "Cantumkan sandi baru yang valid."
                                                        }
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword("showNewPassword")} className="icon">
                                                        {showNewPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Konfirmasi sandi terbaru <span className="required">*</span></label>
                                                <div className="input-icons">
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        name="confirm-new-password"
                                                        className={`form-control ${showError && this.confirmPasswordValidation(newPassword) ? "is-invalid" : ""}`}
                                                        value={confirmNewPassword}
                                                        onChange={this.handleChange("confirmNewPassword")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {(!isEmpty(confirmNewPassword) && newPassword !== confirmNewPassword) ?
                                                            "Konfirmasi sandi tidak sesuai" :
                                                            "Cantumkan konfirmasi sandi baru yang valid."
                                                        }
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword("showConfirmPassword")} className="icon">
                                                        {showConfirmPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button className="btn btn-primary submit-btn" type="submit" >
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>
            </div >
        )
    }
}

export default ChangeTutorPassword