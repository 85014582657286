import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ResourceReducer from "./ResourceReducer";
import ClassReducer from "./ClassReducer";

const defaultMasterData = {
    auth: null,
    isLoading: false,
};

const MasterDataReducer = (state = defaultMasterData, action) => {

    switch (action.type) {
        case "SET_AUTH":
            return {
                ...state,
                auth: action.payload
            }

        case "UPDATE_PROFILE":
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...action.payload
                }
            }

        case "SET_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }

        default:
            return state;
    }
}


const masterDataPersistConfig = {
    key: "masterData",
    storage,
    whitelist: []
}

const rootReducer = combineReducers({
    masterData: persistReducer(masterDataPersistConfig, MasterDataReducer),
    resourceReducer: ResourceReducer,
    classReducer: ClassReducer
});

export default rootReducer;