import React from 'react'
import { Grid } from '@material-ui/core'
import TopUpBalance from '../../Dialog/TopUpBalance';
import Refund from '../../Dialog/Refund';
import isEmpty from '../../../../validation';
import { connect } from "react-redux";
import Axios from 'axios';
import AuthActions from '../../../../actions/AuthActions';
import store from '../../../../store';
import setAuthToken from '../../../../validation/setAuthToken';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import * as Icon from "react-feather";
import swal from 'sweetalert';
import CustomerActions from '../../../../actions/CustomerActions';
import PaymentPending from '../../Dialog/PaymentPending';

class Balance extends React.Component {
    state = {
        openTopUpBalance: false,
        openRefund: false,
        topUpBalanceSuccess: false
    }

    handleOpen = () => {
        this.setState({
            openTopUpBalance: true
        })
    }

    handleClose = () => {
        this.setState({
            openTopUpBalance: false
        })
    }

    handleTopUpBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        CustomerActions.topUpBalance({
            authId: this.props.auth.id,
            amount
        }, (respond, err) => {
            if (!err.isEmpty && !err.isError) {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                CustomerActions.callbackMidtrans({
                    snapToken: respond.token,
                    onSuccessCallback: () => {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                            openSetPrice: true,
                        })
                    },
                    onPendingCallback: () => {
                        _this.setState({
                            openPaymentPending: true,
                        })
                    },
                    onErrorCallback: () => {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            this.setState({
                openTopUpBalance: false,
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        })
    }

    handleOpenRefund = () => {
        this.setState({
            openRefund: true
        })
    }

    handleCloseRefund = () => {
        this.setState({
            openRefund: false
        })
    }

    numberWithCommas = str => {
        return String(str).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        //formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { auth } = this.props;
        const { openTopUpBalance, openRefund, topUpBalanceSuccess, successResendEmail, openPaymentPending } = this.state;
        const { is_verified } = auth;

        return (
            <div className="balance container">
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <h2>Saldo</h2>
                            </div>
                        </div>
                    </div>

                    <div className="shape1"><img src="/assets/images/abstract/shape1.png" alt="shape" /></div>
                    <div className="shape2 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                    <div className="shape3"><img src="/assets/images/abstract/shape3.svg" alt="shape" /></div>
                    <div className="shape4"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape5"><img src="/assets/images/abstract/shape5.png" alt="shape" /></div>
                    <div className="shape6 rotateme"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape7"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape8 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                </div>
                <div className="content ptb-80" style={{ paddingTop: (successResendEmail || (!successResendEmail && isEmpty(is_verified))) && 50 }}>
                    <div className="container">
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <div className="row">
                                    <div className="col-12">
                                        {successResendEmail &&
                                            <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                                    <Icon.Info />
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                                <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                                    <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                                </span>
                                            </div>
                                        }
                                        {!successResendEmail && isEmpty(is_verified) &&
                                            <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                                    <Icon.Info />
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                                <span>
                                                    Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                                    <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <div className="current-balance">
                                    <div className="title">
                                        <h6>Saldo Anda saat ini</h6>
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <Grid container spacing={1} alignItems="center">
                                            {topUpBalanceSuccess &&
                                                <Grid item xs={12} style={{ marginTop: 20 }}>
                                                    <div className="alert alert-success alert-dismissible fade show" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                        <Icon.Info /><div style={{ width: 10 }}> </div>Berhasil top up saldo.
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                                <h5 >IDR {!isEmpty(parseInt(this.props.auth.balance)) ? this.numberWithCommas(this.props.auth.balance) : 0}</h5>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: 20 }} container justify="center" alignItems="center">
                                                <button className={`btn btn-primary ${isEmpty(is_verified) ? "disable-btn" : ""}`} onClick={this.handleOpen} disabled={isEmpty(is_verified)}>Top up saldo</button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <TopUpBalance open={openTopUpBalance} handleClose={this.handleClose} handleTopUpBalance={this.handleTopUpBalance} />
                <Refund open={openRefund} handleClose={this.handleCloseRefund} handleConfirm={this.handleCloseRefund} />
                <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth
    }
}

export default connect(mapStateToProps)(Balance);