import React from 'react';
import * as Icon from 'react-feather';
import isEmpty from '../../validation';
import Axios from 'axios';
import store from '../../store';
import crypto from 'crypto';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import setAuthToken from '../../validation/setAuthToken';
import AuthActions from '../../actions/AuthActions';
import VerificationInvalid from './Dialog/VerificationInvalid';
import history from '../../history';
import swal from 'sweetalert';
import { IconButton } from '@material-ui/core';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            isPasswordSame: false,
            showError: false,
            userNotFound: false,
            changeSuccess: false,
            verificationKeyInvalid: false,
            verificationKeyNotFound: false,
            verifyCompleted: false,
            user: null,
            showPassword: false,
            showRetypePassword: false
        };
    }

    toggleViewPassword = password => () => {
        this.setState({
            [password]: !this.state[password]
        })
    }

    getUserDetail = uid => {
        var formData = new FormData();
        formData.append("UqADvv", uid);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WFVgZQZ6sd73R5HF",
                    password: "ef5hKtveCEpWL69q"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "VhUyMY") {
                    this.setState({
                        user: respond.data
                    })
                }
            }
        });
    }

    componentWillMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        if (!isEmpty(this.props.match.params)) {
            var formData = new FormData();
            formData.append("gZfUnK", this.props.match.params.id)
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/a8CzukPWJQEeNdz8",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "VpY3Mu2ZdSL2ZSbp",
                        password: "sepLrxj569BBUAGR"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "mKFqBT" && !respond.data !== "kehBch") {
                        this.getUserDetail(respond.data)
                    }
                }

                this.setState({
                    verificationKeyNotFound: isEmpty(respond.data) || respond.data === "mKFqBT",
                    verificationKeyInvalid: isEmpty(respond.data) || respond.data === "kehBch",
                    verifyCompleted: true
                })

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    passwordValidation = password => {
        return isEmpty(password) || String(password).length < 8 || !(/^((?=.*\d)(?=.*?[a-z])(?=.*[A-Z])(?=.*\W).*)$/.test(password));
    }

    confirmPasswordValidation = password => {
        const { confirmNewPassword } = this.state;

        return isEmpty(confirmNewPassword) || password !== confirmNewPassword;
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            isPasswordSame: false
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        const { newPassword, user, isPasswordSame } = this.state;

        this.setState({
            showError: this.passwordValidation(newPassword) || this.confirmPasswordValidation(newPassword) || isPasswordSame,
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                const newHashPassword = crypto.createHash('sha256').update(newPassword).digest('hex');

                var formData = new FormData();
                formData.append("ebVnkW", user.id);
                formData.append("ZQjNGe", newHashPassword);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/jHU8NJPyy78NQvhS",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "WQh8wmHbUE23Udvb",
                            password: "StqQc3BtPcpAAfbw"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "YuXfDL" && respond.data !== "mFzBqf" && respond.data !== "nUgRyV" && respond.data !== "eEySAt" && respond.data !== "zrhSQZ" && respond.data !== "STqkdk") {

                            swal("Berhasil!", "Anda berhasil mengganti sandi!", "success", {
                                timer: 3000,
                            }).then((value) => {
                                this.setState({ changeSuccess: true, oldPassword: "", newPassword: "", confirmNewPassword: "" })
                                history.push("/login")
                            });
                        }

                        this.setState({
                            isPasswordSame: respond.data === "YuXfDL"
                        })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    handleClose = () => {
        history.push("/")
    }

    render() {
        const { oldPassword, newPassword, confirmNewPassword, showError, userNotFound, changeSuccess, verificationKeyInvalid, verificationKeyNotFound, verifyCompleted, isPasswordSame, showPassword, showRetypePassword } = this.state;

        return (
            <div className="reset-password ptb-80">
                <div className="container h-100">
                    {verifyCompleted && (verificationKeyInvalid || verificationKeyNotFound) &&
                        <div className="row justify-content-md-center">
                            <VerificationInvalid open={verificationKeyInvalid || verificationKeyNotFound} verificationKeyInvalid={verificationKeyInvalid} verificationKeyNotFound={verificationKeyNotFound} handleClose={this.handleClose} errorMessage="Link reset sandi tidak valid." />
                        </div>
                    }
                    {verifyCompleted && !verificationKeyInvalid && !verificationKeyNotFound &&
                        <div className="row justify-content-center align-items-center" style={{ minHeight: "calc(100vh - 273px)" }}>
                            <div className="col-lg-6 col-md-12">
                                <div className="explanation row h-100 justify-content-center align-items-center">
                                    <div>
                                        <h4></h4>
                                        <h2>Ganti Sandi</h2>
                                        <ul>
                                            <li><div><Icon.Check /> <span>Sandi harus terdiri dari 8 karakter</span></div></li>
                                            <li><div><Icon.Check /> <span>Sandi harus menggunakan campuran huruf kapital, huruf kecil, angka & simbol.</span></div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-container">
                                        <div className="row">
                                            {userNotFound &&
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="alert alert-danger" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                        <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Sandi Salah.
                                                    </div>
                                                </div>
                                            }
                                            {changeSuccess &&
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                        <Icon.Info /><div style={{ width: 10 }}> </div>Berhasil Ganti Sandi.
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Tulis sandi baru Anda<span className="required">*</span></label>
                                                    <div className="input-icons">
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            name="new-password"
                                                            className={`form-control ${showError && (this.passwordValidation(newPassword) || isPasswordSame) ? "is-invalid" : ""}`}
                                                            value={newPassword}
                                                            onChange={this.handleChange("newPassword")}
                                                            autoFocus
                                                        />
                                                        <div className="invalid-feedback" style={{ display: showError && (this.passwordValidation(newPassword) || isPasswordSame) && "block" }}>
                                                            {isPasswordSame ?
                                                                "Sandi baru tidak bisa sama dengan yang lama." :
                                                                "Cantumkan sandi baru yang valid."
                                                            }
                                                        </div>
                                                        <IconButton onClick={this.toggleViewPassword("showPassword")} className="icon">
                                                            {showPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Konfirmasi sandi baru Anda <span className="required">*</span></label>
                                                    <div className="input-icons">
                                                        <input
                                                            type={showRetypePassword ? "text" : "password"}
                                                            name="confirm-new-password"
                                                            className={`form-control ${showError && this.confirmPasswordValidation(newPassword) ? "is-invalid" : ""}`}
                                                            value={confirmNewPassword}
                                                            onChange={this.handleChange("confirmNewPassword")}
                                                        />
                                                        <IconButton onClick={this.toggleViewPassword("showRetypePassword")} className="icon">
                                                            {showRetypePassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                        </IconButton>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: showError && this.confirmPasswordValidation(newPassword) && "block" }}>
                                                        {(!isEmpty(confirmNewPassword) && newPassword !== confirmNewPassword) ?
                                                            "Konfirmasi sandi tidak sesuai" :
                                                            "Cantumkan konfirmasi sandi baru yang valid."
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <button className="btn btn-primary submit-btn" type="submit" >
                                                    Simpan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }

                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>
            </div >
        )
    }
}

export default ResetPassword