import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Grid from "@material-ui/core/Grid";
import ContactSoon from '../../Dialog/ContactSoon';
import history from "../../../../history";
import * as Icon from "react-feather";
import isEmpty from '../../../../validation';
import store from '../../../../store';
import Axios from 'axios';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import draftjsToMarkdown from 'draftjs-to-markdown';
import Select from '../../../../reusable-components/Inputs/Select';
import Util from '../../../../util';
import swal from 'sweetalert';
import { DropzoneArea } from 'material-ui-dropzone';
import { Chip } from '@material-ui/core';
import EquationEditor from "equation-editor-react";
import BalanceInsufficient from '../../Dialog/BalanceInsufficient';
import TopUpBalance from '../../Dialog/TopUpBalance';
import setAuthToken from '../../../../validation/setAuthToken';
import AuthActions from '../../../../actions/AuthActions';
import moment from 'moment';
import TermConditionExpertTutor from '../../Dialog/TermConditionExpertTutor';
import PaymentPending from '../../Dialog/PaymentPending';

class AskExpertTutor extends React.Component {
    state = {
        editorState: EditorState.createEmpty(),
        openTopUpBalance: false,
        openContactSoon: false,
        successResendEmail: false,
        subjects: [],
        subject: null,
        subjectInvalid: false,
        time: "",
        unit: "hour",
        showError: false,
        type: 2010126780,
        typeInvalid: false,
        balanceInsufficient: false,
        topUpBalanceSuccess: false,
        files: [],
        content: "",
        openTermCondition: false
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kkLdrk", 9999)
        formData.append("wqWWWe", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/X7asVxHWTYTEeGmV",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "RRq6sjw9j3AWrbYf",
                    password: "zm8YBRvkMVkTQawm"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CktZKk" && respond.data !== "JrasLA") {
                this.setState({
                    subjects: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    onEditorChange = editorState => {
        this.setState({
            editorState,
        });
    }

    handleOpenContactSoon = () => {
        this.setState({
            openContactSoon: true
        })
    }

    handleCloseContactSoon = () => {
        history.push("/dashboard")
        this.setState({
            openContactSoon: false
        })
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSelectChange = name => value => {
        const { time } = this.state;

        this.setState({
            [name]: value
        })

        if (name === "unit") {
            this.setState({
                time: value === "month" ? time <= 12 ? time : 12 : value === "day" ? time <= 365 ? time : 365 : time <= 24 ? time : 24
            })
        }
    }

    handleInputChange = name => e => {
        this.setState({
            [name]: e.target.value,
            unit: this.state.unit === "hour" && e.target.value != 2010126780 ? "day" : this.state.unit
        })
    }

    handleTimeChange = name => e => {
        const { type } = this.state;
        const re = /^[0-9\b]+$/;
        const value = e.target.value
        const unit = String(this.state.unit).toLowerCase();

        const processedValue = unit === "month" ? value <= 12 ? value : 12 : unit === "day" ? value <= 365 ? value : 365 : value <= 24 ? value : 24

        if (value === '' || re.test(value)) {
            this.setState({
                [name]: processedValue
            })
        }
    }

    getBalance = () => {
        const { auth } = this.props;

        if (auth) {
            var formData = new FormData();
            formData.append("UqADvv", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "WFVgZQZ6sd73R5HF",
                        password: "ef5hKtveCEpWL69q"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "VhUyMY") {

                        const payload = {
                            ...this.props.auth,
                            ...respond.data,
                        }; // Create JWT Payload

                        delete payload["exp"];

                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: oldDecoded.exp }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: respond.data
                        })
                    }
                }
            });
        }
    }

    handleSendInquiry = rate => {
        const { editorState, content, files, time, subject, type, unit } = this.state;
        const rawContent = editorState.getCurrentContent().getPlainText();

        this.setState({
            showError: (isEmpty(unit) || this.isValidTime()) || isEmpty(subject) || isEmpty(rawContent) || isEmpty(type)
        }, () => {
            if (!this.state.showError) {

                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("qQknLY", this.props.auth.id);
                formData.append("ubkHhj", subject);
                formData.append("AHJgjb", type);
                formData.append("hCQXaX", rawContent);
                formData.append("rqkBGJ", parseInt(rate));
                formData.append("eznWDf", String(unit).toLowerCase() === "month" ? parseInt(time) : 0);
                formData.append("wApcUU", String(unit).toLowerCase() === "day" ? parseInt(time) : 0);
                formData.append("sraDMU", String(unit).toLowerCase() === "hour" ? parseInt(time) : 0);
                formData.append("WxDtuj", files[0]);
                formData.append("cPScVZ", files[1]);
                formData.append("PYdveE", files[2]);
                formData.append("XgdSGP", files[3]);
                formData.append("nXwHZN", files[4]);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/HpZ3za6B6XW3DVPy",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "mxnhsBuHQMSa9x2L",
                            password: "mxnhsBuHQMSa9x2L"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "YfWwDx" && respond.data !== "MQaZaU" && respond.data !== "ASyLrr" && respond.data !== "wGsdHg" && respond.data !== "qCJJjQ") {
                        this.getBalance();

                        this.setState({
                            openContactSoon: true
                        })
                    }

                    this.setState({
                        openSetPrice: respond.data === "YfWwDx",
                        subjectInvalid: respond.data === "ASyLrr",
                        typeInvalid: respond.data === "wGsdHg",
                        showError: respond.data === "ASyLrr" || respond.data === "wGsdHg",
                        topUpBalanceSuccess: false,
                        // balanceInsufficient: respond.data === "qCJJjQ" || respond.data === "YfWwDx"
                    })

                    if (respond.data === "qCJJjQ" || respond.data === "YfWwDx") {
                        let minBalance = 0;

                        [
                            {
                                id: 2022400576,
                                name: "Thesis",
                                min: 5000000,
                                max: 8000000,
                            },
                            {
                                id: 2088358696,
                                name: "Skripsi",
                                min: 2000000,
                                max: 3000000
                            },
                            {
                                id: 2018889419,
                                name: "Disertasi",
                                min: 1000000,
                                max: 5000000
                            },
                            {
                                id: 2098194258,
                                name: "Journal",
                                min: 4000000,
                                max: 9000000
                            },
                            {
                                id: 2010126780,
                                name: "Tugas",
                                min: 100000,
                            }
                        ].filter(item => item.id == type).forEach(inquiryType => {
                            minBalance = inquiryType.min
                        })

                        swal(
                            "Error!",
                            `Saldo tidak cukup \n Minimal saldo adalah ${Util.currencyFormat(minBalance)}`,
                            "error",
                            {
                                buttons: {
                                    topUpBalance: "Top Up Saldo",
                                    cancel: "Batalkan"
                                },
                                className: "balance-insufficient"
                            },
                        ).then((value) => {
                            if (value) {
                                this.handleTopUpBalance()
                            }
                        });
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                })
            }
        })
    }

    isValidTime = () => {
        const { unit, time, type } = this.state;
        let result = true;

        if (unit === "month") {
            if (time >= 1 && time <= 12) {
                result = false;
            }
        } else if (unit === "day") {
            if (type == 2010126780) {
                if (time >= 1 && time <= 365) {
                    result = false;
                }
            } else {
                if (time >= 30 && time <= 365) {
                    result = false;
                }
            }
        } else if (unit === "hour") {
            if (time >= 6 && time <= 24) {
                result = false;
            }
        }

        return result;
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = currentIndex => () => {
        this.setState({
            files: this.state.files.filter((item, index) => index !== currentIndex)
        })
    }

    handleTopUpBalance = () => {
        this.setState({
            balanceInsufficient: false,
            openTopUpBalance: true
        })
    }

    handleCloseBalanceInsufficient = () => {
        this.setState({
            balanceInsufficient: false
        })
    }

    handleCloseTopUpBalance = () => {
        this.setState({
            openTopUpBalance: false
        })
    }

    handleSetBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VwxCYt", this.props.auth.id);
        formData.append("EzEZnB", amount);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/UqbHAbH3En55aHam",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p5Hym9fAguNh8FxK",
                    password: "TWEDHGZn7Fmx3wfW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ERR") {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                window.snap.pay(respond.data.token, {
                    onSuccess: async function (result) {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                        })
                    },
                    onPending: function (result) {
                        _this.setState({
                            openPaymentPending: true,
                        })
                    },
                    onError: function (result) {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    },
                    onClose: function () {
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.setState({
            openTopUpBalance: false,
        })
    }

    handleClickTermCondition = e => {
        e.preventDefault();

        this.setState({
            openTermCondition: true
        })
    }

    handleCloseTermAndCondition = () => {
        this.setState({
            openTermCondition: false
        })
    }

    render() {
        const {
            editorState,
            openContactSoon,
            successResendEmail,
            subjects,
            showError,
            type,
            typeInvalid,
            subject,
            subjectInvalid,
            time,
            files,
            unit,
            balanceInsufficient,
            topUpBalanceSuccess,
            openTopUpBalance,
            openTermCondition,
            openPaymentPending
        } = this.state;
        const { auth } = this.props;
        const { is_verified } = auth;

        const units = [
            type == 2010126780 && { value: 'hour', label: 'Jam' },
            { value: 'day', label: 'Hari' },
            { value: 'month', label: 'Bulan' },
        ].filter(item => item);

        const inquiryTypes = [
            {
                id: 2022400576,
                name: "Thesis",
                min: 5000000,
                max: 8000000,
            },
            {
                id: 2088358696,
                name: "Skripsi",
                min: 2000000,
                max: 3000000
            },
            {
                id: 2018889419,
                name: "Disertasi",
                min: 1000000,
                max: 5000000
            },
            {
                id: 2098194258,
                name: "Journal",
                min: 4000000,
                max: 9000000
            },
            {
                id: 2010126780,
                name: "Tugas",
                min: 100000,
            }
        ]

        return (
            <div className="container">
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <h2>Tanya Expert Tutor</h2>
                            </div>
                        </div>
                    </div>

                    <div className="shape1"><img src="/assets/images/abstract/shape1.png" alt="shape" /></div>
                    <div className="shape2 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                    <div className="shape3"><img src="/assets/images/abstract/shape3.svg" alt="shape" /></div>
                    <div className="shape4"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape5"><img src="/assets/images/abstract/shape5.png" alt="shape" /></div>
                    <div className="shape6 rotateme"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape7"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape8 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                </div>
                <div className="content ptb-80">
                    <div className="container">
                        {successResendEmail &&
                            <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                    <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                </span>
                            </div>
                        }
                        {!successResendEmail && isEmpty(is_verified) &&
                            <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>
                                    Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                    <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                </span>
                            </div>
                        }

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <h4 style={{ marginBottom: 15 }}>Tanyakan kepada Expert Tutor kami:</h4>

                                    <div className="what-you-need">
                                        {inquiryTypes.map(inquiryType =>
                                            <div key={inquiryType.id} className="radio">
                                                <input type="radio" id={inquiryType.id} name="radio-group" value={inquiryType.id} checked={type == inquiryType.id} onChange={this.handleInputChange("type")} />
                                                <label htmlFor={inquiryType.id}>{inquiryType.name}</label>
                                                <div className="price">
                                                    {!isEmpty(inquiryType.max) &&
                                                        <>
                                                            <span style={{ fontSize: 10 }}>Rp. {Util.numberWithCommas(inquiryType.min)}</span>
                                                            <span style={{ fontSize: 10, margin: "0px 10px" }}> - </span>
                                                            <span style={{ fontSize: 10 }}>Rp. {Util.numberWithCommas(inquiryType.max)}</span>
                                                        </>
                                                    }
                                                    {isEmpty(inquiryType.max) &&
                                                        <span style={{ fontSize: 10 }}>Min. Rp. {Util.numberWithCommas(inquiryType.min)}</span>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="invalid-feedback" style={{ display: showError && isEmpty(type) || typeInvalid ? "block" : "none", marginTop: -5, fontSize: 10, color: "red" }}>
                                        Cantumkan jenis yang valid
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{ border: "1px rgba(189, 204, 215, 0.8) solid", padding: 15, backgroundColor: "#ffffff" }}>
                                    <div className="row">
                                        <div className="col-12">
                                            {/* <EquationEditor
                                                value={content}
                                                onChange={value => this.setState({ content: value })}
                                                autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
                                                autoOperatorNames=""
                                            /> */}
                                            <Editor
                                                toolbarHidden
                                                placeholder="Tuliskan pertanyaanmu secara detail untuk mempermudah Tutor kami menjawab"
                                                editorState={editorState}
                                                wrapperClassName={`wrapper-editor ${showError && isEmpty(editorState.getCurrentContent().getPlainText()) ? "text-editor-invalid" : ""}`}
                                                editorClassName={`text-area-editor ${showError && isEmpty(editorState.getCurrentContent().getPlainText()) ? "text-editor-invalid" : ""}`}
                                                toolbarClassName="toolbar-editor"
                                                onEditorStateChange={this.onEditorChange}
                                            />
                                            <div className="invalid-feedback" style={{ display: showError && isEmpty(editorState.getCurrentContent().getPlainText()) && "block" }}>
                                                Cantumkan konten yang valid.
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div style={{ marginTop: 10 }}>
                                                <label>Files: </label>
                                                <DropzoneArea
                                                    dropzoneClass={`custom-dropzone ${files.length >= 5 ? "hide" : ""}`}
                                                    dropzoneText="Unduh File/dokumen disini"
                                                    filesLimit={5}
                                                    initialFiles={files}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewText=""
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ variant: "outlined", style: { color: "#223F63", borderColor: "#223F63" } }}
                                                    onChange={this.handleUpload.bind(this)}
                                                    showAlerts={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div style={{ marginTop: 30 }}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={12} sm={12} md={9}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <div className={`select-box ${showError && isEmpty(subject) || subjectInvalid ? "is-invalid" : ""}`}>
                                                                    <Select
                                                                        options={subjects.map(subject => ({ value: subject.id, label: subject.name }))}
                                                                        placeholder="Subject"
                                                                        noOptionsMessage={() => {
                                                                            return 'Tidak ada pilihan'
                                                                        }}
                                                                        value={subject}
                                                                        onChange={this.handleSelectChange("subject")}
                                                                        error={showError && isEmpty(subject) || subjectInvalid}
                                                                    />
                                                                </div>
                                                                <div className="invalid-feedback" style={{ display: showError && (isEmpty(subject) || subjectInvalid) && "block" }}>
                                                                    Cantumkan subject yang valid.
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                                                            <input
                                                                                type="text"
                                                                                name="time"
                                                                                placeholder="Waktu"
                                                                                className={`form-control ${(showError && (isEmpty(time) || this.isValidTime())) ? "is-invalid" : ""}`}
                                                                                value={time}
                                                                                onChange={this.handleTimeChange("time")}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback" style={{ display: showError && (isEmpty(time) || this.isValidTime()) && "block" }}>
                                                                                {unit === "month" ?
                                                                                    !(time >= 1 && time <= 12) && "Minimal waktu adalah 1 bulan dan maksimal waktu adalah 12 bulan" :
                                                                                    unit === "day" ?
                                                                                        type == 2010126780 ?
                                                                                            !(time >= 1 && time <= 365) && "Minimal waktu adalah 1 hari dan maksimal waktu adalah 365 hari" :
                                                                                            !(time >= 30 && time <= 365) && "Minimal waktu adalah 30 hari and maksimal waktu adalah 365 hari" :
                                                                                        unit === "hour" ?
                                                                                            !(time >= 6 && time <= 24) && "Minimal waktu adalah 6 jam dan maksimal waktu adalah 24 jam" :
                                                                                            "Cantumkan waktu yang valid."
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Select
                                                                            options={units}
                                                                            placeholder="Unit"
                                                                            noOptionsMessage={() => {
                                                                                return 'Tidak ada pilihan'
                                                                            }}
                                                                            value={unit}
                                                                            onChange={this.handleSelectChange("unit")}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} container justify="flex-end">
                                                        <button className={`btn btn-primary ${isEmpty(is_verified) ? "disable-btn" : ""}`} onClick={this.handleSendInquiry} disabled={isEmpty(is_verified)}>KIRIM</button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ textAlign: "right", marginTop: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span style={{ fontWeight: 500 }}>Kami akan segera membalas pesan Anda</span>
                                <span style={{ fontWeight: 500 }}><a className="nav-link" className="nav-link" href="#" onClick={this.handleClickTermCondition}>Syarat dan Ketentuan</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactSoon open={openContactSoon} handleClose={this.handleCloseContactSoon} />
                <BalanceInsufficient open={balanceInsufficient} handleClose={this.handleCloseBalanceInsufficient} handleTopUpBalance={this.handleTopUpBalance} />
                <TopUpBalance open={openTopUpBalance} handleClose={this.handleCloseTopUpBalance} handleTopUpBalance={this.handleSetBalance} />
                <TermConditionExpertTutor open={openTermCondition} handleClose={this.handleCloseTermAndCondition} />
                <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
            </div >
        )
    }
}

export default AskExpertTutor