import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import isEmpty from '../../../validation';
import store from '../../../store';
import Axios from 'axios';

class CreateNewFolder extends React.Component {
    state = {
        name: "",
        showError: false,
        nameInvalid: false
    }

    handleCreateNewFolder = e => {
        e.preventDefault();

        const { activeClass } = this.props;
        const { name, nameInvalid } = this.state;

        this.setState({
            showError: isEmpty(name) || nameInvalid
        }, () => {
            if (!this.state.showError) {

                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("hzLaVx", activeClass.id);
                formData.append("PHnxpb", name);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Dby4gG8HMuZDH62n",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "jceLEHBrghC9WZrc",
                            password: "pDrjLJEmMqt8P8gZ"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "rkvsZK") {
                            if (this.props.handleCreateNewFolder) {
                                this.props.handleCreateNewFolder(respond.data, name)
                            }

                            this.setState({
                                name: "",
                                showError: false
                            })
                        }

                        store.dispatch({
                            type: "SET_LOADING",
                            payload: false
                        });
                    }
                })
            }
        })
    }

    handleInputChange = name => e => {
        const { activeClass } = this.props;
        this.setState({
            [name]: e.target.value,
            nameInvalid: activeClass.folders.filter(item => !item.is_deleted).some(item => item.name.trim() === e.target.value.trim())
        })
    }

    render() {
        const { open, handleClose } = this.props;
        const { name, showError, nameInvalid } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Buat Folder Baru</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20 }}>
                        <form onSubmit={this.handleCreateNewFolder}>
                            <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Nama Folder"
                                    className={`form-control ${((showError && isEmpty(name)) || nameInvalid) ? "is-invalid" : ""}`}
                                    value={name}
                                    onChange={this.handleInputChange("name")}
                                    autoComplete="off"
                                    autoFocus
                                />
                                <div className="invalid-feedback">
                                    {nameInvalid ? "Nama yang digunakan sudah terpakai." : "Cantumkan nama yang valid."}
                                </div>
                            </div>
                        </form>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-light" onClick={handleClose}>Batalkan</button>
                    <button className="btn btn-primary" onClick={this.handleCreateNewFolder}>Buat</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default CreateNewFolder;