import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import Util from '../../../util';
import isEmpty from '../../../validation';
import * as Icon from "react-feather";

class SetPrice extends React.Component {
    state = {
        rate: "",
        rateInvalid: false,
        showError: false
    }

    handleChangeRate = event => {
        const value = Util.numberWithoutCommas("0" + event.target.value.toString().trim());

        this.setState({
            rate: value,
        })
    }

    handleSendInquiry = () => {
        const { rate } = this.state;

        this.setState({
            showError: isEmpty(rate) || rate < 15000
        }, () => {
            if (!this.state.showError) {
                this.setState({
                    rate: "",
                    showError: false
                })

                if (this.props.handleSendInquiry) {
                    this.props.handleSendInquiry(rate)
                }
            }
        })
    }

    render() {
        const { rate, showError } = this.state;
        const { open, topUpBalanceSuccess, balanceInsufficient, handleClose, handleOpenTopUpBalance } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Tentukan harga untuk pekerjaan Anda</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        {topUpBalanceSuccess &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <Icon.Info /><div style={{ width: 10 }}> </div>Berhasil top up saldo.
                                <button className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        {balanceInsufficient &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Saldo tidak cukup.
                                <button className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="form-group">
                                    <label style={{ fontSize: 20 }}>Rate:</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ height: 35 }}>
                                            <span className="input-group-text" id="currency">IDR</span>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Masukan rate"
                                            value={Util.numberWithCommas(rate)}
                                            name="rate"
                                            onChange={this.handleChangeRate}
                                            className={`form-control ${showError && (isEmpty(rate) || rate < 15000) ? "is-invalid" : ""}`}
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        <div className="invalid-feedback">
                                            {!isEmpty(rate) && rate < 15000 ?
                                                "Rate tidak boleh kurang dari IDR 15.000" :
                                                "Cantumkan rate yang valid."
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-danger" onClick={handleOpenTopUpBalance}>Top Up Saldo</button>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button className="btn btn-light" onClick={handleClose}>Batalkan</button>
                        <div style={{ padding: 4 }}></div>
                        <button className="btn btn-primary" onClick={this.handleSendInquiry}>OK</button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default SetPrice;