import React from 'react';
import { Grid, IconButton, Chip } from '@material-ui/core';
import * as Icon from "react-feather";
import CreateNewClass from '../../Dialog/CreateNewClass';
import SuccessCreateNewClass from '../../Dialog/SuccessCreateNewClass';
import { DropzoneArea } from 'material-ui-dropzone';
import CreateNewFolder from '../../Dialog/CreateNewFolder';
import history from '../../../../history';
import InputTimeLive from '../../Dialog/InputTimeLive';
import UploadFile from '../../Dialog/UploadFile';
import isEmpty from '../../../../validation';
import NoData from '../../../../icons/no-data';
import { v1 as uuidv1 } from 'uuid';
import FilePreview from 'react-preview-file';
import Select from '../../../../reusable-components/Inputs/Select';
import Axios from 'axios';
import store from '../../../../store';
import moment from 'moment';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import swal from 'sweetalert';

class Class extends React.Component {
    state = {
        openConfirmationDialog: false,
        openClass: false,
        openSuccessCreateClass: false,
        searchValue: "",
        showClass: false,
        showDraft: false,
        showFolder: false,
        openCreateFolder: false,
        openInputTimeLive: false,
        openUploadFile: false,
        currentFilter: "all",
        files: [],
        classes: [],
        subjects: [],
        activeClass: null,
        activeFolder: null,
        activeFile: null
    }

    componentDidMount() {
        this.getNewClass();
        this.getSubjects();
    }

    getNewClass = () => {
        const { auth } = this.props;
        let classes = []
        if (auth) {
            var formData = new FormData();
            formData.append("wMFqAR", 99999);
            formData.append("UpkYjb", 0);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Uk6mGZfBrtQSutjp",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "Lq2TV7G42EV8Ayad",
                        password: "dsFZGNC7ua7xS5BM"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ncuBLv" && respond.data !== "EyQPPk") {
                    if (respond.data !== "ERR") {
                        respond.data.forEach(datum => {
                            classes.push(datum)
                        })
                    } else {
                        swal("Error!", "Class not found", "error", {
                            timer: 3000,
                        })
                    }

                    this.setState({
                        classes
                    })
                }
            });
        }
    }

    getSubjects = () => {
        var formData = new FormData();
        formData.append("kkLdrk", 9999)
        formData.append("wqWWWe", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/X7asVxHWTYTEeGmV",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "RRq6sjw9j3AWrbYf",
                    password: "zm8YBRvkMVkTQawm"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CktZKk" && respond.data !== "JrasLA") {
                this.setState({
                    subjects: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleChangeSearch = e => {
        this.setState({
            searchValue: e.target.value
        })
    }

    handleClickClass = activeClass => () => {
        store.dispatch({
            type: "SET_ACTIVE_CLASS",
            payload: activeClass
        })

        history.push("/dashboard/online-class")
    }

    handleChangeFilter = value => {
        this.setState({
            currentFilter: value,
        })
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { openConfirmationDialog, openClass, openInputTimeLive, openSuccessCreateClass, showClass, showDraft, showFolder, currentFilter, openCreateFolder, openUploadFile, files } = this.state;
        const { classes, searchValue, activeClass, activeFolder, activeFile, successResendEmail, subjects } = this.state;
        const { auth } = this.props;
        const { is_verified } = auth;

        const filters = subjects.map(subject => ({
            value: subject.id,
            label: subject.name
        }))

        return (
            <div className="class">
                <div className="content container ptb-80">
                    <div className="row">
                        <div className="col-12">
                            {successResendEmail &&
                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                        <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                    </span>
                                </div>
                            }
                            {!successResendEmail && isEmpty(is_verified) &&
                                <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>
                                        Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                        <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="class-list">
                        <div className="toolbar">
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={4} md={4} container spacing={0} alignItems="center">
                                    {/* <div className="total-result-search">
                                                    <p>Showing 1-6 of 6 results</p>
                                                </div> */}
                                </Grid>
                                <Grid item xs={12} lg={8} md={8}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} lg={6} md={6}>
                                            <div className="search-class">
                                                <input
                                                    type="text"
                                                    value={searchValue}
                                                    onChange={this.handleChangeSearch}
                                                    name="search"
                                                    placeholder="Cari Kelas"
                                                    className="form-control"
                                                    style={{
                                                        height: 35
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} md={6}>
                                            <Select
                                                options={filters}
                                                placeholder="Filter berdasarkan Subject"
                                                noOptionsMessage={() => {
                                                    return 'Tidak ada pilihan'
                                                }}
                                                value={currentFilter}
                                                onChange={this.handleChangeFilter}
                                                isClearable
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        {classes.filter(item => String(item.name).toLocaleLowerCase().includes(String(searchValue).toLocaleLowerCase())).filter(item => (!isEmpty(currentFilter) ? true : item.subject_id === currentFilter)).filter(item => item.status !== 2072139579 && item.status !== 2036163938).length === 0 &&
                            <div className="empty-state" style={{ height: "calc(100vh - 454px)", minHeight: 300 }}>
                                <div className="icon-container">
                                    <NoData />
                                </div>
                                <p style={{ fontSize: 20 }}>
                                    Kelas tidak tersedia
                                </p>
                            </div>
                        }
                        {classes.filter(item => String(item.name).toLocaleLowerCase().includes(String(searchValue).toLocaleLowerCase())).filter(item => (!isEmpty(currentFilter) ? true : item.subject_id === currentFilter)).filter(item => item.status !== 2072139579 && item.status !== 2036163938).length > 0 &&
                            <div className="row" style={{ height: "calc(100vh - 454px)", minHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
                                {classes.filter(item => item.status !== 2072139579 && item.status !== 2036163938).map((singleClass, index) => (
                                    <div key={index} className="col-lg-4 col-md-6">
                                        <div className="single-class" onClick={isEmpty(is_verified) ? () => { } : this.handleClickClass(singleClass)}>
                                            <div className="title">
                                                <h3> {singleClass.name} </h3>
                                                {singleClass.status === 2015158996 && <span style={{ flex: "30%", color: "#44ce6f", fontSize: 14, textAlign: "right" }}>LIVE</span>}
                                            </div>
                                            <p>{unescape(singleClass.description)}</p>
                                            <div className="subject">
                                                <span>Subject</span>
                                                <p>{singleClass.subject.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

export default Class