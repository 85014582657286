import React from 'react';
import * as Icon from "react-feather";
import Grid from "@material-ui/core/Grid";
import PaymentSummary from '../../Dialog/PaymentSummary';
import TopUpBalance from '../../Dialog/TopUpBalance';
import { connect } from "react-redux";
import Util from '../../../../util';
import isEmpty from '../../../../validation';
import history from '../../../../history';
import store from '../../../../store';
import Axios from 'axios';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import setAuthToken from '../../../../validation/setAuthToken';
import AuthActions from '../../../../actions/AuthActions';
import PurchasedSuccess from '../../Dialog/PurchasedSuccess';
import swal from 'sweetalert';
import PaymentPending from '../../Dialog/PaymentPending';

class DetailFile extends React.Component {
    state = {
        openPaymentSummary: false,
        openTopUpBalance: false,
        topUpBalanceSuccess: false,
        successResendEmail: false,
        openSuccessPurchased: false
    }

    handleClosePaymentSummary = () => {
        this.setState({
            openPaymentSummary: false
        })
    }

    handleOpenPaymentSummary = e => {
        e.preventDefault();

        this.setState({
            openPaymentSummary: true
        })
    }

    handleConfirmPaymentSummary = () => {
        this.setState({
            openPaymentSummary: false,
            openTopUpBalance: true
        })
    }

    handleOpen = () => {
        this.setState({
            openTopUpBalance: true
        })
    }

    handleClose = () => {
        this.setState({
            openTopUpBalance: false,
            openPaymentSummary: true,
        })
    }

    handlePay = () => {
        this.setState({
            openPaymentSummary: false,
            openSuccessPurchased: true
        })
    }

    handleClosePurchasedSuccess = () => {
        this.setState({
            openSuccessPurchased: false
        })
    }

    componentWillMount() {
        if (isEmpty(this.props.activeData)) {
            history.goBack();
        }
    }


    handleTopUpBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VwxCYt", this.props.auth.id);
        formData.append("EzEZnB", amount);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/UqbHAbH3En55aHam",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p5Hym9fAguNh8FxK",
                    password: "TWEDHGZn7Fmx3wfW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ERR") {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                window.snap.pay(respond.data.token, {
                    onSuccess: async function (result) {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                            openPaymentSummary: true,
                        })
                    },
                    onPending: function (result) {
                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                        })
                    },
                    onError: function (result) {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    },
                    onClose: function () {
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.setState({
            openTopUpBalance: false,
        })
    }

    handleCloseTopUpAlert = () => {
        this.setState({
            topUpBalanceSuccess: false
        })
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleDownload = fileId => () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("wLFBzP", fileId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/aTSpxP27NuTtA3aw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "LepY79np2x38vZzq",
                    password: "VrSDfy2sfbxfzZWq"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "aYQCxh") {
                window.location.href = respond.data;
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { openPaymentSummary, openTopUpBalance, topUpBalanceSuccess, openSuccessPurchased, successResendEmail, openPaymentPending } = this.state;
        const { activeData, auth } = this.props;
        const { is_verified } = auth;

        return (
            <div className="recent-file">
                <div className="content ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {successResendEmail &&
                                    <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ flex: "30%", maxWidth: 50 }}>
                                            <Icon.Info />
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                            <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                        </span>
                                    </div>
                                }
                                {!successResendEmail && isEmpty(is_verified) &&
                                    <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ flex: "30%", maxWidth: 50 }}>
                                            <Icon.Info />
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <span>
                                            Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                            <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="products-details-image">
                                    <ul className="slickslide">
                                        <img src={unescape(activeData.images[0])} alt="image" style={{ borderRadius: 4 }} />
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-7">
                                <div className="products-details">
                                    <h3>{unescape(activeData.name)}</h3>

                                    <div className="price">
                                        Rp. {Util.numberWithCommas(activeData.price)}
                                    </div>

                                    <p>{unescape(activeData.description)}</p>

                                    <div className="buy-btn">
                                        {!activeData.isPurchased &&
                                            <button className={`btn btn-primary ${isEmpty(is_verified) ? "disable-btn" : ""}`} onClick={this.handleOpenPaymentSummary} disabled={isEmpty(is_verified)}>Beli</button>
                                        }
                                        {activeData.isPurchased &&
                                            <button className={`btn btn-primary ${isEmpty(is_verified) ? "disable-btn" : ""}`} onClick={this.handleDownload(activeData.id)} disabled={isEmpty(is_verified)}>Download</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PaymentSummary open={openPaymentSummary} topUpBalanceSuccess={topUpBalanceSuccess} handleClose={this.handleClosePaymentSummary} handleConfirm={this.handleConfirmPaymentSummary} handleCloseTopUpAlert={this.handleCloseTopUpAlert} handlePay={this.handlePay} handleRemoveSuccessTopUpBalance={this.handleCloseTopUpAlert} />
                    <TopUpBalance open={openTopUpBalance} handleClose={this.handleClose} handleTopUpBalance={this.handleTopUpBalance} />
                    <PurchasedSuccess open={openSuccessPurchased} handleClose={this.handleClosePurchasedSuccess} />
                    <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth,
        activeData: state.resourceReducer.activeData
    }
}

export default connect(mapStateToProps)(DetailFile);