import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    activeData: null
};

const ResourceReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_ACTIVE_FILE":
            return {
                ...state,
                activeData: action.payload
            }

        case "UPDATE_PURCHASED_FILE":
            return {
                ...state,
                activeData: {
                    ...state.activeData,
                    ...action.payload
                }
            }

        default:
            return state;
    }
}

const filesPersistConfig = {
    key: "files",
    storage,
    whitelist: ["activeData"]
}

export default persistReducer(filesPersistConfig, ResourceReducer);