import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import Util from '../../../util';
import isEmpty from '../../../validation';

class NotificationPopUp extends React.Component {
    render() {
        const { open, activeNotification, handleClose, handleConfirm, handleDecline } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Detail Pertanyaan</h4>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0} alignItems="center" justify="center">
                        <Grid item xs={8}>
                            <div style={{ padding: 20 }}>
                                <div className="work-info">
                                    {!isEmpty(activeNotification) && !isEmpty(activeNotification.bid_price) &&
                                        <div className="info-list">
                                            <Grid container spacing={1}>
                                                <Grid item xs={3} container spacing={0} alignItems="center">
                                                    <p>Jenis</p>
                                                </Grid>
                                                <Grid item xs={1} container alignItems="center" justify="flex-end">
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item xs={8} container spacing={0} alignItems="center">
                                                    <span style={{ wordBreak: "break-word" }}>{activeNotification.type.name}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    <div className="info-list">
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} container spacing={0} alignItems="center">
                                                <p>Subject</p>
                                            </Grid>
                                            <Grid item xs={1} container alignItems="center" justify="flex-end">
                                                <span>:</span>
                                            </Grid>
                                            <Grid item xs={8} container spacing={0} alignItems="center">
                                                <span style={{ wordBreak: "break-word" }}>{activeNotification.subject_name}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="info-list">
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} container spacing={0} alignItems="center">
                                                <p>Pertanyaan</p>
                                            </Grid>
                                            <Grid item xs={1} container alignItems="center" justify="flex-end">
                                                <span>:</span>
                                            </Grid>
                                            <Grid item xs={8} container spacing={0} alignItems="center">
                                                <span style={{ wordBreak: "break-word" }}>{unescape(activeNotification.content)}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="time-rate">
                                    <div className="info-list =">
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} container spacing={0} alignItems="center">
                                                <p>Waktu</p>
                                            </Grid>
                                            <Grid item xs={1} container justify="flex-end" alignItems="center">
                                                <span>:</span>
                                            </Grid>
                                            <Grid item xs={8} container spacing={0} alignItems="center">
                                                <span style={{ wordWrap: "break-word" }}>{!isEmpty(activeNotification) ? activeNotification.type.id === 2045482424 ?
                                                    activeNotification.expected_duration_day > 0 ?
                                                        (activeNotification.expected_duration_day + " Hari") :
                                                        activeNotification.expected_duration_hour > 0 ?
                                                            (activeNotification.expected_duration_hour + " Jam") :
                                                            (activeNotification.expected_duration_minute + " Menit") :
                                                    activeNotification.expected_duration_day > 0 ?
                                                        (activeNotification.expected_duration_day + " Hari") :
                                                        activeNotification.expected_duration_hour > 0 ?
                                                            (activeNotification.expected_duration_hour + " Jam") :
                                                            (activeNotification.expected_duration_month + " Bulan") : ""
                                                }</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="info-list">
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} container spacing={0} alignItems="center">
                                                <p>Rate</p>
                                            </Grid>
                                            <Grid item xs={1} container justify="flex-end" alignItems="center">
                                                <span>:</span>
                                            </Grid>
                                            <Grid item xs={8} container spacing={0} alignItems="center">
                                                {!isEmpty(activeNotification.bid_price) &&
                                                    <span>IDR {Util.numberWithCommas(activeNotification.bid_price)}</span>
                                                }
                                                {!isEmpty(activeNotification) && isEmpty(activeNotification.bid_price) &&
                                                    <>
                                                        <span>IDR {Util.numberWithCommas(activeNotification.type.minimum_price)}</span>
                                                        {!isEmpty(activeNotification.type.maximum_price) &&
                                                            <>
                                                                <span style={{ padding: "0px 10px" }}>-</span>
                                                                <span>IDR {Util.numberWithCommas(activeNotification.type.maximum_price)}</span>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-light" onClick={handleDecline}>Tolak Penawaran</button>
                    <button className="btn btn-primary" onClick={handleConfirm}>Terima Penawaran</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default NotificationPopUp;