import React from 'react';
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import isEmpty from '../../../validation';
import Axios from 'axios';
import store from '../../../store';
import Select from '../../../reusable-components/Inputs/Select';

class CreateNewClass extends React.Component {
    state = {
        name: "",
        description: "",
        nameInvalid: false,
        showError: false
    }

    handleCreateNewClass = e => {
        e.preventDefault();

        const { name, description, nameInvalid, subject } = this.state;

        this.setState({
            showError: isEmpty(name) || nameInvalid || isEmpty(subject) || isEmpty(description)
        }, () => {
            if (!this.state.showError) {

                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("vfFGtH", this.props.auth.id);
                formData.append("rxXUWU", name);
                formData.append("ujXeeE", description);
                formData.append("rpTECe", subject);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/fcB6hbQ6E5V4BD8T",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "cAVMEVy9mRQSfw2X",
                            password: "7YwqTxdk8LjUMpRA"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "tftLJg" && respond.data !== "hHhLUP" && respond.data !== "pLSthT") {
                            if (this.props.handleCreateNewClass) {
                                this.props.handleCreateNewClass(name, description, subject)
                            }

                            this.setState({
                                name: "",
                                description: "",
                                subject: null,
                                showError: false
                            })
                        }

                        this.setState({
                            nameInvalid: respond.data === "tftLJg"
                        })

                        store.dispatch({
                            type: "SET_LOADING",
                            payload: false
                        });
                    }
                })
            }
        })
    }

    handleInputChange = name => e => {
        const { classes } = this.props;
        this.setState({
            [name]: e.target.value,
            nameInvalid: name === "name" ? classes.some(item => item.name.trim() === e.target.value.trim()) : this.state.nameInvalid
        })
    }

    handleChangeSubject = value => {
        this.setState({
            subject: value
        })
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose()
        }

        this.setState({
            name: "",
            description: "",
            nameInvalid: false,
            showError: false
        })
    }

    render() {
        const { auth, open } = this.props;
        const { name, nameInvalid, description, subject, showError } = this.state;

        const tutorSubjects = auth ? auth.subjects.map(element => ({ value: element.id, label: element.name })) : [];

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Buat Kelas Baru</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20 }}>
                        <form onSubmit={this.handleCreateNewClass}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Nama kelas"
                                            className={`form-control ${((showError && isEmpty(name)) || nameInvalid) ? "is-invalid" : ""}`}
                                            value={name}
                                            onChange={this.handleInputChange("name")}
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        <div className="invalid-feedback">
                                            {nameInvalid ? "Nama yang digunakan sudah terpakai." : "Cantumkan nama yang valid."}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`select-box ${showError && isEmpty(subject) ? "is-invalid" : ""}`}>
                                        <Select
                                            options={tutorSubjects}
                                            placeholder="Subject"
                                            noOptionsMessage={() => {
                                                return 'Tidak ada pilihan'
                                            }}
                                            value={subject}
                                            onChange={this.handleChangeSubject}
                                            error={showError && isEmpty(subject)}
                                        />
                                    </div>
                                    <div className="invalid-feedback" style={{ display: showError && isEmpty(subject) && "block" }}>
                                        Cantumkan subject yang valid.
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                        <textarea
                                            className={`form-control ${showError && isEmpty(description) ? "is-invalid" : ""}`}
                                            placeholder="Deskripsikan Kelas Anda"
                                            rows="8"
                                            style={{ height: "100%" }}
                                            maxLength="140"
                                            onChange={this.handleInputChange("description")}
                                            value={description}
                                            error={showError && isEmpty(description)}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="invalid-feedback" style={{ display: showError && isEmpty(description) && "block" }}>
                                        Cantumkan deskripsi yang valid.
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-light" onClick={this.handleClose}>Batalkan</button>
                    <button className="btn btn-primary" onClick={this.handleCreateNewClass}>Buat</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth,
    }
}

export default connect(mapStateToProps)(CreateNewClass);