import React from 'react';
import * as Icon from "react-feather";
import isEmpty from '../../../../validation';
import store from '../../../../store';
import Axios from 'axios';
import AuthActions from '../../../../actions/AuthActions';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import moment from 'moment';
import setAuthToken from '../../../../validation/setAuthToken';
import { Grid } from '@material-ui/core';
import swal from 'sweetalert';
import Select from '../../../../reusable-components/Inputs/Select';
import { Link } from 'react-router-dom';

let isChange = false;

class TutorProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props.auth,
            description: (!isEmpty(this.props.auth.description) && this.props.auth.description != "null") ? this.props.auth.description : "",
            education_levels: [],
            showError: false,
            profileSaveSuccess: false,
            invalid_education_level: false
        }
    }

    onUnload = e => { // the method that will be used for both add and remove event
        e.preventDefault();

        if (isChange) {
            e.returnValue = '';
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }


    handleUpload = event => {
        isChange = true;

        this.setState({
            photo: event.target.files[0]
        })

        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let _this = this;

        reader.onloadend = () => {
            _this.setState({
                profile_picture: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    handleClickUpload = () => {
        document.getElementById("profile-img").click();
    }

    handleSelectChange = name => value => {
        isChange = true;

        this.setState({
            [name]: value
        })
    }

    handleChange = name => event => {
        isChange = true;

        this.setState({
            [name]: event.target.value
        })
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);

        const { location } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("zwZGQn", 9999)
        formData.append("KnBNsY", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/RGCcPbkbtMP6PjuD",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "t8yVVjp4WMZAcLGv",
                    password: "RUQpdFA9PcMRcwCC"
                }
            }
        ).then(respond => {

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "fstTrN" && respond.data !== "hMwECG") {
                this.setState({
                    education_levels: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { name, education_level, description, photo } = this.state;

        this.setState({
            showError: isEmpty(name) || isEmpty(education_level),
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("byyGbc", this.props.auth.id);
                formData.append("fpGNYq", name);
                formData.append("epTNPk", photo);
                formData.append("ySeKrx", description);
                formData.append("PxewKz", education_level);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/fyTp9eLM7tabBN7K",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "aDcJD8JEaPuEaA6c",
                            password: "TN3VWbM66LHJzL3b"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "aKrABp" && respond.data !== "NAKeVu" && respond.data !== "yeQhjr") {
                            isChange = false;
                            const updatedData = {};

                            respond.data.forEach(data => {
                                updatedData[data[0]] = data[1]
                            })

                            const payload = {
                                ...this.props.auth,
                                ...updatedData,
                            }; // Create JWT Payload

                            delete payload["exp"];

                            const oldDecoded = jwt_decode(localStorage.jwtToken);

                            const token = jwt.sign(
                                payload,
                                "SECRET",
                                { expiresIn: oldDecoded.exp }
                            );

                            localStorage.setItem("jwtToken", token);
                            // Decode token to get user data
                            const decoded = jwt_decode(token);

                            store.dispatch(AuthActions.setCurrentUser(decoded));

                            // Set token to Auth header
                            setAuthToken(token);

                            store.dispatch({
                                type: "UPDATE_PROFILE",
                                payload: updatedData
                            })

                            this.setState({
                                ...updatedData
                            })

                            this.setState({ profileSaveSuccess: true })
                        }

                        this.setState({
                            invalid_education_level: respond.data === "yeQhjr"
                        })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    render() {
        const { email, name, subjects, institution, description, education_level, profile_picture, showError, profileSaveSuccess, education_levels, invalid_education_level } = this.state;

        return (
            <div className="profile ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>Profil Anda</h2>
                                <div className="bar"></div>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="form-container">

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="profile-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="profile-image">
                                                    <input id="profile-img" type="file" name="profile-img" style={{ display: "none" }} onChange={this.handleUpload} accept="image/*" />
                                                    <div className="upload-profile-img" onClick={this.handleClickUpload} style={{ position: isEmpty(profile_picture) ? "relative" : "absolute" }}>
                                                        <Icon.Camera />
                                                    </div>
                                                    {!isEmpty(profile_picture) &&
                                                        <img src={profile_picture}
                                                            alt="image"
                                                            onClick={this.handleClickUpload}
                                                        />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="profile-content">
                                                    <textarea
                                                        className="form-control description"
                                                        placeholder="Deskripsikan keahlian di bidang Anda"
                                                        rows="8"
                                                        style={{ height: "100%" }}
                                                        maxLength="140"
                                                        onChange={this.handleChange("description")}
                                                        value={description}
                                                    >
                                                    </textarea>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <a className="nav-link" href="/change-tutor-password" style={{ textDecoration: "underline" }}>
                                                    GANTI SANDI
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="data-container">
                                        <div className="row justify-content-center h-100">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                        {profileSaveSuccess &&
                                                            <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                                <Icon.Info /><div style={{ width: 10 }}> </div>Update profile berhasil!
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Nama Lengkap <span className="required">*</span></label>
                                                            <input
                                                                type="text"
                                                                name="fullname"
                                                                className={`form-control ${showError && isEmpty(name) ? "is-invalid" : ""}`}
                                                                value={name}
                                                                disabled
                                                            />
                                                            <div className="invalid-feedback">
                                                                Cantumkan nama lengkap Anda yang valid.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Email <span className="required">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="text"
                                                                value={email}
                                                                disabled
                                                            />
                                                            <div className="invalid-feedback">
                                                                Cantumkan email Anda yang valid.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isEmpty(subjects) &&
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <label>Subject <span className="required">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="subject"
                                                                    value={subjects.map(subject => subject.name).join(", ")}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Pendidikan Terakhir <span className="required">*</span></label>
                                                            <div className={`select-box ${(showError && isEmpty(education_level) || invalid_education_level) ? "is-invalid" : ""}`}>
                                                                <Select
                                                                    options={education_levels.map(education_level => ({ value: education_level.id, label: education_level.name }))}
                                                                    placeholder=""
                                                                    noOptionsMessage={() => {
                                                                        return 'Tidak ada pilihan'
                                                                    }}
                                                                    value={education_level}
                                                                    onChange={this.handleSelectChange("education_level")}
                                                                    error={showError && isEmpty(education_level) || invalid_education_level}
                                                                />
                                                            </div>
                                                            <div className="invalid-feedback" style={{ display: showError && isEmpty(education_level) && "block" }}>
                                                                Cantumkan edukasi Anda yang valid.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Universitas <span className="required">*</span></label>
                                                            <input
                                                                type="text"
                                                                name="university"
                                                                className="form-control"
                                                                value={institution.name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 row align-items-end">
                                                <button className={`btn btn-primary submit-btn`} type="submit">
                                                    Simpan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div>
        )
    }
}

export default TutorProfile