import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import RegisterSuccess from '../Homepage/Dialog/RegisterSuccess';
import { Chip, Grid } from '@material-ui/core';
import store from '../../store';
import Axios from 'axios';
import isEmpty from '../../validation';
import Select from "../../reusable-components/Inputs/Select";
import { Link } from 'react-router-dom';
import TermAndCondition from './Dialog/TermAndCondition';

class TutorRegister extends React.Component {
    state = {
        openRegisterSuccess: false,
        files: [],
        universities: [],
        email: "",
        fullname: "",
        subject: [],
        subjects: [],
        university: null,
        invalidSubject: null,
        openTermCondition: false,
        agreeTermCondition: false,
        sbTextContainers: [],
        content: []

    };

    handleClose = () => {
        this.setState({
            openRegisterSuccess: false
        })
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = () => {
        this.setState({
            files: []
        })
    }

    handleInputChange = name => event => [
        this.setState({
            [name]: event.target.value
        })
    ]

    handleSelectChange = name => value => {
        this.setState({
            [name]: value
        })
    }

    handleCreateUniversity = string => {
        const universities = [...this.state.universities];

        universities.push({
            id: string,
            name: string
        })

        this.setState({
            universities,
            university: string
        })
    }

    handleMultiSelectChange = name => value => {
        this.setState({
            [name]: !isEmpty(value) ? value : []
        })
    }

    handleClickTermCondition = e => {
        e.preventDefault();

        this.setState({
            openTermCondition: true
        })
    }

    handleCloseTermAndCondition = () => {
        this.setState({
            openTermCondition: false
        })
    }

    componentWillMount() {
        // Check for token
        if (localStorage.jwtToken) {
            window.location.href = "/tutor-dashboard";
        }
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("ycAgZQ", 9999)
        formData.append("Lfdzbb", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/SVCKWKYsVny9YU9N",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "V2Wap9myX6B9eQqN",
                    password: "uLgjTBB6eCrZ84wZ"
                }
            }
        ).then(respond => {

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BUSxfL" && respond.data !== "JPeUuS") {
                this.setState({
                    universities: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        var formData = new FormData();
        formData.append("kkLdrk", 9999)
        formData.append("wqWWWe", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/X7asVxHWTYTEeGmV",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "RRq6sjw9j3AWrbYf",
                    password: "zm8YBRvkMVkTQawm"
                }
            }
        ).then(respond => {

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CktZKk" && respond.data !== "JrasLA") {
                this.setState({
                    subjects: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2013294856)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { email, fullname, subject, university, files, agreeTermCondition } = this.state;

        this.setState({
            showError: !agreeTermCondition || isEmpty(email) || isEmpty(fullname) || isEmpty(university || isEmpty(subject)) || isEmpty(files)
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("sKPyMe", email)
                formData.append("FQZgxV", fullname)
                formData.append("pVvhng", subject)
                formData.append("XdHhdY", university)
                files.map((file, index) => {
                    formData.append(`pHwuMf`, file)
                })

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/LHQBKyKp3fAFAS7r",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "a8eaVWsMHbF5kKKV",
                            password: "3zYK9WBaP3VGRsFr"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "subCzQ") {
                            this.setState({
                                openRegisterSuccess: true
                            })
                        }

                        this.setState({ emailExist: respond.data === "subCzQ", invalidSubject: respond.data === "HwuGSA" })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    render() {
        const { openRegisterSuccess, openTermCondition, files, email, fullname, universities, university, showError, subject, subjects, emailExist, invalidSubject, agreeTermCondition, sbTextContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        })
        return (
            <div className="sign-up ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>{content[2081980977]}</h2>
                                <div className="bar"></div>
                                <p>{content[2026421036]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2048914121]} <span className="required">*</span></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className={`form-control ${(showError && isEmpty(email) || emailExist) ? "is-invalid" : ""}`}
                                                    value={email}
                                                    onChange={this.handleInputChange("email")}
                                                />
                                                <div className="invalid-feedback">
                                                    {emailExist ? "Email sudah terdaftar." : "Cantumkan alamat email yang valid."}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2033943999]} <span className="required">*</span></label>
                                                <input
                                                    type="text"
                                                    name="fullname"
                                                    className={`form-control ${(showError && isEmpty(fullname)) ? "is-invalid" : ""}`}
                                                    value={fullname}
                                                    onChange={this.handleInputChange("fullname")}
                                                />
                                                <div className="invalid-feedback">
                                                    Cantumkan nama lengkap yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2003414812]}<span className="required">*</span></label>
                                                <div className={`select-box ${(showError && isEmpty(subject) || invalidSubject) ? "is-invalid" : ""}`}>
                                                    <Select
                                                        options={subjects.map(subject => ({ value: subject.id, label: subject.name }))}
                                                        noOptionsMessage={() => {
                                                            return subject.length === 2 ? "Kamu hanya bisa memilih 2 subject" : 'Tidak ada pilihan'
                                                        }}
                                                        limitValue={2}
                                                        placeholder=""
                                                        value={subject}
                                                        onChange={this.handleMultiSelectChange("subject")}
                                                        isMulti
                                                        error={showError && isEmpty(subject)}
                                                    />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: showError && isEmpty(subject) && "block" }}>
                                                    Cantumkan subject yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2064824289]} <span className="required">*</span></label>
                                                <div className={`select-box ${showError && isEmpty(university) ? "is-invalid" : ""}`}>
                                                    <Select
                                                        options={universities.map(university => ({ value: university.name, label: university.name }))}
                                                        placeholder=""
                                                        noOptionsMessage={() => {
                                                            return 'Tidak ada pilihan'
                                                        }}
                                                        value={university}
                                                        onChange={this.handleSelectChange("university")}
                                                        // onCreateOption={this.handleCreateUniversity}
                                                        // isCreateable
                                                        error={showError && isEmpty(university)}
                                                    />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: showError && isEmpty(university) && "block" }}>
                                                    Cantumkan universitas yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2000800677]} <span className="required">*</span></label>
                                                <DropzoneArea
                                                    dropzoneClass={`custom-dropzone ${files.length >= 1 ? "hide" : ""} ${showError && isEmpty(files) ? "error-dropzone" : ""}`}
                                                    dropzoneText="Unduh File/dokumen disini"
                                                    filesLimit={1}
                                                    initialFiles={files}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewText=""
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ variant: "outlined", style: { color: "#223F63", borderColor: "#223F63" } }}
                                                    onChange={this.handleUpload.bind(this)}
                                                    showAlerts={false}
                                                />
                                                <div className="invalid-feedback" style={{ display: showError && isEmpty(files) && "block" }}>
                                                    Cantumkan file/dokumen yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="agree-term-condition" value={agreeTermCondition} onChange={() => this.setState({ agreeTermCondition: !agreeTermCondition })} />
                                                <p className="bottom-text" style={{ marginBottom: 0 }}>{content[2023159299]}<a className="nav-link" className="nav-link" href="#" onClick={this.handleClickTermCondition}>{content[2052246582]}</a>.</p>
                                                <div className="invalid-feedback" style={{ display: showError && !agreeTermCondition && "block" }}>
                                                    Anda harus setuju dengan syarat dan kondisi.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button className="btn btn-primary submit-btn" type="submit" >
                                                {content[2095179477]}
                                            </button>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <p className="bottom-text">
                                                {content[2097350809]}
                                                <a className="nav-link" className="nav-link" href="/tutor-login">{content[2050569565]}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="shape1">
                        <img src="/assets/images/abstract/shape1.png" alt="shape" />
                    </div>
                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>

                <RegisterSuccess open={openRegisterSuccess} handleClose={this.handleClose} handleConfirm={this.handleClose} />
                <TermAndCondition open={openTermCondition} handleClose={this.handleCloseTermAndCondition} />
            </div >
        )
    }
}

export default TutorRegister