import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle, Chip } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import isEmpty from '../../../validation';
import store from '../../../store';
import Axios from 'axios';

class UploadFile extends React.Component {
    state = {
        files: [],
        description: "",
        showError: false
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = () => {
        this.setState({
            files: []
        })
    }

    handleChangeDescription = e => {
        this.setState({
            description: e.target.value
        });
    }

    handleSave = e => {
        e.preventDefault();

        const { activeFolder } = this.props;
        const { files, description, showError } = this.state;

        this.setState({
            showError: isEmpty(files) || isEmpty(description)
        }, () => {
            if (!this.state.showError) {

                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("ZyfUFL", activeFolder.id);
                formData.append("EqqzAW", files[0].name);
                formData.append("FSjFpd", description);
                formData.append("ufdrUG", files[0]);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/ynZSM92VsSsMHmdE",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "w8wWR6ccjtzqVQnr",
                            password: "ydKxhp7SC85DBrgX"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "DfZbxm") {
                            if (this.props.handleSave) {
                                this.props.handleSave(respond.data, description, files)
                            }

                            this.setState({
                                files: [],
                                description: "",
                                showError: false
                            })
                        }

                        store.dispatch({
                            type: "SET_LOADING",
                            payload: false
                        });
                    }
                })
            }
        })
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }

        this.setState({
            files: [],
            description: "",
            showError: false
        })
    }

    onEnter = () => {
        if (this.props.activeFile) {
            this.setState({
                ...this.props.activeFile,
                files: [this.props.activeFile.file]
            })
        }
    }

    render() {
        const { open } = this.props;
        const { files, description, showError } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                onEnter={this.onEnter}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Unggah File</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <form onSubmit={this.handleSave}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-start">
                                                {files[0] &&
                                                    <Chip label={files[0].name} onDelete={this.handleDelete} variant="outlined" style={{ color: "#223F63", borderColor: "#223F63" }} />
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {!files[0] &&
                                                <>
                                                    <DropzoneArea
                                                        dropzoneClass={`custom-dropzone ${showError && isEmpty(files) ? "error-dropzone" : ""}`}
                                                        dropzoneText="Masukkan file anda disini"
                                                        acceptedFiles={['image/*', 'video/*', 'application/*']}
                                                        showPreviewsInDropzone={false}
                                                        onChange={this.handleUpload.bind(this)}
                                                        filesLimit={1}
                                                        maxFileSize={1000000000}
                                                        sizes
                                                    />
                                                    <div className="invalid-feedback" style={{ display: showError && isEmpty(files) && "block" }}>
                                                        Pilih file yang valid
                                                </div>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                        <textarea
                                            value={description}
                                            className={`form-control ${showError && isEmpty(description) ? "is-invalid" : ""}`}
                                            onChange={this.handleChangeDescription}
                                            placeholder="Masukkan deskripsi"
                                            rows="6"
                                            style={{ height: "100%" }}
                                            maxLength="140"
                                        >
                                        </textarea>
                                        <div className="invalid-feedback">
                                            Cantumkan deskripsi yang valid.
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-light" onClick={this.handleClose}>Batalkan</button>
                    <button className="btn btn-primary" onClick={this.handleSave}>Simpan</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default UploadFile;