import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle, IconButton } from '@material-ui/core';
import store from '../../../store';
import Axios from 'axios';
import isEmpty from '../../../validation';
import moment from 'moment';
import { DateTimePicker } from "@material-ui/pickers";
import * as Icon from "react-feather";

class InputTimeLive extends React.Component {
    state = {
        openCalendar: false,
        date: moment().add(2, "days"),
        showError: false
    }

    handleChangeDate = date => {
        if (moment(date).isSameOrAfter(moment().add(2, "days"), "hour")) {
            this.setState({
                date: date
            })
        }
    }

    handleConfirm = e => {
        e.preventDefault();

        const { activeClass } = this.props;
        const { date } = this.state;

        this.setState({
            showError: isEmpty(date)
        }, () => {
            if (!this.state.showError) {
                if (this.props.handleConfirm) {
                    this.props.handleConfirm(date)
                }

                this.setState({
                    date: moment().add(2, "days"),
                    showError: false
                })
            }
        })
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }

        this.setState({
            date: moment().add(2, "days"),
            showError: false
        })
    }

    handleOpenCalendar = () => {
        this.setState({
            openCalendar: true
        })
    }

    handleCloseCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }

    render() {
        const { open } = this.props;
        const { openCalendar, date } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Pilih Waktu untuk Live</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="form-group" style={{ textAlign: "center" }}>
                                    <p style={{ margin: "0px 0px 5px 0px", fontSize: 20 }}>Anda ingin Live di jam berapa?</p>
                                    <DateTimePicker
                                        open={openCalendar}
                                        value={moment(date)}
                                        onChange={this.handleChangeDate}
                                        onClose={this.handleCloseCalendar}
                                        minDate={moment().add(2, "days")}
                                        fullWidth
                                        TextFieldComponent={props =>
                                            <div className="input-icons">
                                                <input
                                                    {...props}
                                                    placeholder="Masukkan waktu Anda"
                                                    value={moment(date).locale("en-au").format("DD/MM/YYYY, HH:mm A")}
                                                    onClick={this.handleOpenCalendar}
                                                    className="form-control input-field"
                                                />
                                                <IconButton onClick={this.handleOpenCalendar} className="icon">
                                                    <Icon.Calendar />
                                                </IconButton>
                                            </div>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: "0px 20px 20px 20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button className="btn btn-light" onClick={this.handleClose}>Batalkan</button>
                        <div style={{ padding: 4 }}></div>
                        <button className="btn btn-primary" onClick={this.handleConfirm}>OK</button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default InputTimeLive;