import React from 'react'
import { Grid } from '@material-ui/core'
import * as Icon from "react-feather";

const histories = [
    { name: "John Doe", lastMessage: "Whats going on?", image: "/assets/images/team-image/1.jpg", chatAvailable: false },
    { name: "Maria Mufasa", lastMessage: "Hey, how are you today?", image: "/assets/images/team-image/2.jpg", chatAvailable: true },
    { name: "Happy Timon", lastMessage: "Thank you, this is long text hahahaha, not long enough hahahahah", image: "/assets/images/team-image/3.jpg" },
]

class AdminChat extends React.Component {
    state = {
        chatAvailable: true,
        showNego: false
    }
    componentDidMount() {
        document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleDecline = () => {
        this.setState({ showNego: true })
    }

    render() {
        const { chatAvailable, showNego } = this.state;

        return (
            <div className="chat-box-container">
                <div className="content container ptb-80" style={{ padding: "80px 12.5%" }}>
                    <div className="chat-box">
                        <div className="chat-message">
                            <div className="single-chat-info">
                                <div className="profile-image">
                                    <img src="/assets/images/team-image/1.jpg" />
                                </div>
                                <div className="display-name">
                                    <p>Admin</p>
                                </div>
                            </div>
                            <div id="main-message" className="message-info">
                                {chatAvailable &&
                                    <>
                                        <div className="single-message-container message-in">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>Hi!</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>8:58 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-out">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>Hello!</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>8:59 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-out">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>How are you today?</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>9:00 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-in">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>I'm Fine</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>9:01 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-out">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>What are you doing?</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>9:02 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-out">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>Hello?</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>10:00 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-in">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>Hey, I better get going. I have a long day tomorrow.</p>
                                                </div>
                                                <div className="time-date">
                                                    <span>12:01 PM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-message-container message-out">
                                            <div className="single-message">
                                                <div className="message">
                                                    <p>
                                                        {this.validURL("Okay ;)") &&
                                                            <a href="http://Okay ;)" target="_blank">Okay ;)</a>
                                                        }
                                                        {!this.validURL("Okay ;)") &&
                                                            "Okay ;)"
                                                        }
                                                    </p>
                                                </div>
                                                <div className="time-date">
                                                    <span>12:02 AM Today</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {!chatAvailable &&
                                    <div className="overlay">
                                        <div className="dialog-price" style={{ height: showNego ? 250 : 200 }}>
                                            <div className="dialog-title">
                                                <h6>Harga pekerjaan</h6>
                                            </div>
                                            <div className="dialog-content">
                                                {!showNego &&
                                                    <>
                                                        <p>Rp 5.000.000</p>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <a href="#" className="btn btn-primary">BAYAR</a>
                                                            <div style={{ padding: 2 }}></div>
                                                            <a href="#" className="btn btn-light" onClick={this.handleDecline}>BATALKAN</a>
                                                        </div>
                                                    </>
                                                }
                                                {showNego &&
                                                    <>
                                                        <p><b>Rate</b>: Rp. 4.000.000 - Rp. 8.000.000</p>
                                                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <input
                                                                type="number"
                                                                placeholder="Input amount"
                                                                name="amount"
                                                                className="form-control"
                                                                style={{
                                                                    width: "70%"
                                                                }}
                                                            />
                                                        </div>
                                                        <a href="#" className="btn btn-primary" onClick={() => this.setState({ showNego: false, chatAvailable: true })}>OK</a>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="message-input">
                                <input
                                    type="text"
                                    name="message"
                                    placeholder="Ketik pesan"
                                    className="input-field"
                                />
                                <div className="send-button">
                                    {<Icon.Send />}
                                </div>
                                {!chatAvailable &&
                                    <div className="overlay">
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

export default AdminChat