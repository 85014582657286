import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import BecomeTutor from './components/Auth/BecomeTutor';
import history from './history';
import FreeAccess from './components/Auth/FreeAccess';
import SignUp from './components/Auth/SignUp';
import Login from './components/Auth/Login';
import ComponentWrapper from './components';
import Profile from './components/Dashboard/Pages/User/Profile';
import TutorProfile from './components/Dashboard/Pages/Tutor/TutorProfile';
import TutorLogin from './components/Auth/TutorLogin';
import TutorRegister from './components/Auth/TutorRegister';
import ResetPassword from './components/Auth/ResetPassword';
import { CircularProgress } from '@material-ui/core';
import store from './store';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import AuthActions from './actions/AuthActions';
import setAuthToken from './validation/setAuthToken';


// Check for token
if (localStorage.jwtToken) {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set auth
    store.dispatch(AuthActions.setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        // Redirect to login
        if (store.getState().masterData.auth.user_type === "TUTOR") {
            window.location.href = "/tutor-login";
        } else {
            window.location.href = "/login";
        }

        // Remove auth
        store.dispatch(AuthActions.logoutUser());
    }
}

function App() {
    return (
        <div className="App">
            <Router history={history}>
                <Switch>
                    <Route path="/" component={ComponentWrapper} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
