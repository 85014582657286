import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../validation";

const PrivateRoute = ({ component: Component, auth, path, ...rest }) => {
    return (
        <Route
            {...rest}
            path={path}
            render={props =>
                !isEmpty(auth) ?
                    (auth.user_type === "CUSTOMER" && path !== "/dashboard/profile" && path !== "/dashboard") && auth.is_verified && isEmpty(auth.graduation_date) ?
                        (
                            <Redirect to="/dashboard/profile" />
                        ) :
                        (auth.user_type === "TUTOR" && path !== "/tutor-dashboard/profile") && isEmpty(auth.education_level) ?
                            (
                                <Redirect to="/tutor-dashboard/profile" />
                            ) :
                            (
                                <Component {...props} auth={auth} />
                            ) : (
                        <Redirect to={auth && auth.user_type === "CUSTOMER" ? "/login" : "/tutor-login"} />
                    )
            }
        />
    )
}

const mapStateToProps = state => ({
    auth: state.masterData.auth
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));