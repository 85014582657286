import React from 'react';
import { connect } from "react-redux";
import { Router, Switch, Route } from 'react-router-dom';
import * as Icon from "react-feather";
import swal from 'sweetalert';
import jwt from "jsonwebtoken";
import _ from "lodash";
import { CircularProgress, Backdrop, Portal } from '@material-ui/core';

import FreeAccess from './Auth/FreeAccess';
import Login from './Auth/Login';
import TutorLogin from './Auth/TutorLogin';
import TutorRegister from './Auth/TutorRegister';
import BecomeTutor from './Auth/BecomeTutor';
import SignUp from './Auth/SignUp';
import ResetPassword from './Auth/ResetPassword';
import EmailVerification from './Auth/EmailVerification';
import ResetPasswordTutor from './Auth/ResetPasswordTutor';
import ChangePassword from './Auth/ChangePassword';
import ChangeTutorPassword from './Auth/ChangeTutorPassword';
import PrivateRoute from './Custom/PrivateRoute';
import NotificationPopUp from './Dashboard/Dialog/NotificationPopUp';
import AcceptedQuestion from './Dashboard/Dialog/AcceptedQuestion';
import JoinClass from './Dashboard/Dialog/JoinClass';
import Help from './Dashboard/Pages/User/Help';
import AskExpertTutor from './Dashboard/Pages/User/AskExpertTutor';
import AskTutor from './Dashboard/Pages/User/AskTutor';
import RecentFile from './Dashboard/Pages/User/RecentFile';
import FindFile from './Dashboard/Pages/User/FindFile';
import ClassUser from './Dashboard/Pages/User/Class';
import Balance from './Dashboard/Pages/User/Balance';
import Chat from './Dashboard/Pages/User/Chat';
import ChatBox from './Dashboard/Pages/Tutor/ChatBox';
import Class from './Dashboard/Pages/Tutor/Class';
import OnlineClass from './Dashboard/Pages/Tutor/OnlineClass';
import DetailFile from './Dashboard/Pages/User/DetailFile';
import AdminChat from './Dashboard/Pages/User/AdminChat';
import TutorBalance from './Dashboard/Pages/Tutor/TutorBalance';
import PromoDetail from './Dashboard/Dialog/PromoDetail';
import Profile from './Dashboard/Pages/User/Profile';
import TutorProfile from './Dashboard/Pages/Tutor/TutorProfile';
import ConfirmationDialog from './Dashboard/Dialog/ConfirmationDialog';
import Header from "./Layout/Header";
import history from '../history';
import Homepage from './Homepage';
import Footer from './Layout/Footer';
import moment from "moment-timezone";

import Axios from 'axios';
import store from '../store';
import isEmpty from '../validation';
import jwt_decode from "jwt-decode";
import AuthActions from '../actions/AuthActions';
import setAuthToken from '../validation/setAuthToken';
import FindTutor from './Dashboard/Dialog/FindTutor';
import NotFindTutor from './Dashboard/Dialog/NotFindTutor';
import Report from './Dashboard/Dialog/Report';
import SetPrice from './Dashboard/Dialog/SetPrice';
import TopUpBalance from './Dashboard/Dialog/TopUpBalance';
import SuccessCreateInquiry from './Dashboard/Dialog/SuccessCreateInquiry';
import GetInTouch from './Dashboard/Dialog/GetInTouch';
import UpdateInquirySuccess from './Dashboard/Dialog/UpdateInquirySuccess';
import CustomerActions from '../actions/CustomerActions';
import PaymentPending from './Dashboard/Dialog/PaymentPending';

class ComponentWrapper extends React.Component {
    state = {
        openDialogNotification: false,
        openDialogAccepted: false,
        openConfirmationDeclineQuestion: false,
        openJoinClass: this.props.location.pathname.includes("dashboard/online-class/join-class"),
        activeNotification: {},
        openPromo: this.props.location.pathname.includes("promo-detail"),
        questionNotifications: [],
        notifications: [],
        messages: [],
        openFindTutor: false,
        openNotFindTutor: false,
        openReport: false,
        openSetPrice: false,
        openTopUpBalance: false,
        balanceInsufficient: false,
        openSuccessCreateInquiry: false
    }

    handleClickNotification = notification => {
        var formData = new FormData();
        formData.append("kEeQBf", notification.id)

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Eqkw6fQ9WLGNEyDb",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WnJMSgcjLxY9H3AW",
                    password: "gss6xQ9hEASBZ3YT"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "vCzVak") {
                this.setState({
                    openDialogNotification: !this.state.openDialogNotification,
                    activeNotification: notification
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleConfirmNotification = () => {
        const { activeNotification } = this.state;
        const { auth } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        if (activeNotification.type_id === 2045482424) {
            var formData = new FormData();
            formData.append("tgRRTX", activeNotification.id)
            formData.append("hnTdDC", auth.id)
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KNGYQfqnFHs5btLP",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "petaETAhJjJwNa9g",
                        password: "SrjVeFKBv6S5CpHa"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "hPXmKZ" && respond.data !== "YDwTja" && respond.data !== "NUGtuq" && respond.data !== "hjRQem") {
                    this.setState({ openDialogNotification: false, openDialogAccepted: true })
                    history.push(this.props.location.pathname)

                    this.removeInquiryFromQuestion(activeNotification.id);
                }


                if (respond.data === "NUGtuq" || respond.data === "hjRQem") {
                    swal("Error!", respond.data === "NUGtuq" ? "Pertanyaan sudah diambil oleh tutor lain." : "Waktu pertanyaan habis", "error", {
                        timer: 3000,
                    }).then((value) => {
                        history.push(this.props.location.pathname)
                        this.setState({ openDialogNotification: false })
                    });
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        } else {
            var formData = new FormData();
            formData.append("wEfHKD", activeNotification.id)
            formData.append("KpjcWQ", auth.id)
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vMLfeHny7Js2a4Xx",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "sExNe8rbLSyu4sU9",
                        password: "Xzqnrd7WC78cmRws"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "jRuHSa" && respond.data !== "pnkBkk" && respond.data !== "WUwjzJ" && respond.data !== "keDdpb") {
                    this.setState({ openDialogNotification: false, openDialogAccepted: true })

                    this.removeInquiryFromQuestion(activeNotification.id);
                }

                if (respond.data === "WUwjzJ" || respond.data === "keDdpb") {
                    swal("Error!", respond.data === "WUwjzJ" ? "Pertanyaan sudah diambil oleh tutor lain." : "Waktu pertanyaan habis", "error", {
                        timer: 3000,
                    }).then((value) => {
                        history.push(this.props.location.pathname)
                        this.setState({ openDialogNotification: false })
                    });
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    handleDeclineQuestion = () => {
        const { activeNotification } = this.state;
        const { auth } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("aCqUQQ", auth.id)
        formData.append("UwySMR", activeNotification.id)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KNMqyqfKf9SWM4rv",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "BLqAKzUmkqn35zPZ",
                    password: "T7Xfn6rCqrYzqJuc"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "vKGzsR" && respond.data !== "yYweUe") {
                this.setState({
                    openDialogNotification: false,
                    openConfirmationDeclineQuestion: false,
                    activeNotification: {},
                    questionNotifications: this.state.questionNotifications.filter(item => item.id !== activeNotification.id)
                })
                history.push(this.props.location.pathname)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseNotificationPopUp = () => {
        this.setState({
            openDialogNotification: false,
        })
    }

    handleClickAcceptQuestion = () => {
        this.setState({
            openDialogAccepted: false,
            activeNotification: {}
        })
    }

    handleCloseJoinClass = () => {
        history.push("/sign-up")

        this.setState({
            openJoinClass: false
        })
    }

    getInquiriesUser = () => {
        const { auth } = this.props;
        let notifications = _.cloneDeep(this.state.notifications)

        if (auth && auth.user_type === "CUSTOMER") {
            var formData = new FormData();
            formData.append("nRLkxq", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "DR7LpCF5GN26wVhn",
                        password: "V4UwWvftU8fTRSCZ"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SpLtDH") {
                    const newInquiries = respond.data;
                    let messages = [];

                    if (JSON.stringify(newInquiries) !== JSON.stringify(notifications)) {
                        newInquiries.forEach(inquiry => {
                            var formData = new FormData();
                            formData.append("UVPtFS", inquiry.id);
                            formData.append("aauFaq", 9999);
                            formData.append("HvERKy", 0);

                            Axios.post(
                                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                                formData,
                                {
                                    headers: {
                                        'Content-Type': "multiple/form-data"
                                    },
                                    auth: {
                                        username: "wEyp8sY6tVmQ7Jj8",
                                        password: "mkxzK4XGR3qyS3St"
                                    }
                                }
                            ).then(respond => {
                                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                                    respond.data.forEach(message => {
                                        messages.push(message);
                                    })

                                    this.setState({
                                        messages
                                    })
                                }
                            });

                            notifications = newInquiries;
                        })
                    }

                    this.setState({
                        notifications
                    })
                }
            });
        }
    }

    getApprovedClass = () => {
        let notifications = _.cloneDeep(this.state.notifications)

        const { auth } = this.props;
        if (auth) {
            var formData = new FormData();
            formData.append("KyerbD", 99999);
            formData.append("pJppPs", 0);
            formData.append("ERgtaC", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ge6VmLzuykB6CCPv",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "MYBwde3K92ACp8vK",
                        password: "t8uUuZ3wc92qEGZh"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "PQyyXb") {
                    let isDifferent = false;

                    respond.data.filter(item => item.status === 2089985133).forEach(datum => {
                        if (notifications.every(notification => notification.id !== datum.id)) {
                            notifications.push(({
                                ...datum,
                                classNotif: true
                            }))

                            isDifferent = true;
                        }
                    })

                    if (isDifferent || JSON.stringify(respond.data.filter(item => item.status === 2089985133)) !== JSON.stringify(notifications.filter(item => item.status === 2089985133))) {
                        this.setState({
                            notifications
                        })
                    }
                }
            });
        }
    }

    getOpenInquiriesTutor = () => {
        const { auth } = this.props;
        let questionNotifications = []

        if (auth && auth.user_type === "TUTOR") {
            var formData = new FormData();
            formData.append("KzLwhe", 99999);
            formData.append("CYcmMz", 0);
            formData.append("kZPWpv", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/uZFFNGTJQGxhfb25",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "KJ8suTQrDLUkbPkV",
                        password: "t75nacdrK7S2scHq"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "aLpPph" && respond.data !== "dTbjXP" && respond.data !== "eNctbK") {
                    respond.data.forEach(datum => {
                        questionNotifications.push(datum)
                    })

                    this.setState({
                        questionNotifications,
                    })
                }
            });
        }
    }

    getInquiriesTutor = () => {
        const { auth } = this.props;
        let notifications = _.cloneDeep(this.state.notifications);

        if (auth && auth.user_type === "TUTOR") {
            var formData = new FormData();
            formData.append("kWTHLe", auth.id);
            formData.append("KzLwhe", 999);
            formData.append("CYcmMz", 0);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zjKVHjtyMW4hD5LP",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "Yt7MYPX2sn72rvsA",
                        password: "Bbf9h6vmQYAxpBfc"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BbcgeL") {
                    const newInquiries = respond.data;
                    let messages = [];
                    let isDifferent = false;

                    if (JSON.stringify(newInquiries) !== JSON.stringify(notifications)) {
                        newInquiries.forEach(inquiry => {
                            var formData = new FormData();
                            formData.append("UVPtFS", inquiry.id);
                            formData.append("aauFaq", 9999);
                            formData.append("HvERKy", 0);

                            Axios.post(
                                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                                formData,
                                {
                                    headers: {
                                        'Content-Type': "multiple/form-data"
                                    },
                                    auth: {
                                        username: "wEyp8sY6tVmQ7Jj8",
                                        password: "mkxzK4XGR3qyS3St"
                                    }
                                }
                            ).then(respond => {
                                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                                    respond.data.forEach(message => {
                                        messages.push(message);
                                    })

                                    this.setState({
                                        messages
                                    })
                                }
                            });

                            if (notifications.every(notification => notification.id !== inquiry.id)) {
                                notifications.push(inquiry);
                            }

                            isDifferent = true;
                        })
                    }

                    if (isDifferent) {
                        this.setState({
                            notifications
                        })
                    }
                }
            });
        }
    }

    getUserDetail = () => {
        const { auth } = this.props;

        if (auth && auth.user_type === "CUSTOMER") {
            var formData = new FormData();
            formData.append("UqADvv", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "WFVgZQZ6sd73R5HF",
                        password: "ef5hKtveCEpWL69q"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "VhUyMY") {

                        const payload = {
                            ...this.props.auth,
                            ...respond.data,
                        }; // Create JWT Payload

                        delete payload["exp"];

                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: oldDecoded.exp }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: respond.data
                        })
                    }
                }
            });
        }
    }

    getTutorDetail = () => {
        const { auth } = this.props;

        if (auth && auth.user_type === "TUTOR") {
            var formData = new FormData();
            formData.append("MMgxhE", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dW6Tf9RyDGWgKhgn",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "N5aqscHw5dYZtgQK",
                        password: "ww3LJEm9tvdRtSq8"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "WmjpFm" && respond.data !== "XtdUne") {
                        const payload = {
                            ...this.props.auth,
                            ...respond.data,
                        }; // Create JWT Payload

                        delete payload["exp"];

                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: oldDecoded.exp }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: respond.data
                        })
                    }
                }
            });
        }
    }

    getOpenInquiriesUser = () => {
        const { auth } = this.props;
        const { openNotFindTutor, openFindTutor, openReport, openSetPrice } = this.state;

        if (auth && auth.user_type === "CUSTOMER") {
            var formData = new FormData();
            formData.append("nRLkxq", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "DR7LpCF5GN26wVhn",
                        password: "V4UwWvftU8fTRSCZ"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SpLtDH") {
                    respond.data.filter(item => item.type_id === 2045482424 && item.status_id !== 2080985413).forEach(datum => {
                        if (!openNotFindTutor && !openFindTutor && !openReport && !openSetPrice) {
                            if (moment().diff(moment(moment(datum.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") < 5) {
                                if (datum.status_id === 2083370574 && datum.last_notified_customer_user == null) {
                                    this.setState({
                                        openFindTutor: true,
                                        inquiryActive: datum
                                    })
                                }
                            }

                            if (moment().diff(moment(moment(datum.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 5 && datum.last_notified_customer_user === null) {
                                if (datum.status_id === 2070045802 || datum.status_id === 2052553063 || datum.status_id === 2016686496) {
                                    this.setState({
                                        openNotFindTutor: true,
                                        inquiryActive: datum
                                    })
                                }
                            }
                        }
                    })
                }
            });
        }
    }

    handleCloseFindTutor = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("jGRDfN", this.state.inquiryActive.id)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/hZy7CpZN5Whnh92b",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VB8SQfWMqAD3pJVT",
                    password: "d27R8bep2WFejtNQ"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "LtSBHB") {
                history.push(`/dashboard/chat?question_id=${this.state.inquiryActive.id}`)
                this.setState({
                    openFindTutor: false,
                    inquiryActive: null
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleRetrySearchTutor = () => {
        const { inquiryActive } = this.state;

        if (inquiryActive.status_id === 2070045802 || inquiryActive.status_id === 2052553063) {
            this.setState({
                openNotFindTutor: false,
                openSetPrice: true
            });
        } else {
            this.setState({
                openNotFindTutor: false,
                openReport: true
            })
        }
    }

    handleCloseSetPrice = () => {
        this.setState({
            openSetPrice: false,
        })
    }

    handleDeleteInquiry = () => {
        const { inquiryActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryActive.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getUserDetail();
                this.setState({
                    openNotFindTutor: false,
                    retry: false,
                    inquiryActive: null,
                    recentlyCreatedInquiryId: null
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleRetrySendInquiry = rate => {
        const { inquiryActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("XavcBP", inquiryActive.id);
        formData.append("GeqsbK", rate);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Nr88vyJL7pBdSq65",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Uv7ypRqrLAqh7g8H",
                    password: "V4GUZxQDp6hg4ALG"
                }
            }
        ).then(respond => {
            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "snYuJQ") {
                this.setState({
                    openSetPrice: false,
                    openSuccessUpdateInquiry: true
                })
            }
        });
    }

    handleOpenTopUpBalance = () => {
        this.setState({
            openSetPrice: false,
            openTopUpBalance: true,
            balanceInsufficient: false
        })
    }

    handleTopUpBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        CustomerActions.topUpBalance({
            authId: this.props.auth.id,
            amount
        }, respond => {
            if (!respond.err.isEmpty && respond.err.isError) {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                CustomerActions.callbackMidtrans({
                    snapToken: respond.data.token,
                    onSuccessCallback: () => {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                            openSetPrice: true,
                        })
                    },
                    onPendingCallback: () => {
                        _this.setState({
                            openPaymentPending: true,
                        })
                    },
                    onErrorCallback: () => {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            this.setState({
                openTopUpBalance: false,
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: true
            });
        })
    }

    handleCloseTopUpBalance = () => {
        this.setState({
            openTopUpBalance: false,
            openSetPrice: true,
        })
    }

    getBalance = () => {
        const { auth } = this.props;

        if (auth) {
            var formData = new FormData();
            formData.append("UqADvv", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "WFVgZQZ6sd73R5HF",
                        password: "ef5hKtveCEpWL69q"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "VhUyMY") {

                        const payload = {
                            ...this.props.auth,
                            ...respond.data,
                        }; // Create JWT Payload

                        delete payload["exp"];

                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: oldDecoded.exp }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: respond.data
                        })
                    }
                }
            });
        }
    }

    handleCloseReport = () => {
        const { inquiryActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryActive.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getBalance();
                this.setState({
                    openReport: false,
                    inquiryActive: null,
                    retry: false
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseSuccessCreateInquiry = () => {
        this.setState({
            openSuccessCreateInquiry: false
        })
    }

    handleCloseSuccessUpdateInquiry = () => {
        this.setState({
            openSuccessUpdateInquiry: false
        })
    }

    handleConfirmReport = () => {
        const { inquiryActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryActive.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getUserDetail();
                this.setState({
                    openReport: false,
                    inquiryActive: null,
                    openGetInTouch: true
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseGetInTouch = () => {
        this.setState({
            openGetInTouch: false,
            inquiryActive: null,
            retry: false
        })
    }

    componentDidMount() {
        const { auth } = this.props;
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);

        if (this.props.auth) {
            this.getUserDetail();
            this.getInquiriesUser();
            this.getTutorDetail();
            this.getOpenInquiriesTutor();
            this.getInquiriesTutor();
            this.getApprovedClass();
            this.getOpenInquiriesUser();

            this.interval = setInterval(() => {
                this.getUserDetail();
                this.getInquiriesUser();
                this.getTutorDetail();
                this.getOpenInquiriesTutor();
                this.getInquiriesTutor();
                this.getApprovedClass();
                this.getOpenInquiriesUser();
            }, 10000);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auth === null && this.props.auth !== null) {
            this.getUserDetail();
            this.getInquiriesUser();
            this.getTutorDetail();
            this.getOpenInquiriesTutor();
            this.getInquiriesTutor();
            this.getApprovedClass();
            this.getOpenInquiriesUser();

            this.interval = setInterval(() => {
                this.getUserDetail();
                this.getInquiriesUser();
                this.getTutorDetail();
                this.getOpenInquiriesTutor();
                this.getInquiriesTutor();
                this.getApprovedClass();
                this.getOpenInquiriesUser();
            }, 10000);
        } else if (prevProps.auth !== null && this.props.auth === null) {
            clearInterval(this.interval)
        }
    }

    componentWillMount() {
        clearInterval(this.interval)
    }

    removeInquiryFromQuestion = inquiryId => {
        let questionNotifications = _.cloneDeep(this.state.questionNotifications);

        this.setState({
            questionNotifications: questionNotifications.filter(item => item.id !== inquiryId)
        })
    }

    removeInquiryFromNotification = inquiryId => {
        let notifications = _.cloneDeep(this.state.notifications);

        this.setState({
            notifications: notifications.filter(item => item.id !== inquiryId)
        })
    }

    toggleConfirmationDialog = () => {
        const { openConfirmationDeclineQuestion } = this.state;

        this.setState({
            openDialogNotification: !!openConfirmationDeclineQuestion,
            openConfirmationDeclineQuestion: !openConfirmationDeclineQuestion
        })
    }

    handleClosePromo = () => {
        this.setState({
            openPromo: false
        })
    }

    render() {
        const { openDialogNotification, openDialogAccepted, openJoinClass, activeNotification, notifications, questionNotifications, openConfirmationDeclineQuestion, messages, openFindTutor, openNotFindTutor, openReport, openSetPrice, openTopUpBalance, openSuccessCreateInquiry, balanceInsufficient, inquiryActive, topUpBalanceSuccess } = this.state;
        const { openPromo, openGetInTouch, openSuccessUpdateInquiry, openPaymentPending } = this.state;
        const { isLoading, auth } = this.props;
        const pathname = this.props.location.pathname;

        return (
            <React.Fragment>
                <Portal container={document.getElementsByTagName("body")[0]}>
                    <Backdrop open={isLoading} className="backdrop-loading">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Portal>
                <div style={{ filter: openJoinClass ? "blur(8px)" : "none" }}>
                    <Header
                        pathname={pathname}
                        auth={auth}
                        handleClickNotification={this.handleClickNotification}
                        notifications={notifications}
                        questionNotifications={questionNotifications}
                        messages={messages}
                        removeInquiryFromNotification={this.removeInquiryFromNotification}
                    />

                    <Router history={history}>
                        <Switch>
                            <Route exact path="/" component={Homepage} />
                            <Route exact path="/registered" component={Homepage} />
                            <Route exact path="/admin-chat" component={AdminChat} />
                            <Route exact path="/free-access" component={FreeAccess} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/tutor-login" component={TutorLogin} />
                            <Route exact path="/tutor-register" component={TutorRegister} />
                            <Route exact path="/become-tutor" component={BecomeTutor} />
                            <Route exact path="/reset/password/:id" component={ResetPassword} />
                            <Route exact path="/reset/tutor-password/:id" component={ResetPasswordTutor} />
                            <Route exact path="/sign-up" component={SignUp} />
                            <Route exact path="/help" component={Help} />
                            <Route exact path="/email/verification/:id" component={EmailVerification} />

                            <PrivateRoute exact path="/change-password" component={ChangePassword} />
                            <PrivateRoute exact path="/change-tutor-password" component={ChangeTutorPassword} />
                            <PrivateRoute exact path="/dashboard" component={RecentFile} />
                            <PrivateRoute exact path="/dashboard/profile" component={Profile} />
                            <PrivateRoute path="/dashboard/ask-expert-tutor" component={AskExpertTutor} />
                            <PrivateRoute path="/dashboard/ask-tutor" component={AskTutor} />
                            <PrivateRoute path="/dashboard/find" component={FindFile} />
                            <PrivateRoute path="/dashboard/detail-file" component={DetailFile} />
                            <PrivateRoute exact path="/dashboard/class" component={ClassUser} />
                            <PrivateRoute path="/dashboard/online-class" component={OnlineClass} />
                            <PrivateRoute exact path="/dashboard/balance" component={Balance} />
                            <PrivateRoute path="/dashboard/chat" component={Chat} />
                            <PrivateRoute exact path="/dashboard/admin-chat" component={AdminChat} />
                            <PrivateRoute exact path="/dashboard/promo-detail" component={RecentFile} />
                            <PrivateRoute exact path="/dashboard/show-dialog-chat-now" component={RecentFile} />

                            <PrivateRoute exact path="/tutor-dashboard" component={ChatBox} />
                            <PrivateRoute exact path="/tutor-dashboard/class" component={Class} />
                            <PrivateRoute path="/tutor-dashboard/online-class" component={OnlineClass} />
                            <PrivateRoute exact path="/tutor-dashboard/balance" component={TutorBalance} />
                            <PrivateRoute exact path="/tutor-dashboard/in-review" component={ChatBox} />
                            <PrivateRoute path="/tutor-dashboard/profile" component={TutorProfile} />
                        </Switch>
                    </Router>

                    <Footer />

                    <NotificationPopUp
                        open={openDialogNotification}
                        activeNotification={activeNotification}
                        handleDecline={this.toggleConfirmationDialog}
                        handleClose={this.handleCloseNotificationPopUp}
                        handleConfirm={this.handleConfirmNotification}
                    />
                    <AcceptedQuestion
                        open={openDialogAccepted}
                        activeNotification={activeNotification}
                        handleClose={this.handleClickAcceptQuestion}
                    />
                    <JoinClass
                        open={openJoinClass}
                        handleClose={this.handleCloseJoinClass}
                    />
                    <ConfirmationDialog
                        open={openConfirmationDeclineQuestion}
                        handleClose={this.toggleConfirmationDialog}
                        handleConfirm={this.handleDeclineQuestion}
                        title="Anda yakin ingin membatalkan pertanyaan ini?"
                        content="Pertanyaan ini akan dibatalkan segera dan Anda tidak bisa kembali ke menu penawaran."
                        confirmText="Ya"
                        closeText="Tidak"
                    />

                    {auth &&
                        <React.Fragment>
                            {pathname.includes("/dashboard") &&
                                <button className="fab" onClick={() => history.push("/dashboard/chat")} > <Icon.MessageSquare /> </button>
                            }

                            <PromoDetail open={openPromo} handleClose={this.handleClosePromo} />

                            <TopUpBalance open={openTopUpBalance} handleClose={this.handleCloseTopUpBalance} handleTopUpBalance={this.handleTopUpBalance} />
                            <SuccessCreateInquiry open={openSuccessCreateInquiry} handleClose={this.handleCloseSuccessCreateInquiry} />
                            <UpdateInquirySuccess open={openSuccessUpdateInquiry} handleClose={this.handleCloseSuccessUpdateInquiry} />
                            <SetPrice
                                open={openSetPrice}
                                topUpBalanceSuccess={topUpBalanceSuccess}
                                balanceInsufficient={balanceInsufficient}
                                handleClose={this.handleCloseSetPrice}
                                handleSendInquiry={this.handleRetrySendInquiry}
                                handleOpenTopUpBalance={this.handleOpenTopUpBalance}
                            />
                            <FindTutor inquiryActive={inquiryActive} open={openFindTutor} handleClose={this.handleCloseFindTutor} />
                            <NotFindTutor inquiryActive={inquiryActive} open={openNotFindTutor} handleClose={this.handleDeleteInquiry} handleConfirm={this.handleRetrySearchTutor} />
                            <Report open={openReport} inquiryActive={inquiryActive} handleClose={this.handleCloseReport} handleConfirm={this.handleConfirmReport} />
                            <GetInTouch open={openGetInTouch} handleClose={this.handleCloseGetInTouch} />
                            <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
                        </React.Fragment>

                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.masterData.isLoading,
        auth: state.masterData.auth
    }
}

export default connect(mapStateToProps)(ComponentWrapper);