import React from 'react'
import { Grid, Collapse, CircularProgress } from '@material-ui/core'
import * as Icon from "react-feather";
import Axios from 'axios';
import isEmpty from '../../../../validation';
import store from '../../../../store';
import moment from 'moment';
import Util from '../../../../util';
import NoMessage from '../../../../icons/no-message';
import { v1 as uuidv1 } from 'uuid';
import _ from "lodash";
const queryString = require('query-string');

class ChatBox extends React.Component {
    state = {
        activeInquiry: null,
        inquiries: [],
        message: "",
        messages: [],
        amount: 0,
        showError: false,
        amountInvalid: false,
        file: null,
        file_result: null,
        showSessionFinished: true
    }

    componentDidMount() {
        const { auth } = this.props;
        const searchQuery = queryString.parse(this.props.location.search);

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kWTHLe", auth.id);
        formData.append("KzLwhe", 999);
        formData.append("CYcmMz", 0);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zjKVHjtyMW4hD5LP",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Yt7MYPX2sn72rvsA",
                    password: "Bbf9h6vmQYAxpBfc"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BbcgeL") {
                let inquiries = [];
                let messages = [];

                respond.data.forEach(inquiry => {
                    if (!isEmpty(searchQuery) && !isEmpty(searchQuery.question_id) && searchQuery.question_id == inquiry.id) {
                        this.handleSetActiveInquiry(inquiry)()
                    }

                    if (!isEmpty(inquiry.attachments)) {
                        inquiry.attachments.forEach(attachment => {
                            messages.push({
                                customer_user: null,
                                id: uuidv1(),
                                inquiry: inquiry.id,
                                modified_at: inquiry.created_at,
                                modified_by: inquiry.modified_by,
                                name: inquiry.name,
                                file_content: {
                                    ...attachment,
                                    url1: attachment.url1,
                                    url2: attachment.url2
                                },
                                tutor_user: null,
                                type: "default"
                            })
                        })
                    }

                    messages.push({
                        customer_user: null,
                        id: uuidv1(),
                        inquiry: inquiry.id,
                        modified_at: inquiry.created_at,
                        modified_by: inquiry.modified_by,
                        name: inquiry.name,
                        text_content: inquiry.content,
                        tutor_user: null,
                        type: "default"
                    })

                    this.setState({
                        messages
                    })

                    var formData = new FormData();
                    formData.append("UVPtFS", inquiry.id);
                    formData.append("aauFaq", 9999);
                    formData.append("HvERKy", 0);

                    Axios.post(
                        "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                        formData,
                        {
                            headers: {
                                'Content-Type': "multiple/form-data"
                            },
                            auth: {
                                username: "wEyp8sY6tVmQ7Jj8",
                                password: "mkxzK4XGR3qyS3St"
                            }
                        }
                    ).then(respond => {
                        if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                            respond.data.forEach(message => {
                                messages.push(message);
                            })

                            this.setState({
                                messages
                            })
                        }
                    });

                    inquiries.push(inquiry)
                });

                this.setState({
                    inquiries
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.interval = setInterval(() => {
            this.getInquiries();
            this.getMessages();
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        clearTimeout(this.timeout)
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !(_.isEqual(this.state.inquiries, prevState.inquiries)) &&
            _.isEqual(this.state.activeInquiry, prevState.activeInquiry)

        ) {
            if (!isEmpty(this.state.activeInquiry)) {
                this.state.inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
                    this.handleSetActiveInquiry(inquiry)();
                })
            }
        }

    }

    getInquiries = () => {
        const { auth } = this.props;
        let inquiries = this.state.inquiries;

        var formData = new FormData();
        formData.append("kWTHLe", auth.id);
        formData.append("KzLwhe", 999);
        formData.append("CYcmMz", 0);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zjKVHjtyMW4hD5LP",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Yt7MYPX2sn72rvsA",
                    password: "Bbf9h6vmQYAxpBfc"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BbcgeL") {
                const newInquiries = respond.data;

                let isDifferent = false;

                if (!(_.isEqual(newInquiries, inquiries))) {
                    inquiries = [
                        ...newInquiries
                    ];

                    isDifferent = true;
                }

                if (isDifferent) {
                    const messages = _.cloneDeep(this.state.messages);

                    newInquiries.filter(item => messages.every(message => message.inquiry !== item.id)).forEach(inquiry => {
                        if (!isEmpty(inquiry.attachments)) {
                            inquiry.attachments.forEach(attachment => {
                                messages.push({
                                    customer_user: null,
                                    id: uuidv1(),
                                    inquiry: inquiry.id,
                                    modified_at: inquiry.created_at,
                                    modified_by: inquiry.modified_by,
                                    name: inquiry.name,
                                    file_content: {
                                        ...attachment,
                                        url1: attachment.url1,
                                        url2: attachment.url2
                                    },
                                    tutor_user: null,
                                    type: "default"
                                })
                            })
                        }

                        messages.push({
                            customer_user: null,
                            id: uuidv1(),
                            inquiry: inquiry.id,
                            modified_at: inquiry.created_at,
                            modified_by: inquiry.modified_by,
                            name: inquiry.name,
                            text_content: inquiry.content,
                            tutor_user: null,
                            type: "default"
                        });
                    })

                    this.setState({
                        inquiries,
                        messages
                    })
                }
            }
        });
    }

    getMessages = () => {
        const { inquiries } = this.state;

        inquiries.forEach(inquiry => {
            var formData = new FormData();
            formData.append("UVPtFS", inquiry.id);
            formData.append("aauFaq", 9999);
            formData.append("HvERKy", 0);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "wEyp8sY6tVmQ7Jj8",
                        password: "mkxzK4XGR3qyS3St"
                    }
                }
            ).then(respond => {

                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                    const newMessages = [];
                    let isDifferent = false;

                    if (respond.data.length !== this.state.messages.filter(message => message.type !== "default" && message.inquiry === inquiry.id).length) {
                        respond.data.forEach(newMessage => {
                            if (this.state.messages.every(item => item.id !== newMessage.id)) {
                                newMessages.push(newMessage);
                            }
                        })

                        isDifferent = true;
                    }

                    if (isDifferent && this.state.activeInquiry) {
                        this.setState({
                            messages: [
                                ...this.state.messages,
                                ...newMessages
                            ]
                        }, () => {
                            document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
                        })
                    }
                }
            });
        })
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleSetActiveInquiry = inquiry => () => {
        var myobj = document.getElementById(`timer`);
        if (document.getElementById(`loading-time`)) {
            document.getElementById(`loading-time`).style.display = "block";
        }

        if (myobj) {
            myobj.remove();
        }

        this.setState({
            activeInquiry: inquiry.id,
            amount: inquiry.status_id === 2038862711 ? inquiry.type_minimum_price : inquiry.bid_price
        }, () => {
            if (document.getElementById("main-message")) {
                document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
            }

            if (inquiry.status_id === 2083370574) {
                let unit = !isEmpty(inquiry.expected_duration_day) ? "days" : !isEmpty(inquiry.expected_duration_hour) ? "hours" : !isEmpty(inquiry.expected_duration_minute) ? "minutes" : "months";
                let time = !isEmpty(inquiry.expected_duration_day) ? inquiry.expected_duration_day : !isEmpty(inquiry.expected_duration_hour) ? inquiry.expected_duration_hour : !isEmpty(inquiry.expected_duration_minute) ? inquiry.expected_duration_minute : inquiry.expected_duration_month;

                if (document.getElementById("timer-container")) {
                    var node = document.createElement("span");
                    node.id = "timer";
                    document.getElementById("timer-container").appendChild(node);

                    var duration = moment(moment(inquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')).add(time, unit).diff(moment(), "seconds"),
                        display = document.getElementById(`timer`);

                    this.timeout = setTimeout(() => {
                        if (document.getElementById(`loading-time`)) {
                            document.getElementById(`loading-time`).style.display = "none";
                        }
                    }, 1000);

                    if (display) {
                        this.startTimer(duration, display);
                    }
                }
            }
        })
    }

    handleSendMessage = event => {
        event.preventDefault();

        const { auth } = this.props;
        const { inquiries, message, file } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        if ((!isEmpty(message) || file) && activeInquiry.status_id === 2083370574) {
            var formData = new FormData();
            formData.append("YKkQgf", activeInquiry.id);
            formData.append("FzejYB", auth.id);
            formData.append("zuGMJg", message);
            formData.append("xTFusn", file);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/GeFZ9MFBxzahGXjz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "T7zHe6dpmRb3jPzh",
                        password: "bgxEqVVK2VA2UPHd"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "KjBtfU" && respond.data !== "fFCSSE" && respond.data !== "tFDUsf") {
                    this.getMessages();
                    document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
                }
            });
        }

        this.setState({
            message: "",
            file: null,
            file_result: null
        })

    }

    handleChangeMessage = event => {
        this.setState({
            message: event.target.value
        })
    }

    handleChangeAmount = event => {
        const { inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        const value = Util.numberWithoutCommas("0" + event.target.value.toString().trim());

        this.setState({
            amount: value,
            amountInvalid: (activeInquiry.type_maximum_price && value > activeInquiry.type_maximum_price) || value < activeInquiry.type_minimum_price
        })
    }

    handleSubmitPrice = e => {
        e.preventDefault();

        const { amount, inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })


        this.setState({
            showError: isEmpty(amount) || (!isEmpty(activeInquiry.type_maximum_price) ? amount > activeInquiry.type_maximum_price : false) || amount < activeInquiry.type_minimum_price,
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("DCjXqA", activeInquiry.id);
                formData.append("sctZZt", amount);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/cAw99nkf96M6JEpe",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "a5gp4eKeV4WGpDam",
                            password: "WYGUxN4ctrAZqNPs"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ejZAQd") {
                        this.getInquiries();
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                })
            }

        })
    }

    handleClickUpload = () => {
        const { inquiries, file_result } = this.state;

        let activeInquiry = null;
        let isOutdated = false;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
            let unit = !isEmpty(activeInquiry.expected_duration_day) ? "days" : !isEmpty(activeInquiry.expected_duration_hour) ? "hours" : !isEmpty(activeInquiry.expected_duration_minute) ? "minutes" : "months";
            let time = !isEmpty(activeInquiry.expected_duration_day) ? activeInquiry.expected_duration_day : !isEmpty(activeInquiry.expected_duration_hour) ? activeInquiry.expected_duration_hour : !isEmpty(activeInquiry.expected_duration_minute) ? activeInquiry.expected_duration_minute : activeInquiry.expected_duration_month;

            isOutdated = moment().diff(moment(moment(activeInquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time
        })

        if (!file_result && activeInquiry.status_id === 2083370574 && !isOutdated) {
            document.getElementById("file-chat").click();
        }
    }

    handleUpload = event => {
        this.setState({
            file: event.target.files[0]
        })

        event.preventDefault();

        if (event.target.files[0]) {
            if (event.target.files[0].type.includes("video")) {
                this.setState({
                    file_result: URL.createObjectURL(event.target.files[0])
                });
            } else {
                let reader = new FileReader();
                let file = event.target.files[0];
                let _this = this;

                reader.onloadend = () => {
                    _this.setState({
                        file_result: reader.result
                    });
                }

                reader.readAsDataURL(file)
            }
        }
    }

    startTimer = (duration, display) => {
        var seconds = duration;

        this.interval = () => {
            var days = Math.floor(seconds / 24 / 60 / 60);
            var hoursLeft = Math.floor((seconds) - (days * 86400));
            var hours = Math.floor(hoursLeft / 3600);
            var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
            var minutes = Math.floor(minutesLeft / 60);
            var remainingSeconds = seconds % 60;
            function pad(n) {
                return (n < 10 ? "0" + n : n);
            }
            display.innerHTML = pad(days) + "d " + pad(hours) + "h " + pad(minutes) + "m " + pad(remainingSeconds) + "s";
            if (seconds == 0) {
            } else {
                seconds--;
            }
        }

        setInterval(this.interval, 1000);
    }

    handleCloseSessionHasFinished = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kEeQBf", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Eqkw6fQ9WLGNEyDb",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WnJMSgcjLxY9H3AW",
                    password: "gss6xQ9hEASBZ3YT"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "vCzVak") {
                this.getInquiries();
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseSessionHasStarted = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kEeQBf", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Eqkw6fQ9WLGNEyDb",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WnJMSgcjLxY9H3AW",
                    password: "gss6xQ9hEASBZ3YT"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "vCzVak") {
                this.getInquiries();
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { auth } = this.props;
        const { inquiries, message, messages, amount, showError, amountInvalid, file, file_result } = this.state;

        let activeInquiry = null;
        let isOutdated = false;
        let last_read_tutor_user = null;
        let modified_at = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry;

            let unit = !isEmpty(activeInquiry.expected_duration_day) ? "days" : !isEmpty(activeInquiry.expected_duration_hour) ? "hours" : !isEmpty(activeInquiry.expected_duration_minute) ? "minutes" : "months";
            let time = !isEmpty(activeInquiry.expected_duration_day) ? activeInquiry.expected_duration_day : !isEmpty(activeInquiry.expected_duration_hour) ? activeInquiry.expected_duration_hour : !isEmpty(activeInquiry.expected_duration_minute) ? activeInquiry.expected_duration_minute : activeInquiry.expected_duration_month;

            isOutdated = moment().diff(moment(moment(activeInquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time
            if (inquiry.last_read_tutor_user !== null) {
                last_read_tutor_user = moment(moment(inquiry.last_read_tutor_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
            }
            modified_at = moment(moment(inquiry.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
        })

        let showSessionFinished = activeInquiry && activeInquiry.status_id === 2083370574 && isOutdated && (last_read_tutor_user !== null ? last_read_tutor_user.isBefore(modified_at, "second") : true);
        let showSessionStart = activeInquiry && activeInquiry.type_id !== 2045482424 && activeInquiry.status_id === 2083370574 && (last_read_tutor_user !== null ? last_read_tutor_user.isBefore(modified_at, "second") : true);

        return (
            <div className="chat-box-container">
                <div className="content container ptb-80">
                    <div className="chat-box">
                        <div className="chat-list">
                            {/* <div className="search-message">
                                <div className="input-icons">
                                    <i className="icon">
                                        {<Icon.Search />}
                                    </i>
                                    <input
                                        type="text"
                                        name="message"
                                        placeholder="Search message"
                                        className="input-field"
                                    />
                                </div>
                            </div> */}
                            <div className="list">
                                {inquiries.length <= 0 &&
                                    <div className="empty-state">
                                        <p>
                                            Tidak ada pertanyaan yang tersedia
                                        </p>
                                    </div>
                                }
                                {inquiries.filter(item => item.status_id !== 2070045802 && item.status_id !== 2080985413).sort(function (a, b) { return new Date(b.created_at) - new Date(a.created_at); }).map((inquiry, index) => {
                                    const currentMessages = messages.filter(item => item.inquiry === inquiry.id).sort(function (a, b) { return new Date(a.modified_at) - new Date(b.modified_at); })

                                    return (
                                        <div key={index} className={`single-chat ${activeInquiry && activeInquiry.id == inquiry.id ? "active" : ""}`} onClick={this.handleSetActiveInquiry(inquiry)}>
                                            <div className="profile-image">
                                                <img src={inquiry.customer_user.profile_picture} />
                                            </div>
                                            <div className="chat-info">
                                                <div className="display-name">
                                                    <p>{inquiry.id} - {inquiry.customer_user.name} </p>
                                                </div>
                                                {!isEmpty(currentMessages) &&
                                                    <div className="last-message">
                                                        <p>{unescape(currentMessages[currentMessages.length - 1].text_content)}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="chat-message">
                            {activeInquiry &&
                                <>
                                    <div className="single-chat-info">
                                        <div className="profile-image">
                                            <img src={activeInquiry.customer_user.profile_picture} />
                                        </div>
                                        <div className="display-name">
                                            <p>{activeInquiry.customer_user.name}</p>
                                        </div>
                                        <div className="upload-file-button">
                                            {<Icon.Paperclip onClick={this.handleClickUpload} />}
                                            <input id="file-chat" type="file" name="file-chat" style={{ display: "none" }} onChange={this.handleUpload} />
                                        </div>
                                    </div>
                                    {activeInquiry.status_id === 2083370574 && !isOutdated &&
                                        <div id="timer-container" style={{ width: "100%", height: "40px", background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)", boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.08)", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CircularProgress id="loading-time" size={20} style={{ color: "white" }} />
                                            <span id={"timer"} style={{ display: "flex" }}> </span>
                                        </div>
                                    }
                                    <div className="overlay" style={{ height: Boolean(file_result) ? "calc(100% - 75px)" : "0%", padding: 0, transition: "0.3s all", bottom: 0, top: "unset" }}>
                                        <div className="dialog-price dialog-preview-file" style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", borderRadius: 0 }}>
                                            <div className="dialog-title" style={{ flex: "10%", display: "flex", alignItems: "center" }}>
                                                <Icon.X style={{ margin: "10px", color: "white", cursor: "pointer" }} onClick={() => this.setState({ file: null, file_result: null })} />
                                                <h6>Preview</h6>
                                            </div>
                                            <div className="dialog-content" style={{ flex: "80%", maxHeight: "calc(80% - 30px)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                {file && file.type.includes("image") ?
                                                    <>
                                                        <img src={file_result} style={{ maxHeight: "calc(80% - 30px)", flex: "0 0 90%", marginBottom: 20 }} />
                                                        <textarea
                                                            onChange={this.handleChangeMessage}
                                                            placeholder="Tambahkan keterangan"
                                                            className="input-field"
                                                            disabled={activeInquiry.status_id !== 2083370574}
                                                        >
                                                            {message}
                                                        </textarea>
                                                    </>
                                                    : file && file.type.includes("video") ?
                                                        <>
                                                            <video controls style={{ flex: "0 0 90%", maxHeight: "calc(80% - 30px)", marginBottom: 20 }} disablePictureInPicture controlsList="nodownload">
                                                                <source src={file_result} type="video/mp4" />
                                                                <source src={file_result} type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            <textarea
                                                                onChange={this.handleChangeMessage}
                                                                placeholder="Tambahkan keterangan"
                                                                className="input-field"
                                                                disabled={activeInquiry.status_id !== 2083370574}
                                                            >
                                                                {message}
                                                            </textarea>
                                                        </>
                                                        : file &&
                                                        <Grid container alignItems="center" justify="center" direction="column">
                                                            <Icon.File style={{ marginBottom: 10, height: 30, width: 30 }} />
                                                            <p>{file.name}</p>
                                                        </Grid>
                                                }
                                            </div>
                                            <div className="dialog-action-button" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", background: "#e3e3e3", flex: "10%" }}>
                                                <div className="send-button">
                                                    <Icon.Send
                                                        onClick={this.handleSendMessage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showSessionFinished &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content" }}>
                                                <div className="dialog-title">
                                                    <h6>Waktu Habis!</h6>
                                                </div>
                                                <div className="dialog-content">
                                                    <p>Pekerjaan Anda akan di review dalam 1x24 jam</p>
                                                    <div className="dialog-footer">
                                                        <button className="btn btn-primary" onClick={this.handleCloseSessionHasFinished(activeInquiry.id)}>Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {showSessionStart &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content" }}>
                                                <div className="dialog-title">
                                                    <h6>Selamat penawaran harga anda telah diterima!</h6>
                                                </div>
                                                <div className="dialog-content">
                                                    <p>
                                                        Anda mempunyai waktu {activeInquiry.expected_duration_day > 0 ?
                                                            (activeInquiry.expected_duration_day + " Hari") :
                                                            activeInquiry.expected_duration_hour > 0 ?
                                                                (activeInquiry.expected_duration_hour + " Jam") :
                                                                (activeInquiry.expected_duration_month + " Bulan")} untuk menjawab pertanyaan!
                                                    </p>
                                                    <div className="dialog-footer">
                                                        <button className="btn btn-primary" onClick={this.handleCloseSessionHasStarted(activeInquiry.id)}>Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {activeInquiry.type_id !== 2045482424 &&
                                        (
                                            activeInquiry.status_id === 2038862711 ||
                                            activeInquiry.status_id === 2063870995 ||
                                            activeInquiry.status_id === 2034577039 ||
                                            activeInquiry.status_id === 2077351391 ||
                                            activeInquiry.status_id === 2044457826 ||
                                            activeInquiry.status_id === 2098716420
                                        ) &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content" }}>
                                                <div className="dialog-title">
                                                    <h6>{activeInquiry.status_id === 2038862711 ? "Tentukan harga untuk pekerjaan Anda" : "Permintaan Negosiasi dari User"}</h6>
                                                </div>
                                                <form onSubmit={this.handleSubmitPrice}>
                                                    <div className="dialog-content">
                                                        <p>
                                                            {!isEmpty(activeInquiry.type_maximum_price) &&
                                                                <>
                                                                    <b>Rate</b>: {Util.currencyFormat(activeInquiry.type_minimum_price)} - {Util.currencyFormat(activeInquiry.type_maximum_price)}
                                                                </>
                                                            }
                                                            {isEmpty(activeInquiry.type_maximum_price) &&
                                                                <>
                                                                    <b>Rate</b>: Min. {Util.currencyFormat(activeInquiry.type_minimum_price)}
                                                                </>
                                                            }
                                                        </p>
                                                        <div className="input-group inquiry-input-price">
                                                            <div className="input-group-prepend" style={{ height: 35 }}>
                                                                <span className="input-group-text" id="currency">IDR</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder="Masukkan nominal"
                                                                value={Util.numberWithCommas(amount)}
                                                                onChange={this.handleChangeAmount}
                                                                className={`form-control ${(showError && isEmpty(amount)) || amountInvalid ? "is-invalid" : ""}`}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Cantumkan nominal yang valid.
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary" style={{ marginTop: 10 }}>OK</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                    {(
                                        activeInquiry.status_id === 2096469076 ||
                                        activeInquiry.status_id === 2061809634 ||
                                        activeInquiry.status_id === 2079997237 ||
                                        activeInquiry.status_id === 2076844205 ||
                                        activeInquiry.status_id === 2059677011 ||
                                        activeInquiry.status_id === 2005449352
                                    ) &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content" }}>
                                                <div className="dialog-title">
                                                    <h6>Mohon tunggu sebentar</h6>
                                                </div>
                                                <div className="dialog-content">
                                                    <p>Menunggu persetujuan</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div id="main-message" className="message-info">
                                        {activeInquiry.status_id === 2083370574 &&
                                            messages.filter(item => item.inquiry === activeInquiry.id).sort(function (a, b) {
                                                return new Date(moment(a.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) - new Date(moment(b.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                            }).map(message =>
                                                <React.Fragment key={message.id}>
                                                    {!isEmpty(message.text_content) &&
                                                        <div className={`single-message-container ${message.tutor_user === auth.id ? "message-out" : "message-in"}`}>
                                                            <div className="single-message">
                                                                <div className="message">
                                                                    <p>{unescape(message.text_content)}</p>
                                                                </div>
                                                                <div className="time-date">
                                                                    <span>{moment(message.modified_at).utc().tz(`Asia/Jakarta`).format("HH:mm")} {moment().isSame(moment(message.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'), "day") ? "Hari ini" : moment().utc().tz(`Asia/Jakarta`).format("dddd")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!isEmpty(message.file_content) &&
                                                        <div key={message.id} className={`single-message-container ${message.tutor_user === auth.id ? "message-out" : "message-in"}`}>
                                                            <div className="single-message">
                                                                <div className="message" style={{ padding: 5 }}>
                                                                    {!isEmpty(message.file_content) && message.file_content.type.includes("image") ?
                                                                        <div className="image-container">
                                                                            <a className="image" href={message.file_content.url2} target="_blank" download style={{ padding: 0, widht: 300, height: "100%" }}>
                                                                                <img src={message.file_content.url1} style={{ maxHeight: 300, borderRadius: 12 }} />
                                                                            </a>
                                                                        </div>
                                                                        : !isEmpty(message.file_content) && message.file_content.type.includes("video") ?
                                                                            <div className="image-container">
                                                                                <a className="image" href={message.file_content.url2} target="_blank" download style={{ padding: 0, widht: 300, height: "100%" }}>
                                                                                    <video controls style={{ maxWidth: "100%" }} disablePictureInPicture controlsList="nodownload">
                                                                                        <source src={message.file_content.url1} type="video/mp4" />
                                                                                        <source src={message.file_content.url1} type="video/ogg" />
                                                                                        Your browser does not support the video tag.
                                                                                    </video>
                                                                                </a>
                                                                            </div>
                                                                            : !isEmpty(message.file_content) &&
                                                                            <div className="file-container">
                                                                                <a className="file" href={message.file_content.url2} target="_blank" download>
                                                                                    <Icon.File style={{ height: 30, width: 30, color: "#000" }} />
                                                                                </a>
                                                                                <p>{message.file_content.name}</p>
                                                                            </div>}
                                                                </div>
                                                                <div className="time-date">
                                                                    <span>{moment(message.modified_at).utc().tz(`Asia/Jakarta`).format("HH:mm")} {moment().isSame(moment(message.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'), "day") ? "Hari ini" : moment().utc().tz(`Asia/Jakarta`).format("dddd")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                    </div>

                                    <form onSubmit={this.handleSendMessage}>
                                        <div className="message-input">
                                            <input
                                                type="text"
                                                value={message}
                                                onChange={this.handleChangeMessage}
                                                placeholder="Ketik pesan"
                                                className="input-field"
                                                disabled={activeInquiry.status_id !== 2083370574 || (activeInquiry.status_id === 2083370574 && isOutdated)}
                                                autoFocus
                                            />
                                            <div className="send-button">
                                                {<Icon.Send onClick={this.handleSendMessage} />}
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }
                            {!activeInquiry &&
                                <div style={{ flex: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div className="empty-state">
                                        <div className="icon-container">
                                            <NoMessage />
                                        </div>
                                        <p style={{ fontSize: 20 }}>
                                            Percakapan tidak tersedia
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

export default ChatBox