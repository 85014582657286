import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import _ from "lodash";
import isEmpty from '../../validation';
import { Grow, Portal } from '@material-ui/core';

//Custom Style

const styles = theme => ({
    prevIconContainer: {
        position: "absolute",
        top: "20px !important",
        right: 50,
        padding: "8px !important",

        "& svg": {
            fontSize: "24px"
        }
    },
    nextIconContainer: {
        position: "absolute",
        top: "20px !important",
        right: 20,
        padding: "8px !important",

        "& svg": {
            fontSize: "24px"
        }
    },
})

class DateRange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            focusedInput: null,
            position: {}
        };
    }

    renderMonthText = date => <p>{date.format("MMMM YYYY")}</p>

    handleClick = name => event => {
        const { calendarRef } = this.props;
        this.setState({ focusedInput: name });

        const calendar = this.calendar;
        const container = this.container;

        const currentContainer = container.getBoundingClientRect();
        const rootContainer = document.getElementById("root");

        // if (calendarRef.offsetWidth >= 770 && (rootContainer.offsetHeight - currentContainer.bottom + 10) < 309) {
        //     calendar.style.top = (currentContainer.top - 299) + "px";

        //     if ((rootContainer.offsetWidth - (currentContainer.left + currentContainer.width)) < 619) {
        //         calendar.style.left = (currentContainer.left + currentContainer.width - 619) + "px";
        //     } else {
        //         calendar.style.left = (currentContainer.left) + "px";
        //     }
        // } else {
        // calendar.style.top = (currentContainer.bottom + 10) + "px";
        // calendar.style.left = currentContainer.left + "px";
        // if (calendarRef.offsetWidth >= 770) {
        //     calendar.style.left = (currentContainer.width - 619) + "px";
        // } else {
        //     calendar.style.left = 0;
        // }

        //     if (calendarRef.offsetWidth >= 770) {
        //         if ((rootContainer.offsetWidth - (currentContainer.left + currentContainer.width)) < 619) {
        //             calendar.style.left = (currentContainer.left + currentContainer.width - 619) + "px";
        //         } else {
        //             calendar.style.left = (currentContainer.left) + "px";
        //         }
        //     }
        // }

        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    handleClose = () => this.setState({ focusedInput: null });

    handleFocusChange = focusedInput => {
        this.setState({ focusedInput });
    }

    componentDidMount() {
        const container = ReactDOM.findDOMNode(this.container);
        this.setState({ position: container.getBoundingClientRect() });
    }

    handleClearValue = event => {
        event.stopPropagation();

        if (this.props.handleClearValue) {
            this.props.handleClearValue();
        }
    }

    render() {
        const {
            id,
            startDate,
            endDate,
            disabledStart,
            disabledEnd,
            onChange,
            onClick,
            onBlur,
            classes,
            error,
            placeholderStart,
            placeholderEnd,
            display,
            labelStart,
            labelEnd,
            enablePastDays,
            handleClearValue,
            isDayBlocked,
            anchor,
            calendarRef,
            separatedFields,
            ...rest
        } = this.props;
        const { focusedInput, position } = this.state;

        const currentStartDate = !isEmpty(startDate) ? moment(startDate).format("dddd, DD/MM/YY") : "";
        const currentEndDate = !isEmpty(endDate) ? moment(endDate).format("dddd, DD/MM/YY") : "";
        const currentDateMerged = !isEmpty(startDate) && !isEmpty(endDate) ? moment(startDate).format("DD MMM YYYY") + " - " + moment(endDate).format("DD MMM YYYY") : "";

        const handleClickStartDate = disabledStart ? () => { } : this.handleClick("startDate");
        const handleClickEndDate = disabledEnd ? () => { } : this.handleClick("endDate");

        return (
            <div style={{ position: "relative" }} ref={ref => this.container = ref}>
                <div style={{ display: display === "inline" ? "flex" : "block", alignItems: "center", width: "100%" }}>
                    {separatedFields ? <>
                        <div style={{ width: "100%" }}>
                            <div className="form-group" style={{ marginBottom: 0 }}>
                                <input
                                    type="text"
                                    value={currentStartDate}
                                    onClick={handleClickStartDate}
                                    label={labelStart}
                                    placeholder={placeholderStart}
                                    className={`form-control`}
                                />
                            </div>
                        </div>
                        <div style={{ padding: 8 }}></div>
                        <div style={{ width: "100%" }}>
                            <div className="form-group" style={{ marginBottom: 0 }}>
                                <input
                                    type="text"
                                    value={currentEndDate}
                                    onClick={handleClickEndDate}
                                    label={labelEnd}
                                    placeholder={placeholderEnd}
                                    className={`form-control`}
                                />
                            </div>
                        </div>
                    </> :
                        <div style={{ width: "100%" }}>
                            <div className="form-group" style={{ marginBottom: 0 }}>
                                <input
                                    type="text"
                                    value={currentDateMerged}
                                    onClick={handleClickStartDate}
                                    label={labelStart}
                                    placeholder={placeholderStart}
                                    className={`form-control`}
                                />
                            </div>
                        </div>
                    }
                </div>
                {/* <Portal container={calendarRef}> */}
                <Grow
                    in={!isEmpty(focusedInput)}
                    style={{ transformOrigin: '0 0 0' }}
                >
                    <div ref={ref => this.calendar = ref} style={{ position: "absolute", zIndex: 1500, height: 339 }}>
                        <DayPickerRangeController
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={onChange}
                            focusedInput={focusedInput}
                            onFocusChange={this.handleFocusChange}
                            onOutsideClick={this.handleClose}
                            numberOfMonths={2}
                            renderMonthText={this.renderMonthText}
                            weekDayFormat="ddd"
                            hideKeyboardShortcutsPanel
                            isOutsideRange={day => enablePastDays ? false : moment(day).isBefore(moment(), "day")}
                            isDayBlocked={isDayBlocked}
                            numberOfMonths={1}
                        />
                    </div>
                </Grow>
                {/* </Portal> */}
            </div>
        );
    }
}

DateRange.defaultProps = {
    format: "ddd, D MMM YYYY",
    display: "inline",
    calendarRef: document.getElementById("root"),
    separatedFields: true,
};

DateRange.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    hint: PropTypes.string,
    fullWidth: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,

    value: PropTypes.object,
    onBlur: PropTypes.func,
    handleClearValue: PropTypes.func,
    enablePastDays: PropTypes.bool,
    isDayBlocked: PropTypes.func,
    anchor: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    display: PropTypes.oneOf(["block", "inline"]),
    // startDate: PropTypes.string,
    // endDate: PropTypes.string,
    disabledStart: PropTypes.bool,
    disabledEnd: PropTypes.bool,
    placeholderStart: PropTypes.string,
    placeholderEnd: PropTypes.string,
    labelStart: PropTypes.string,
    labelEnd: PropTypes.string,
    separatedFields: PropTypes.bool,
};

export default withStyles(styles)(DateRange);