import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import * as Icon from "react-feather";
import Axios from 'axios';
import isEmpty from '../../../../validation';
import moment from 'moment';
import store from '../../../../store';
import Util from '../../../../util';
import NoMessage from '../../../../icons/no-message';
import PaymentTutorSuccess from '../../Dialog/PaymentTutorSuccess';
import SuccessDeclineTutor from '../../Dialog/SuccessDeclineTutor';
import { v1 as uuidv1 } from 'uuid';
import swal from 'sweetalert';
import TopUpBalance from '../../Dialog/TopUpBalance';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import setAuthToken from '../../../../validation/setAuthToken';
import AuthActions from '../../../../actions/AuthActions';
import _ from "lodash";
import PaymentPending from '../../Dialog/PaymentPending';
const queryString = require('query-string');

class Chat extends React.Component {
    state = {
        activeInquiry: null,
        openTopUpBalance: false,
        openSuccessPayment: false,
        openConfirmationDialog: false,
        openSuccessDeclineTutorDialog: false,
        openSuccessReport: false,
        showNego: false,
        showRateUs: true,
        inquiries: [],
        message: "",
        report: "",
        messages: []
    }

    componentDidMount() {
        const { auth } = this.props;
        const searchQuery = queryString.parse(this.props.location.search);

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("nRLkxq", auth.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "DR7LpCF5GN26wVhn",
                    password: "V4UwWvftU8fTRSCZ"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SpLtDH") {
                let inquiries = [];
                let messages = [];

                respond.data.forEach(inquiry => {
                    if (!isEmpty(searchQuery) && !isEmpty(searchQuery.question_id) && searchQuery.question_id == inquiry.id) {
                        this.handleSetActiveInquiry(inquiry)()
                    }

                    if (!isEmpty(inquiry.attachments)) {
                        inquiry.attachments.forEach(attachment => {
                            messages.push({
                                customer_user: null,
                                id: uuidv1(),
                                inquiry: inquiry.id,
                                modified_at: inquiry.created_at,
                                modified_by: inquiry.modified_by,
                                name: inquiry.name,
                                file_content: {
                                    ...attachment,
                                    url1: attachment.url1,
                                    url2: attachment.url2
                                },
                                tutor_user: null,
                                type: "default"
                            })
                        })
                    }

                    messages.push({
                        customer_user: null,
                        id: uuidv1(),
                        inquiry: inquiry.id,
                        modified_at: inquiry.created_at,
                        modified_by: inquiry.modified_by,
                        name: inquiry.name,
                        text_content: inquiry.content,
                        tutor_user: null,
                        type: "default"
                    })

                    this.setState({
                        messages
                    })

                    var formData = new FormData();
                    formData.append("UVPtFS", inquiry.id);
                    formData.append("aauFaq", 9999);
                    formData.append("HvERKy", 0);

                    Axios.post(
                        "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                        formData,
                        {
                            headers: {
                                'Content-Type': "multiple/form-data"
                            },
                            auth: {
                                username: "wEyp8sY6tVmQ7Jj8",
                                password: "mkxzK4XGR3qyS3St"
                            }
                        }
                    ).then(respond => {
                        if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                            respond.data.forEach(message => {
                                messages.push(message);
                            })

                            this.setState({
                                messages
                            })
                        }
                    });

                    inquiries.push(inquiry)
                });

                this.setState({
                    inquiries
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.interval = setInterval(() => {
            if (auth) {
                this.getInquiries();
                this.getMessages();
            }
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        clearTimeout(this.timeout)
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !(_.isEqual(this.state.inquiries, prevState.inquiries)) &&
            _.isEqual(this.state.activeInquiry, prevState.activeInquiry)

        ) {
            if (!isEmpty(this.state.activeInquiry)) {
                this.state.inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
                    this.handleSetActiveInquiry(inquiry)();
                })
            }
        }

    }

    getInquiries = () => {
        const { auth } = this.props;
        let inquiries = this.state.inquiries;

        var formData = new FormData();
        formData.append("nRLkxq", auth.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "DR7LpCF5GN26wVhn",
                    password: "V4UwWvftU8fTRSCZ"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SpLtDH") {
                const newInquiries = respond.data;

                let isDifferent = false;

                if (!(_.isEqual(newInquiries, inquiries))) {
                    inquiries = [
                        ...newInquiries
                    ];

                    isDifferent = true;
                }

                if (isDifferent && this.state.activeInquiry) {
                    this.setState({
                        inquiries
                    })
                }

                inquiries.forEach(inquiry => {
                    if (this.state.messages.every(item => item.type !== "default")) {
                        let messages = _.cloneDeep(this.state.messages);

                        if (!isEmpty(inquiry.attachments)) {
                            inquiry.attachments.forEach(attachment => {
                                messages.push({
                                    customer_user: null,
                                    id: uuidv1(),
                                    inquiry: inquiry.id,
                                    modified_at: inquiry.created_at,
                                    modified_by: inquiry.modified_by,
                                    name: inquiry.name,
                                    file_content: {
                                        ...attachment,
                                        url1: attachment.url1,
                                        url2: attachment.url2
                                    },
                                    tutor_user: null,
                                    type: "default"
                                })
                            })
                        }

                        messages.push({
                            customer_user: null,
                            id: uuidv1(),
                            inquiry: inquiry.id,
                            modified_at: inquiry.created_at,
                            modified_by: inquiry.modified_by,
                            name: inquiry.name,
                            text_content: inquiry.content,
                            tutor_user: null,
                            type: "default"
                        })

                        this.setState({
                            messages
                        })
                    }
                })
            }
        });
    }

    getMessages = () => {
        const { inquiries } = this.state;
        let messages = this.state.messages;

        inquiries.forEach(inquiry => {
            var formData = new FormData();
            formData.append("UVPtFS", inquiry.id);
            formData.append("aauFaq", 9999);
            formData.append("HvERKy", 0);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "wEyp8sY6tVmQ7Jj8",
                        password: "mkxzK4XGR3qyS3St"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                    const newMessages = [];
                    let isDifferent = false;

                    if (respond.data.length !== this.state.messages.filter(message => message.type !== "default" && message.inquiry === inquiry.id).length) {
                        respond.data.forEach(newMessage => {
                            if (this.state.messages.every(item => item.id !== newMessage.id)) {
                                newMessages.push(newMessage);
                            }
                        })

                        isDifferent = true;
                    }

                    if (isDifferent && this.state.activeInquiry) {
                        this.setState({
                            messages: [
                                ...this.state.messages,
                                ...newMessages
                            ]
                        })
                    }
                }
            });
        })
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleTopUpBalance = () => {
        this.setState({
            balanceInsufficient: false,
            openTopUpBalance: true
        })
    }

    handleCloseTopUpBalance = () => {
        this.setState({
            openTopUpBalance: false
        })
    }

    handlePay = () => {
        const { auth } = this.props;
        const { inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        if (activeInquiry) {
            if (activeInquiry.bid_price <= auth.balance) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("YeQMFK", activeInquiry.id);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Kc78rwP4tjHkkwgD",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "F5jMpRdXV7JHfUzx",
                            password: "ykYksrgnh9QQBB5D"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CRfFAt") {
                        this.getInquiries();
                        this.setState({
                            openSuccessPayment: true
                        });

                        // var myobj = document.getElementById(`timer`);
                        // if (document.getElementById(`loading-time`)) {
                        //     document.getElementById(`loading-time`).style.display = "block";
                        // }

                        // if (myobj) {
                        //     myobj.remove();
                        // }

                        // document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);

                        // let unit = !isEmpty(activeInquiry.expected_duration_day) ? "days" : !isEmpty(activeInquiry.expected_duration_hour) ? "hours" : !isEmpty(activeInquiry.expected_duration_minute) ? "minutes" : "months";
                        // let time = !isEmpty(activeInquiry.expected_duration_day) ? activeInquiry.expected_duration_day : !isEmpty(activeInquiry.expected_duration_hour) ? activeInquiry.expected_duration_hour : !isEmpty(activeInquiry.expected_duration_minute) ? activeInquiry.expected_duration_minute : activeInquiry.expected_duration_month;

                        // if (document.getElementById("timer-container")) {
                        //     var node = document.createElement("span");
                        //     node.id = "timer";
                        //     document.getElementById("timer-container").style.display = "flex";
                        //     document.getElementById("timer-container").appendChild(node);

                        //     var duration = moment(moment().utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')).add(time, unit).diff(moment(), "seconds"),
                        //         display = document.getElementById(`timer`);

                        //     this.timeout = () => {
                        //         if (document.getElementById(`loading-time`)) {
                        //             document.getElementById(`loading-time`).style.display = "none";
                        //         }
                        //     }

                        //     setTimeout(this.timeout, 1000);
                        //     if (display) {
                        //         this.startTimer(duration, display);
                        //     }
                        //     }
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            } else {
                swal(
                    "Error!",
                    `Saldo tidak cukup \n Minimal saldo adalah ${Util.currencyFormat(activeInquiry.bid_price)}`,
                    "error",
                    {
                        buttons: {
                            topUpBalance: "Top Up Saldo",
                            cancel: "Batalkan"
                        },
                        className: "balance-insufficient"
                    },
                ).then((value) => {
                    if (value) {
                        this.handleTopUpBalance()
                    }
                });
            }
        }
    }

    handleSetBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VwxCYt", this.props.auth.id);
        formData.append("EzEZnB", amount);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/UqbHAbH3En55aHam",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p5Hym9fAguNh8FxK",
                    password: "TWEDHGZn7Fmx3wfW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ERR") {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                window.snap.pay(respond.data.token, {
                    onSuccess: async function (result) {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                        })
                    },
                    onPending: function (result) {
                        _this.setState({
                            openPaymentPending: true,
                        })
                    },
                    onError: function (result) {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    },
                    onClose: function () {
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.setState({
            openTopUpBalance: false,
        })
    }

    handleOpenConfirmationBox = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    handleDecline = () => {
        const { inquiries, openSuccessDeclineTutorDialog } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", activeInquiry.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getInquiries()

                this.setState({
                    openConfirmationDialog: false,
                    openSuccessDeclineTutorDialog: true
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleNegotiate = () => {
        const { inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("XjBvGH", activeInquiry.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/n9SZvXLa63aqTZAk",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "GyRdWhzVJgdLRu83",
                    password: "s9Mqns3huFpFkrM3"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "nFEVEG") {
                this.getInquiries()
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleSetActiveInquiry = inquiry => () => {
        var myobj = document.getElementById(`timer`);
        if (document.getElementById(`loading-time`)) {
            document.getElementById(`loading-time`).style.display = "block";
        }

        this.setState({
            openImprove: false,
            openThankyou: false,
            showReport: false
        })

        if (myobj) {
            myobj.remove();
        }

        if (inquiry.last_notified_customer_user === null) {
            var formData = new FormData();
            formData.append("jGRDfN", inquiry.id)

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/hZy7CpZN5Whnh92b",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "VB8SQfWMqAD3pJVT",
                        password: "d27R8bep2WFejtNQ"
                    }
                }
            )
        }

        this.setState({
            activeInquiry: inquiry.id,
            amount: inquiry.status_id === 2038862711 ? inquiry.type_minimum_price : inquiry.bid_price
        }, () => {
            setTimeout(() => {
                if (document.getElementById("main-message")) {
                    document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
                }
            }, 100);

            let unit = !isEmpty(inquiry.expected_duration_day) ? "days" : !isEmpty(inquiry.expected_duration_hour) ? "hours" : !isEmpty(inquiry.expected_duration_minute) ? "minutes" : "months";
            let time = !isEmpty(inquiry.expected_duration_day) ? inquiry.expected_duration_day : !isEmpty(inquiry.expected_duration_hour) ? inquiry.expected_duration_hour : !isEmpty(inquiry.expected_duration_minute) ? inquiry.expected_duration_minute : inquiry.expected_duration_month;
            let isOutdated = moment().diff(moment(moment(inquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time;

            if (inquiry.status_id === 2083370574 && !isOutdated) {
                if (document.getElementById("timer-container")) {
                    var node = document.createElement("span");
                    node.id = "timer";
                    document.getElementById("timer-container").style.display = "flex";
                    document.getElementById("timer-container").appendChild(node);

                    var duration = moment(moment(inquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')).add(time, unit).diff(moment(), "seconds"),
                        display = document.getElementById(`timer`);

                    this.timeout = () => {
                        if (document.getElementById(`loading-time`)) {
                            document.getElementById(`loading-time`).style.display = "none";
                        }
                    }

                    setTimeout(this.timeout, 1000);
                    if (display) {
                        this.startTimer(duration, display);
                    }
                }
            } else {
                if (document.getElementById("timer-container")) {
                    document.getElementById("timer-container").style.display = "none";
                }
            }
        })
    }

    handleSendMessage = event => {
        event.preventDefault();

        const { auth } = this.props;
        const { inquiries, message, file } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        if ((!isEmpty(message) || file) && activeInquiry.status_id === 2083370574) {
            var formData = new FormData();
            formData.append("YKkQgf", activeInquiry.id);
            formData.append("sxvZgE", auth.id);
            formData.append("xTFusn", file);
            formData.append("zuGMJg", message);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/GeFZ9MFBxzahGXjz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "T7zHe6dpmRb3jPzh",
                        password: "bgxEqVVK2VA2UPHd"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "KjBtfU" && respond.data !== "fFCSSE" && respond.data !== "tFDUsf") {
                    let messages = [];

                    var formData = new FormData();
                    formData.append("UVPtFS", activeInquiry.id);
                    formData.append("aauFaq", 9999);
                    formData.append("HvERKy", 0);

                    Axios.post(
                        "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ja2MWEm3mkrKCJCN",
                        formData,
                        {
                            headers: {
                                'Content-Type': "multiple/form-data"
                            },
                            auth: {
                                username: "wEyp8sY6tVmQ7Jj8",
                                password: "mkxzK4XGR3qyS3St"
                            }
                        }
                    ).then(respond => {
                        if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "gDRYWj" && respond.data !== "wtuJPH" && respond.data !== "PSjyvA") {
                            this.getMessages();

                            setTimeout(() => {
                                if (document.getElementById("main-message")) {
                                    document.getElementById("main-message").scrollTo(0, document.getElementById("main-message").scrollHeight);
                                }
                            }, 100);
                        }
                    });
                }
            });
        }

        this.setState({
            message: "",
            file: null,
            file_result: null
        })

    }

    handleChangeMessage = event => {
        this.setState({
            message: event.target.value
        })
    }

    handleChangeAmount = event => {
        const { inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        const value = Util.numberWithoutCommas("0" + event.target.value.toString().trim());

        this.setState({
            amount: value,
            amountInvalid: value > activeInquiry.type_maximum_price || value < activeInquiry.type_minimum_price
        })
    }

    handleSubmitPrice = e => {
        e.preventDefault();

        const { amount, inquiries } = this.state;

        let activeInquiry = null;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
        })

        this.setState({
            showError: isEmpty(amount) || amount > activeInquiry.type_maximum_price || amount < activeInquiry.type_minimum_price,
        }, () => {

            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("DCjXqA", activeInquiry.id);
                formData.append("sctZZt", amount);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/cAw99nkf96M6JEpe",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "a5gp4eKeV4WGpDam",
                            password: "WYGUxN4ctrAZqNPs"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ejZAQd") {
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                })
            }

        })
    }

    handleClickUpload = () => {
        const { inquiries, file_result } = this.state;

        let activeInquiry = null;
        let isOutdated = false;

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry
            let unit = !isEmpty(activeInquiry.expected_duration_day) ? "days" : !isEmpty(activeInquiry.expected_duration_hour) ? "hours" : !isEmpty(activeInquiry.expected_duration_minute) ? "minutes" : "months";
            let time = !isEmpty(activeInquiry.expected_duration_day) ? activeInquiry.expected_duration_day : !isEmpty(activeInquiry.expected_duration_hour) ? activeInquiry.expected_duration_hour : !isEmpty(activeInquiry.expected_duration_minute) ? activeInquiry.expected_duration_minute : activeInquiry.expected_duration_month;

            isOutdated = moment().diff(moment(moment(activeInquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time
        })

        if (!file_result && activeInquiry.status_id === 2083370574 && !isOutdated) {
            document.getElementById("file-chat").click();
        }
    }

    handleUpload = event => {
        this.setState({
            file: event.target.files[0]
        })

        event.preventDefault();

        if (event.target.files[0]) {
            if (event.target.files[0].type.includes("video")) {
                this.setState({
                    file_result: URL.createObjectURL(event.target.files[0])
                });
            } else {
                let reader = new FileReader();
                let file = event.target.files[0];
                let _this = this;

                reader.onloadend = () => {
                    _this.setState({
                        file_result: reader.result
                    });
                }

                reader.readAsDataURL(file)
            }
        }
    }

    handleCloseSuccessPayment = () => {
        this.setState({
            openSuccessPayment: false
        })
    }

    handleCloseSuccessDecline = () => {
        this.setState({
            openSuccessDeclineTutorDialog: false
        })
    }

    handleCloseSatisfaction = () => {
        this.setState({
            openSatisfaction: false
        })
    }

    handleThumbsUp = activeInquiry => e => {
        this.setState({
            openSatisfaction: false,
            openThankyou: true
        })

        this.handleRate(activeInquiry.id, 2062752651)(e)
    }

    handleThumbsDown = activeInquiry => e => {
        this.setState({
            openSatisfaction: false,
            openImprove: true
        })

        this.handleRate(activeInquiry.id, 2040071650)(e)
    }

    handleCloseThankyou = () => {
        this.setState({
            openThankyou: false,
            showRateUs: false
        })
    }


    handleCloseImprove = () => {
        this.setState({
            openImprove: false,
            showRateUs: false
        })
    }

    handleCloseReport = () => {
        this.setState({
            openSuccessReport: false,
            showReport: false,
            showRateUs: false
        })
    }

    handleOpenReview = () => {
        this.setState({
            openInReview: true
        })
    }

    handleReport = () => {
        this.setState({
            showReport: true
        })
    }

    startTimer = (duration, display) => {
        var seconds = duration;

        this.interval = () => {
            var days = Math.floor(seconds / 24 / 60 / 60);
            var hoursLeft = Math.floor((seconds) - (days * 86400));
            var hours = Math.floor(hoursLeft / 3600);
            var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
            var minutes = Math.floor(minutesLeft / 60);
            var remainingSeconds = seconds % 60;
            function pad(n) {
                return (n < 10 ? "0" + n : n);
            }
            display.innerHTML = pad(days) + "d " + pad(hours) + "h " + pad(minutes) + "m " + pad(remainingSeconds) + "s";
            if (seconds == 0) {
            } else {
                seconds--;
            }
        }

        setInterval(this.interval, 1000);
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleRate = (inquiryId, rate) => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("qJEHgy", inquiryId)
        formData.append("JQKzAV", rate)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/wU5SWPE4AtB8Fa8x",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "JT5SUmVkuMVs3bKc",
                    password: "VK2aK9mm3kZ6B6UW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CRfFAt" && respond.data !== "LbPGha") {
                this.getInquiries();
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSendReport = (inquiryId, report) => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("qJEHgy", inquiryId)
        formData.append("TWVreH", report)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/wU5SWPE4AtB8Fa8x",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "JT5SUmVkuMVs3bKc",
                    password: "VK2aK9mm3kZ6B6UW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CRfFAt" && respond.data !== "LbPGha") {
                this.getInquiries();

                this.setState({
                    openSuccessReport: true,
                    report: ""
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleChangeReportMessage = e => {
        this.setState({
            report: e.target.value
        })
    }

    render() {
        const { auth } = this.props;
        const { openTopUpBalance, openSuccessPayment, openSuccessReport, showReport, openThankyou, openImprove, inquiries, message, messages, report, amount, showError, amountInvalid, file, file_result, openConfirmationDialog, openSuccessDeclineTutorDialog, successResendEmail, openPaymentPending } = this.state;
        const { is_verified } = auth;

        let activeInquiry = null;
        let isOutdated = false;
        let last_read_customer_user = null;
        let modified_at = null;
        let contentInquiry = [];

        inquiries.filter(item => item.id === this.state.activeInquiry).forEach(inquiry => {
            activeInquiry = inquiry;

            let unit = !isEmpty(activeInquiry.expected_duration_day) ? "days" : !isEmpty(activeInquiry.expected_duration_hour) ? "hours" : !isEmpty(activeInquiry.expected_duration_minute) ? "minutes" : "months";
            let time = !isEmpty(activeInquiry.expected_duration_day) ? activeInquiry.expected_duration_day : !isEmpty(activeInquiry.expected_duration_hour) ? activeInquiry.expected_duration_hour : !isEmpty(activeInquiry.expected_duration_minute) ? activeInquiry.expected_duration_minute : activeInquiry.expected_duration_month;

            isOutdated = moment().diff(moment(moment(activeInquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time
            if (inquiry.last_read_customer_user !== null) {
                last_read_customer_user = moment(moment(inquiry.last_read_customer_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
            }
            modified_at = moment(moment(inquiry.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
        })

        let showRateUs = (activeInquiry && isEmpty(activeInquiry.rate) && isEmpty(activeInquiry.report) && activeInquiry.status_id === 2083370574 && isOutdated)

        return (
            <div className="chat-box-container">
                <div className="content container ptb-80">
                    <div className="row">
                        <div className="col-12">
                            {successResendEmail &&
                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                        <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                    </span>
                                </div>
                            }
                            {!successResendEmail && isEmpty(is_verified) &&
                                <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>
                                        Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                        <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="chat-box">
                        <div className="chat-list">
                            {/* <div className="search-message">
                                <div className="input-icons">
                                    <i className="icon">
                                        {<Icon.Search />}
                                    </i>
                                    <input
                                        type="text"
                                        name="message"
                                        placeholder="Search message"
                                        className="input-field"
                                    />
                                </div>
                            </div> */}
                            <div className="list">
                                {inquiries.length <= 0 &&
                                    <div className="empty-state">
                                        <p>
                                            Tidak ada pertanyaan yang tersedia
                                        </p>
                                    </div>
                                }
                                {inquiries.filter(item => item.status_id !== 2070045802 && item.status_id !== 2080985413).sort(function (a, b) { return new Date(b.created_at) - new Date(a.created_at); }).map((inquiry, index) => {
                                    const currentMessages = messages.filter(item => item.inquiry === inquiry.id).sort(function (a, b) { return new Date(a.modified_at) - new Date(b.modified_at); });

                                    return (
                                        <div key={index} className={`single-chat ${activeInquiry && activeInquiry.id == inquiry.id ? "active" : ""}`} onClick={this.handleSetActiveInquiry(inquiry)}>
                                            <div className="profile-image">
                                                <img src={"/assets/images/default-image/profile.png"} />
                                            </div>
                                            <div className="chat-info">
                                                <div className="display-name">
                                                    <p>{inquiry.id} - {inquiry.tutor_user.name} </p>
                                                </div>
                                                {!isEmpty(currentMessages) &&
                                                    <div className="last-message">
                                                        <p>{unescape(currentMessages[currentMessages.length - 1].text_content)}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="chat-message">
                            {activeInquiry &&
                                <>
                                    <div className="single-chat-info">
                                        <div className="profile-image">
                                            <img src={"/assets/images/default-image/profile.png"} />
                                        </div>
                                        <div className="display-name">
                                            <p>{activeInquiry.tutor_user.name}</p>
                                        </div>
                                        <div className="upload-file-button">
                                            {<Icon.Paperclip onClick={this.handleClickUpload} />}
                                            <input id="file-chat" type="file" name="file-chat" style={{ display: "none" }} onChange={this.handleUpload} />
                                        </div>
                                    </div>
                                    <div id="timer-container" style={{ display: activeInquiry.status_id === 2083370574 && !isOutdated ? "flex" : "none", width: "100%", height: "40px", background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)", boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.08)", color: "white", alignItems: "center", justifyContent: "center" }}>
                                        <CircularProgress id="loading-time" size={20} style={{ color: "white" }} />
                                        <span id={"timer"} style={{ display: "flex" }}> </span>
                                    </div>
                                    <div className="overlay" style={{ height: file_result ? "calc(100% - 75px)" : "0%", padding: 0, transition: "0.3s all", bottom: 0, top: "unset" }}>
                                        <div className="dialog-price dialog-preview-file" style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", borderRadius: 0 }}>
                                            <div className="dialog-title" style={{ flex: "10%", display: "flex", alignItems: "center" }}>
                                                <Icon.X style={{ margin: "10px", color: "white", cursor: "pointer" }} onClick={() => this.setState({ file: null, file_result: null })} />
                                                <h6>Preview</h6>
                                            </div>
                                            <div className="dialog-content" style={{ flex: "80%", maxHeight: "calc(80% - 30px)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                {file && file.type.includes("image") ?
                                                    <>
                                                        <img src={file_result} style={{ maxHeight: "calc(80% - 30px)", flex: "0 0 90%", marginBottom: 20 }} />
                                                        <textarea
                                                            onChange={this.handleChangeMessage}
                                                            placeholder="Tambahkan keterangan"
                                                            className="input-field"
                                                            disabled={activeInquiry.status_id !== 2083370574}
                                                        >
                                                            {message}
                                                        </textarea>
                                                    </>
                                                    : file && file.type.includes("video") ?
                                                        <>
                                                            <video controls style={{ flex: "0 0 90%", maxHeight: "calc(80% - 30px)", marginBottom: 20 }} disablePictureInPicture controlsList="nodownload">
                                                                <source src={file_result} type="video/mp4" />
                                                                <source src={file_result} type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            <textarea
                                                                onChange={this.handleChangeMessage}
                                                                placeholder="Tambahkan keterangan"
                                                                className="input-field"
                                                                disabled={activeInquiry.status_id !== 2083370574}
                                                            >
                                                                {message}
                                                            </textarea>
                                                        </>
                                                        : file &&
                                                        <Grid container alignItems="center" justify="center" direction="column">
                                                            <Icon.File style={{ marginBottom: 10, height: 30, width: 30 }} />
                                                            <p>{file.name}</p>
                                                        </Grid>
                                                }
                                            </div>
                                            <div className="dialog-action-button" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", background: "#e3e3e3", flex: "10%" }}>
                                                <div className="send-button">
                                                    <Icon.Send
                                                        onClick={this.handleSendMessage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(openThankyou || openImprove || openSuccessReport) &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content", }}>
                                                <div className="dialog-title" style={{ minHeight: 26.4 }}>
                                                </div>
                                                <div className="dialog-content" style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
                                                    {openThankyou &&
                                                        <>
                                                            <p>Terima kasih atas penilaian anda. </p>
                                                            <div className="dialog-footer">
                                                                <button className="btn btn-primary" onClick={this.handleCloseThankyou}>Ok</button>
                                                            </div>
                                                        </>
                                                    }
                                                    {openImprove &&
                                                        <>
                                                            <p>Terima kasih atas masukan anda. Kami akan meningkatkan kualitas kami kedepannya.</p>
                                                            <div className="dialog-footer">
                                                                <button className="btn btn-primary" onClick={this.handleCloseImprove}>Ok</button>
                                                            </div>
                                                        </>
                                                    }
                                                    {openSuccessReport &&
                                                        <>
                                                            <p>Kami telah menerima laporan Anda. Kami akan menghubungi Anda kembali secepatnya.</p>
                                                            <div className="dialog-footer">
                                                                <button className="btn btn-primary" onClick={this.handleCloseReport}>Ok</button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!openThankyou && !openImprove && !openSuccessReport && showRateUs && this.state.showRateUs &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content", }}>
                                                <div className="dialog-title" style={{ minHeight: 26.4 }}>
                                                    <h6>{!openImprove && !openThankyou ? "Berikan penilaian pelayanan kami" : ""}</h6>
                                                </div>
                                                <div className="dialog-content" style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
                                                    {!openImprove && !openThankyou &&
                                                        <>
                                                            {!showReport &&
                                                                <p>Apakah hasil dari pekerjaan Get Tutor memuaskan?</p>
                                                            }
                                                            {showReport &&
                                                                <div className="form-group">
                                                                    <textarea
                                                                        className="form-control"
                                                                        placeholder="Input your comment"
                                                                        rows="6"
                                                                        style={{ height: "100%" }}
                                                                        value={report}
                                                                        onChange={this.handleChangeReportMessage}
                                                                        maxLength="140"
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            }
                                                            <div className="dialog-footer">
                                                                {!showReport &&
                                                                    <>
                                                                        <button className="btn btn-danger" style={{ display: "flex", alignItems: "center", padding: "10px 40px" }} onClick={this.handleThumbsUp(activeInquiry)}><Icon.ThumbsUp /></button>
                                                                        <div style={{ padding: 4 }}></div>
                                                                        <button className="btn btn-danger" style={{ display: "flex", alignItems: "center", padding: "10px 40px" }} onClick={this.handleThumbsDown(activeInquiry)}><Icon.ThumbsDown /></button>
                                                                        <div style={{ padding: 4 }}></div>
                                                                        <button className="btn btn-primary" style={{ padding: "13px 27px" }} onClick={this.handleReport}>Report</button>
                                                                    </>
                                                                }
                                                                {showReport &&
                                                                    <button className="btn btn-primary" onClick={this.handleSendReport(activeInquiry.id, report)}>Kirim</button>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(
                                        activeInquiry.status_id === 2096469076 ||
                                        activeInquiry.status_id === 2061809634 ||
                                        activeInquiry.status_id === 2079997237 ||
                                        activeInquiry.status_id === 2076844205 ||
                                        activeInquiry.status_id === 2059677011 ||
                                        activeInquiry.status_id === 2005449352
                                    ) &&
                                        <>
                                            {!openConfirmationDialog &&
                                                <div className="overlay">
                                                    <div className="dialog-price" style={{ height: "min-content", }}>
                                                        <div className="dialog-title">
                                                            <h6>{activeInquiry.status_id === 2096469076 ? "Harga pekerjaan" : "Harga pekerjaan baru"}</h6>
                                                        </div>
                                                        <div className="dialog-content" style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
                                                            <p style={{ fontSize: 18 }}>{Util.currencyFormat(activeInquiry.bid_price)}</p>
                                                            <div className="dialog-footer">
                                                                <button className="btn btn-primary" style={{ padding: "10px 20px" }} onClick={this.handlePay}>BAYAR</button>
                                                                <div style={{ padding: 4 }}></div>
                                                                <button className="btn btn-danger" style={{ padding: "10px 20px" }} onClick={this.handleOpenConfirmationBox}>BATALKAN</button>
                                                                {activeInquiry.status_id !== 2005449352 &&
                                                                    <>
                                                                        <div style={{ padding: 4 }}></div>
                                                                        <button className="btn btn-light" style={{ padding: "10px 20px" }} onClick={this.handleNegotiate}>NEGOSIASI</button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {openConfirmationDialog &&
                                                <div className="overlay">
                                                    <div className="dialog-price" style={{ height: "min-content", }}>
                                                        <div className="dialog-title">
                                                            <h6>Konfirmasi</h6>
                                                        </div>
                                                        <div className="dialog-content" style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
                                                            <p>Anda yakin ingin membatalkan pertanyaan ini?</p>
                                                            <div className="dialog-footer">
                                                                <button className="btn btn-primary" style={{ padding: "10px 20px" }} onClick={this.handleDecline}>YA</button>
                                                                <div style={{ padding: 4 }}></div>
                                                                <button className="btn btn-danger" style={{ padding: "10px 20px" }} onClick={() => this.setState({ openConfirmationDialog: false })}>TIDAK</button>
                                                                <div style={{ padding: 4 }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    {(
                                        activeInquiry.status_id === 2038862711 ||
                                        activeInquiry.status_id === 2063870995 ||
                                        activeInquiry.status_id === 2034577039 ||
                                        activeInquiry.status_id === 2077351391 ||
                                        activeInquiry.status_id === 2044457826 ||
                                        activeInquiry.status_id === 2098716420
                                    ) &&
                                        <div className="overlay">
                                            <div className="dialog-price" style={{ height: "min-content" }}>
                                                <div className="dialog-title">
                                                    <h6>Mohon tunggu sebentar</h6>
                                                </div>
                                                <div className="dialog-content">
                                                    <p>{activeInquiry.status_id === 2038862711 ? "Menunggu penawaran" : "Menunggu penawaran baru"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div id="main-message" className="message-info">
                                        {(activeInquiry.status_id === 2083370574 || activeInquiry.status_id === 2008383465) &&
                                            messages.filter(item => item.inquiry === activeInquiry.id).sort(function (a, b) {
                                                return new Date(moment(a.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) - new Date(moment(b.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                            }).map(message =>
                                                <React.Fragment key={message.id}>
                                                    {!isEmpty(message.file_content) &&
                                                        <div key={message.id} className={`single-message-container ${message.customer_user === auth.id ? "message-out" : "message-in"}`}>
                                                            <div className="single-message">
                                                                <div className="message" style={{ padding: 5 }}>
                                                                    {!isEmpty(message.file_content) && message.file_content.type.includes("image") ?
                                                                        <div className="image-container">
                                                                            <a className="image" href={message.file_content.url2} target="_blank" download style={{ padding: 0, widht: 300, height: "100%" }}>
                                                                                <img src={message.file_content.url1} style={{ maxHeight: 300, borderRadius: 12 }} />
                                                                            </a>
                                                                        </div>
                                                                        : !isEmpty(message.file_content) && message.file_content.type.includes("video") ?
                                                                            <div className="image-container">
                                                                                <a className="image" href={message.file_content.url2} target="_blank" download style={{ padding: 0, widht: 300, height: "100%" }}>
                                                                                    <video controls style={{ maxWidth: "100%" }} disablePictureInPicture controlsList="nodownload">
                                                                                        <source src={message.file_content.url1} type="video/mp4" />
                                                                                        <source src={message.file_content.url1} type="video/ogg" />
                                                                                        Your browser does not support the video tag.
                                                                                    </video>
                                                                                </a>
                                                                            </div>
                                                                            : !isEmpty(message.file_content) &&
                                                                            <div className="file-container">
                                                                                <a className="file" href={message.file_content.url2} target="_blank" download>
                                                                                    <Icon.File style={{ height: 30, width: 30, color: "#000" }} />
                                                                                </a>
                                                                                <p>{message.file_content.name}</p>
                                                                            </div>}
                                                                </div>
                                                                <div className="time-date">
                                                                    <span>{moment(message.modified_at).utc().tz(`Asia/Jakarta`).format("HH:mm")} {moment().isSame(moment(message.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'), "day") ? "Hari ini" : moment().utc().tz(`Asia/Jakarta`).format("dddd")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!isEmpty(message.text_content) &&
                                                        <div className={`single-message-container ${message.customer_user === auth.id ? "message-out" : "message-in"}`}>
                                                            <div className="single-message">
                                                                <div className="message">
                                                                    <p>{unescape(message.text_content)}</p>
                                                                </div>
                                                                <div className="time-date">
                                                                    <span>{moment(message.modified_at).utc().tz(`Asia/Jakarta`).format("HH:mm")} {moment().isSame(moment(message.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'), "day") ? "Hari ini" : moment().utc().tz(`Asia/Jakarta`).format("dddd")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                    </div>

                                    <form onSubmit={this.handleSendMessage}>
                                        <div className="message-input">
                                            <input
                                                type="text"
                                                value={message}
                                                onChange={this.handleChangeMessage}
                                                placeholder="Ketik pesan"
                                                className="input-field"
                                                disabled={activeInquiry.status_id !== 2083370574 || (activeInquiry.status_id === 2083370574 && isOutdated)}
                                                autoFocus
                                            />
                                            <div className="send-button">
                                                {<Icon.Send onClick={this.handleSendMessage} />}
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }
                            {!activeInquiry &&
                                <div style={{ flex: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div className="empty-state">
                                        <div className="icon-container">
                                            <NoMessage />
                                        </div>
                                        <p style={{ fontSize: 20 }}>
                                            Percakapan tidak tersedia
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>

                <PaymentTutorSuccess open={openSuccessPayment} handleClose={this.handleCloseSuccessPayment} />
                <SuccessDeclineTutor open={openSuccessDeclineTutorDialog} handleClose={this.handleCloseSuccessDecline} />
                <TopUpBalance open={openTopUpBalance} handleClose={this.handleCloseTopUpBalance} handleTopUpBalance={this.handleSetBalance} />
                <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
            </div >
        )
    }
}

export default Chat