import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';

class Refund extends React.Component {
    render() {
        const { open, handleClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Permintaan Anda dalam proses</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <h6>Permintaan Anda akan diproses dalam 1x24jam.</h6>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-primary" onClick={handleClose}>OK</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default Refund;