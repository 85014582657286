import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import isEmpty from '../../../validation';
import store from '../../../store';
import Axios from 'axios';

class ResetPassword extends React.Component {
    state = {
        email: "",
        emailInvalid: false,
    }


    handleChange = event => {
        this.setState({
            email: event.target.value,
            emailInvalid: isEmpty(event.target.value) || !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value))
        })
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose()
        }

        this.setState({
            emailInvalid: false,
            email: "",
        })
    }

    handleSendEmail = e => {
        e.preventDefault();

        const { email } = this.state;

        if (!isEmpty(email) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {

            store.dispatch({
                type: "SET_LOADING",
                payload: true
            });

            var formData = new FormData();
            formData.append("UBgQyg", email)
            // formData.append("PtaXcs", "http://dev.gettutor.id.atmos.id/reset/password/")
            formData.append("PtaXcs", "http://gettutor.id/reset/password/")
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/mgq86RUnsXtY3UJM",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "gTKFsJE3YdYVmj2S",
                        password: "KnXkKL2ntZBwvc3t"
                    }
                }
            ).then(respond => {
                if (this.props.onSendEmail) {
                    this.props.onSendEmail(email)
                    this.handleClose()
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }

        this.setState({
            emailInvalid: isEmpty(email) || !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)),
        })
    }

    render() {
        const { open, handleClose } = this.props;
        const { email, emailInvalid } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff" }}>Reset Sandi</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: "20px 0px", textAlign: "center" }}>
                        <form onSubmit={this.handleSendEmail}>
                            <div className="form-group">
                                <label>Masukkan email yang pernah didaftarkan </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                    className={`form-control ${emailInvalid ? "is-invalid" : ""}`}
                                    placeholder="Masukan Email"
                                    autoFocus
                                />
                                <div className="invalid-feedback">
                                    Cantumkan alamat email yang valid .
                                </div>
                            </div>
                        </form>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-primary" onClick={this.handleSendEmail}>Kirim</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default ResetPassword;