import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import Util from '../../../util';
import isEmpty from '../../../validation';
import { connect } from "react-redux";
import * as Icon from "react-feather";
import store from '../../../store';
import Axios from 'axios';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import AuthActions from '../../../actions/AuthActions';
import setAuthToken from '../../../validation/setAuthToken';

class PaymentSummary extends React.Component {
    state = {
        balanceInsufficient: false
    }

    handlePay = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        if (this.props.handleRemoveSuccessTopUpBalance) {
            this.props.handleRemoveSuccessTopUpBalance()
        }

        var formData = new FormData();
        formData.append("ALJdcU", this.props.auth.id);
        formData.append("GNHtSA", this.props.activeData.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/ypLCqdcTyz8a3Wjd",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VkCCcPbydWaT2eRc",
                    password: "QT822kMbBGHp7aWv"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "SRejjU" && respond.data !== "EXRteA" && respond.data !== "hYLjCp" && respond.data !== "buDFqH") {

                    const payload = {
                        ...this.props.auth,
                        balance: this.props.auth.balance - this.props.activeData.price,
                    }; // Create JWT Payload

                    const oldDecoded = jwt_decode(localStorage.jwtToken);

                    const token = jwt.sign(
                        payload,
                        "SECRET",
                        {
                            ...oldDecoded.exp
                        }
                    );

                    localStorage.setItem("jwtToken", token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);

                    store.dispatch(AuthActions.setCurrentUser(decoded));

                    // Set token to Auth header
                    setAuthToken(token);

                    store.dispatch({
                        type: "UPDATE_PROFILE",
                        payload
                    })

                    store.dispatch({
                        type: "UPDATE_PURCHASED_FILE",
                        payload: {
                            isPurchased: true
                        }
                    })

                    if (this.props.handlePay) {
                        this.props.handlePay();
                    }
                }

                this.setState({
                    balanceInsufficient: respond.data === "hYLjCp"
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleTopUpBalance = () => {
        this.setState({
            balanceInsufficient: false
        })

        if (this.props.handleConfirm) {
            this.props.handleConfirm();
        }
    }

    render() {
        const { open, handleClose, topUpBalanceSuccess, handleCloseTopUpAlert } = this.props;
        const { balanceInsufficient } = this.state;

        const activeData = !isEmpty(this.props.activeData) ? this.props.activeData : {}

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Konfirmasi Pembayaran</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: "20px 0px" }}>
                        {topUpBalanceSuccess &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <Icon.Info /><div style={{ width: 10 }}> </div>Berhasil top up saldo.
                                <button className="close" onClick={handleCloseTopUpAlert}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        {balanceInsufficient &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Saldo tidak cukup.
                                <button className="close" onClick={handleCloseTopUpAlert}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div >
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={8}>
                                    <div className="work-info">
                                        <div className="info-list subject">
                                            <Grid container spacing={1}>
                                                <Grid item xs={5}>
                                                    <p>Nama File</p>
                                                </Grid>
                                                <Grid item xs={1} container justify="flex-end">
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <span>{unescape(activeData.name)}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="info-list question">
                                            <Grid container spacing={1}>
                                                <Grid item xs={5}>
                                                    <p>Oleh</p>
                                                </Grid>
                                                <Grid item xs={1} container justify="flex-end">
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <span>{unescape(activeData.author)}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="time-rate">
                                        <div className="info-list subject">
                                            <Grid container spacing={1}>
                                                <Grid item xs={5}>
                                                    <p>Total Harga</p>
                                                </Grid>
                                                <Grid item xs={1} container justify="flex-end">
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <span>Rp. {Util.numberWithCommas(activeData.price)}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-danger" onClick={this.handleTopUpBalance}>Top Up Saldo</button>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button className="btn btn-light" onClick={handleClose}>Batalkan</button>
                        <div style={{ padding: 4 }}></div>
                        <button className="btn btn-primary" onClick={this.handlePay}>Bayar</button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        activeData: state.resourceReducer.activeData,
        auth: state.masterData.auth
    }
}

export default connect(mapStateToProps)(PaymentSummary);