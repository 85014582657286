import React from 'react';
import { Grid, IconButton, Chip } from '@material-ui/core';
import * as Icon from "react-feather";
import CreateNewClass from '../../Dialog/CreateNewClass';
import SuccessCreateNewClass from '../../Dialog/SuccessCreateNewClass';
import { DropzoneArea } from 'material-ui-dropzone';
import CreateNewFolder from '../../Dialog/CreateNewFolder';
import history from '../../../../history';
import InputTimeLive from '../../Dialog/InputTimeLive';
import UploadFile from '../../Dialog/UploadFile';
import isEmpty from '../../../../validation';
import NoData from '../../../../icons/no-data';
import { v1 as uuidv1 } from 'uuid';
import FilePreview from 'react-preview-file';
import Select from '../../../../reusable-components/Inputs/Select';
import Axios from 'axios';
import store from '../../../../store';
import moment from 'moment';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import swal from 'sweetalert';

class Class extends React.Component {
    state = {
        openConfirmationDialogClass: false,
        openConfirmationDialogFolder: false,
        openConfirmationDialogFile: false,
        openClass: false,
        openSuccessCreateClass: false,

        searchValue: "",
        showClass: false,
        showDraft: false,
        showFolder: false,
        openCreateFolder: false,
        openInputTimeLive: false,
        openUploadFile: false,
        currentFilter: "ready",
        files: [],
        classes: [],
        activeClass: null,
        activeFolder: null,
        activeFile: null,

        selectedClasses: [],
        selectedFolders: [],
        selectedFiles: [],
        isEditModeFolder: false
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        this.getNewClass();
    }

    getNewClass = () => {
        const { auth } = this.props;
        let classes = []
        if (auth) {
            var formData = new FormData();
            formData.append("KyerbD", 99999);
            formData.append("pJppPs", 0);
            formData.append("ERgtaC", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ge6VmLzuykB6CCPv",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "MYBwde3K92ACp8vK",
                        password: "t8uUuZ3wc92qEGZh"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "PQyyXb") {
                    if (respond.data !== "ERR") {
                        respond.data.forEach(datum => {
                            classes.push(datum)
                        })
                    } else {
                        swal("Error!", "Class not found", "error", {
                            timer: 3000,
                        })
                    }

                    this.setState({
                        classes
                    })
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    handleCreateNewClass = e => {
        this.setState({ openClass: true })
    }

    handleCloseCreateNewClass = () => {
        this.setState({ openClass: !this.state.openClass })
    }

    handleConfirmCreateNewClass = (name, description, subject) => {
        this.getNewClass();

        this.setState({
            openClass: false,
            currentFilter: 2036163938,
        })
    }

    handleConfirmInputTimeLive = date => {
        this.handleSubmitClass(date);

        this.setState({
            openInputTimeLive: false,
            openSuccessCreateClass: true,
            showClass: false,
            showDraft: false,
            activeFile: null,
            activeFolder: null,
            activeClass: null,
            currentFilter: "ready"
        })
    }

    toggleInputTimeLive = () => {
        const { activeClass } = this.state;

        if (activeClass.folders.length !== 0 && activeClass.folders.some(item => item.files.length > 0)) {
            this.setState({
                openInputTimeLive: !this.state.openInputTimeLive
            })
        } else {
            swal("Error!", "File dibutuhkan", "error", {
                timer: 3000,
            })
        }
    }

    toggleSuccessClass = () => {
        this.setState({ openSuccessCreateClass: !this.state.openSuccessCreateClass })
    }

    handleOpenClass = singleClass => () => {
        const { classes } = this.state;

        if (singleClass.status === 2015158996 || singleClass.status === 2089985133 || singleClass.status === 2097058443) {
            store.dispatch({
                type: "SET_ACTIVE_CLASS",
                payload: {
                    ...singleClass,
                    isAvailableForLive: classes.every(item => item.status !== 2015158996)
                }
            })

            history.push("/tutor-dashboard/online-class")
        } else if (singleClass.status === 2036163938 || singleClass.status === 2072139579) {
            this.setState({
                showClass: true,
                showDraft: singleClass.status === 2036163938,
                activeClass: singleClass
            })
        }
    }

    handleClickClass = singleClass => () => {
        let selectedClasses = [
            ...this.state.selectedClasses
        ]

        if (selectedClasses.some(item => item === singleClass.id)) {
            selectedClasses = selectedClasses.filter(item => item !== singleClass.id)
        } else {
            selectedClasses.push(singleClass.id)
        }

        this.setState({
            selectedClasses
        })
    }

    handleBack = () => {
        this.setState({
            showClass: false,
            showDraft: false,
            isEditModeFolder: false,
            selectedClasses: [],
            selectedFolders: [],
            selectedFiles: []
        })
    }

    handleBackFolder = () => {
        this.setState({
            showFolder: false,
            isEditModeFolder: false,
            selectedClasses: [],
            selectedFolders: [],
            selectedFiles: []
        })
    }

    handleChangeFilter = value => {
        this.setState({
            currentFilter: value,
            showClass: false,
            showDraft: false
        })
    }

    handleEditMode = () => {
        this.setState({
            isEditModeFolder: !this.state.isEditModeFolder,
            selectedClasses: [],
            selectedFolders: [],
            selectedFiles: []
        })
    }

    handleClickFolder = folder => () => {
        let selectedFolders = [
            ...this.state.selectedFolders
        ]

        if (selectedFolders.some(item => item === folder.id)) {
            selectedFolders = selectedFolders.filter(item => item !== folder.id)
        } else {
            selectedFolders.push(folder.id)
        }

        this.setState({
            selectedFolders
        })
    }

    handleOpenFolder = folder => () => {
        this.setState({
            showFolder: true,
            activeFolder: folder
        })
    }

    handleOpenCreateFolder = () => {
        this.setState({
            openCreateFolder: true,
        })
    }

    handleCreateNewFolder = (id, name) => {
        const { activeClass } = this.state;
        const classes = [...this.state.classes];

        classes.filter(item => item.id === activeClass.id).forEach(datum => {
            datum.folders.push({
                id,
                name,
                files: [],
                modified_at: moment()
            })
        })

        this.setState({
            openCreateFolder: false,
            classes
        })
    }

    handleCloseFolder = () => {
        this.setState({
            openCreateFolder: false,
        })
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = () => {
        this.setState({
            files: []
        })
    }

    handleOpenUploadFile = () => {
        this.setState({
            openUploadFile: true,
        })
    }

    handleCloseUploadFile = () => {
        this.setState({
            openUploadFile: false,
            activeFile: null
        })
    }

    handleOpenFile = file => () => {
        this.setState({
            openUploadFile: true,
            activeFile: file
        })
    }

    handleClickFile = file => () => {
        let selectedFiles = [
            ...this.state.selectedFiles
        ]

        if (selectedFiles.some(item => item === file.id)) {
            selectedFiles = selectedFiles.filter(item => item !== file.id)
        } else {
            selectedFiles.push(file.id)
        }

        this.setState({
            selectedFiles
        })
    }

    handleSaveUploadFile = (id, description, files) => {
        const { activeClass, activeFolder } = this.state;
        const classes = [...this.state.classes];

        classes.filter(item => item.id === activeClass.id).forEach(datum => {
            datum.folders.filter(item => item.id === activeFolder.id).forEach(folder => {
                files.forEach(file => {
                    folder.files.push(({
                        id,
                        description,
                        name: file.name,
                        file,
                        modified_at: moment()
                    }))
                })
            })
        })


        this.setState({
            openUploadFile: false,
            classes
        })
    }

    handleChangeSearch = e => {
        this.setState({
            searchValue: e.target.value
        })
    }

    handleDeleteClass = () => {
        const { selectedClasses } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("pQStTj", selectedClasses);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/nNWx8jLd5LVNVs68",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "yyFtKqRUR7Q6V73s",
                    password: "JB26DEvMxMbXdYKE"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "kHwKHP") {
                    this.getNewClass();

                    this.setState({
                        openConfirmationDialogClass: false,
                        showClass: false,
                        showDraft: false,
                        activeFile: null,
                        activeFolder: null,
                        activeClass: null,
                        selectedClasses: [],
                        classes: this.state.classes.filter(item => selectedClasses.every(singleClass => singleClass !== item.id))
                    })
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            }
        })
    }

    handleDeleteFolder = () => {
        const { selectedFolders, activeClass } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("EDreWw", selectedFolders);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/eYjV9PT5QyeN2tJF",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "fw5HC6sP4TnVZRH3",
                    password: "YSL9KpyYkmFZWS8S"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "gwAujf") {
                    this.getNewClass();

                    this.setState({
                        openConfirmationDialogFolder: false,
                        activeFolder: null,
                        selectedClasses: [],
                        selectedFolders: [],
                        classes: this.state.classes.map(singleClass => ({
                            ...singleClass,
                            folders: singleClass.folders.filter(folder => selectedFolders.every(selectedFolder => selectedFolder !== folder.id))
                        })),
                        activeClass: {
                            ...activeClass,
                            folders: activeClass.folders.filter(folder => selectedFolders.every(selectedFolder => selectedFolder !== folder.id))
                        }
                    })
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            }
        })
    }

    handleDeleteFile = () => {
        const { selectedFiles, activeClass, activeFolder } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("yDMhBs", selectedFiles);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Uw2xPK5t7g9Cz6xd",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Bxe4GHwKtyLXnMVH",
                    password: "ZNgnQRbxA8FdZgu9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "ZFDfqW") {
                    this.getNewClass();

                    this.setState({
                        openConfirmationDialogFile: false,
                        selectedClasses: [],
                        selectedFolders: [],
                        selectedFiless: [],
                        classes: this.state.classes.map(singleClass => ({
                            ...singleClass,
                            folders: singleClass.folders.map(folder => ({
                                ...folder,
                                files: folder.files.filter(file => selectedFiles.every(selectedFiles => selectedFiles !== file.id))
                            }))
                        })),
                        activeClass: {
                            ...activeClass,
                            activeClass: {
                                ...activeClass,
                                folders: activeClass.folders.map(folder => ({
                                    ...folder,
                                    files: folder.files.filter(file => selectedFiles.every(selectedFiles => selectedFiles !== file.id))
                                }))
                            }
                        },
                        activeFolder: {
                            ...activeFolder,
                            files: activeFolder.files.filter(file => selectedFiles.every(selectedFiles => selectedFiles !== file.id))
                        }
                    })
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            }
        })
    }

    handleSubmitClass = date => {
        const { activeClass } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("ePQhtb", activeClass.id);
        if (date) {
            formData.append("CamBwc", moment(date).format("YYYY-MM-DD HH:mm:ss"));
        }

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/NAkdLwVv6DGSxU84",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "vLKHgvbVp3UWUqst",
                    password: "vYAx7ULMbdm2gkBz"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "UQsUXT") {
                    this.getNewClass();
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            }
        })
    }

    handleDirectlySubmitClass = () => {
        const { activeClass } = this.state;

        if (activeClass.folders.length !== 0 && activeClass.folders.some(item => item.files.length > 0)) {
            this.handleSubmitClass(null)

            this.setState({
                openInputTimeLive: false,
                openSuccessCreateClass: true,
                showClass: false,
                showDraft: false,
                activeFile: null,
                activeFolder: null,
                activeClass: null,
                currentFilter: "ready"
            })
        } else {
            swal("Error!", "File dibutuhkan", "error", {
                timer: 3000,
            })
        }
    }

    toggleConfirmationDialog = openState => () => {
        const { openConfirmationDialogClass, openConfirmationDialogFolder, openConfirmationDialogFile } = this.state;

        this.setState({
            [openState]: openState === "openConfirmationDialogClass" ? !openConfirmationDialogClass : openState === "openConfirmationDialogFolder" ? !openConfirmationDialogFolder : !openConfirmationDialogFile
        })
    }

    render() {
        const { auth } = this.props;
        const { openConfirmationDialogClass, openConfirmationDialogFolder, openConfirmationDialogFile, openClass, openInputTimeLive, openSuccessCreateClass, showClass, showDraft, showFolder, currentFilter, openCreateFolder, openUploadFile, files } = this.state;
        const { classes, searchValue, activeClass, activeFolder, activeFile, selectedClasses, selectedFolders, selectedFiles, isEditModeFolder } = this.state;

        const filters = [
            { value: "ready", label: "Kelas saya" },
            { value: 2072139579, label: "Dalam proses review" },
            { value: 2036163938, label: "Draft" }
        ]

        return (
            <div className="class">
                <div className="content container ptb-80">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={0} justify="flex-end">
                                <button className="btn btn-primary" onClick={this.handleCreateNewClass}>Buat kelas baru</button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {!showClass && !showDraft &&
                                <div className="class-list">
                                    <div className="toolbar">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={4} md={4}>
                                                <div className="search-class">
                                                    <input
                                                        type="text"
                                                        value={searchValue}
                                                        onChange={this.handleChangeSearch}
                                                        name="search"
                                                        placeholder="Cari Kelas"
                                                        className="form-control"
                                                        style={{
                                                            height: 35
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={8} md={8}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={5} sm={6} lg={6} md={6} container spacing={0} alignItems="center" justify="flex-end">
                                                        {!isEditModeFolder && currentFilter === 2036163938 &&
                                                            <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.Edit style={{ height: 15, width: 15 }} />}</button>
                                                        }
                                                        {isEditModeFolder &&
                                                            <>
                                                                {selectedClasses.length > 0 &&
                                                                    <>
                                                                        <button className="btn btn-danger" style={{ display: "flex", padding: 8 }} onClick={this.toggleConfirmationDialog("openConfirmationDialogClass")}>{<Icon.Trash style={{ height: 15, width: 15 }} />}</button>
                                                                        <div style={{ padding: 4 }}></div>
                                                                    </>
                                                                }
                                                                <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.X style={{ height: 15, width: 15 }} />}</button>
                                                            </>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={7} sm={6} lg={6} md={6}>
                                                        <Select
                                                            options={filters}
                                                            placeholder=""
                                                            noOptionsMessage={() => {
                                                                return 'Tidak ada pilihan'
                                                            }}
                                                            value={currentFilter}
                                                            onChange={this.handleChangeFilter}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {classes.filter(item => String(item.name).toLocaleLowerCase().includes(String(searchValue).toLocaleLowerCase())).filter(item => currentFilter === "ready" ? item.status !== 2092456741 && item.status !== 2072139579 && item.status !== 2036163938 : item.status === currentFilter).length === 0 &&
                                        <div className="empty-state" style={{ height: "calc(100vh - 454px)", minHeight: 300 }}>
                                            <div className="icon-container">
                                                <NoData />
                                            </div>
                                            <p style={{ fontSize: 20 }}>
                                                Kelas tidak ditemukan
                                            </p>
                                        </div>
                                    }
                                    {classes
                                        .filter(item => String(item.name).toLocaleLowerCase().includes(String(searchValue).toLocaleLowerCase()))
                                        .filter(item => currentFilter === "ready" ? item.status !== 2072139579 && item.status !== 2036163938 : item.status === currentFilter)
                                        .length > 0 &&
                                        <div className="row" style={{ height: "calc(100vh - 454px)", minHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
                                            {classes
                                                .filter(item => currentFilter === "ready" ? item.status !== 2092456741 && item.status !== 2072139579 && item.status !== 2036163938 : item.status === currentFilter).
                                                sort((a, b) => a.status === 2072139579 ? 1 : -1)
                                                .map((singleClass, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6">
                                                        <div className="single-class" onClick={isEditModeFolder ? this.handleClickClass(singleClass) : this.handleOpenClass(singleClass)}>
                                                            <div className="title">
                                                                <h3> {singleClass.name} </h3>
                                                                {singleClass.status === 2072139579 && <span style={{ flex: "30%", color: "#A5BDCA", fontSize: 12, margin: 0, textAlign: "right" }}>Dalam proses review</span>}
                                                                {singleClass.status === 2015158996 && <span style={{ flex: "30%", color: "#44ce6f", fontSize: 14, textAlign: "right" }}>LIVE</span>}

                                                                {isEditModeFolder &&
                                                                    <span>
                                                                        {selectedClasses.some(item => item === singleClass.id) ?
                                                                            <Icon.CheckCircle style={{ height: 16, width: 16 }} /> :
                                                                            <Icon.Circle style={{ height: 16, width: 16 }} />
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                            <p>{unescape(singleClass.description)}</p>
                                                            <div className="subject">
                                                                <span>Subject</span>
                                                                <p>{singleClass.subject.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    }
                                </div>
                            }
                            {showClass &&
                                <div className="single-draft-class">
                                    <div className="toolbar">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={2} md={1}>
                                                <Grid container alignItems="center" style={{ height: "100%" }}>
                                                    <div className="back-button">
                                                        <Icon.ArrowLeft onClick={!showFolder ? this.handleBack : this.handleBackFolder} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={10} md={3}>
                                                <Grid container alignItems="center" className="title">
                                                    <h6>{activeClass.name}</h6>{showFolder && <> <span> > </span><h6>{activeFolder.name}</h6></>}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Grid container alignItems="center" justify="flex-end">
                                                    {!showFolder && activeClass.status === 2036163938 &&
                                                        <>
                                                            {!isEditModeFolder && activeClass.folders.filter(item => !item.is_deleted).length > 0 &&
                                                                <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.Edit style={{ height: 15, width: 15 }} />}</button>
                                                            }
                                                            {isEditModeFolder &&
                                                                <>
                                                                    {selectedFolders.length > 0 &&
                                                                        <>
                                                                            <button className="btn btn-danger" style={{ display: "flex", padding: 8 }} onClick={this.toggleConfirmationDialog("openConfirmationDialogFolder")}>{<Icon.Trash style={{ height: 15, width: 15 }} />}</button>
                                                                            <div style={{ padding: 4 }}></div>
                                                                        </>
                                                                    }
                                                                    <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.X style={{ height: 15, width: 15 }} />}</button>
                                                                </>
                                                            }
                                                            <div style={{ padding: 8 }}></div>
                                                            <button className="btn btn-light" onClick={this.handleOpenCreateFolder}>Buat Folder Baru</button>
                                                        </>
                                                    }
                                                    {showFolder && activeClass.status === 2036163938 && activeFolder.files.length <= 500 &&
                                                        <>
                                                            {!isEditModeFolder &&
                                                                <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.Edit style={{ height: 15, width: 15 }} />}</button>
                                                            }
                                                            {isEditModeFolder &&
                                                                <>
                                                                    {selectedFiles.length > 0 &&
                                                                        <>
                                                                            <button className="btn btn-danger" style={{ display: "flex", padding: 8 }} onClick={this.toggleConfirmationDialog("openConfirmationDialogFile")}>{<Icon.Trash style={{ height: 15, width: 15 }} />}</button>
                                                                            <div style={{ padding: 4 }}></div>
                                                                        </>
                                                                    }
                                                                    <button className="btn btn-primary" style={{ display: "flex", padding: 8 }} onClick={this.handleEditMode}>{<Icon.X style={{ height: 15, width: 15 }} />}</button>
                                                                </>
                                                            }
                                                            <div style={{ padding: 8 }}></div>
                                                            <button className="btn btn-light" onClick={this.handleOpenUploadFile}>UPLOAD FILE BARU</button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Grid container spacing={1} justify="space-between">
                                        {!showFolder &&
                                            <Grid item xs={12} className="folder-list" style={{ height: showDraft && !showFolder && "calc(100vh - 515px)", minHeight: 400 }}>
                                                {activeClass.folders.filter(item => !item.is_deleted).length > 0 &&
                                                    <Grid container spacing={2}>
                                                        {activeClass.folders.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).filter(item => !item.is_deleted).map((folder, index) => (
                                                            <Grid key={index} item xs={12} md={3}>
                                                                <div className="single-folder" onClick={isEditModeFolder ? this.handleClickFolder(folder) : this.handleOpenFolder(folder)}>
                                                                    {!isEditModeFolder &&
                                                                        <Icon.Folder />
                                                                    }
                                                                    {isEditModeFolder &&
                                                                        <>
                                                                            {selectedFolders.some(item => item === folder.id) ?
                                                                                <Icon.CheckCircle /> :
                                                                                <Icon.Circle />
                                                                            }
                                                                        </>
                                                                    }
                                                                    <h6>
                                                                        {folder.name}
                                                                    </h6>
                                                                </div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                }
                                                {activeClass.folders.filter(item => !item.is_deleted).length === 0 &&
                                                    <div className="empty-state">
                                                        <div className="icon-container">
                                                            <NoData />
                                                        </div>
                                                        <p style={{ fontSize: 20 }}>
                                                            Folder tidak ditemukan
                                                        </p>
                                                    </div>
                                                }
                                            </Grid>
                                        }
                                        {showFolder &&
                                            <Grid item xs={12} className="folder-list" style={{ height: "calc(100vh - 515px)", minHeight: 400 }}>
                                                {activeFolder.files.filter(item => !item.is_deleted).length > 0 &&
                                                    <Grid container spacing={3}>
                                                        {activeFolder.files.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).filter(item => !item.is_deleted).map((file, index) =>
                                                            <Grid key={index} item xs={12} md={3}>
                                                                <div className="single-draft-file" onClick={isEditModeFolder ? this.handleClickFile(file) : () => { }}>
                                                                    <div className="title">
                                                                        <h3> {file.name} </h3>
                                                                        {isEditModeFolder &&
                                                                            <span>
                                                                                {selectedFiles.some(item => item === file.id) ?
                                                                                    <Icon.CheckCircle style={{ height: 16, width: 16 }} /> :
                                                                                    <Icon.Circle style={{ height: 16, width: 16 }} />
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    <div className="description">
                                                                        <p>{unescape(file.description)}</p>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                }
                                                {activeFolder.files.filter(item => !item.is_deleted).length <= 0 &&
                                                    <div className="empty-state">
                                                        <div className="icon-container">
                                                            <NoData />
                                                        </div>
                                                        <p style={{ fontSize: 20 }}>
                                                            File tidak ditemukan
                                                        </p>
                                                    </div>
                                                }
                                            </Grid>
                                        }
                                        {showDraft && !showFolder && !isEditModeFolder &&
                                            <Grid item xs={12} container alignItems="flex-end" justify="center" >
                                                <button className="btn btn-primary" onClick={this.toggleInputTimeLive}> Kirim Kelas </button>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>

                <CreateNewClass open={openClass} classes={classes.filter(item => item.status !== 2092456741)} handleClose={this.handleCloseCreateNewClass} handleCreateNewClass={this.handleConfirmCreateNewClass} />
                <CreateNewFolder open={openCreateFolder} activeClass={activeClass} handleClose={this.handleCloseFolder} handleCreateNewFolder={this.handleCreateNewFolder} />
                <SuccessCreateNewClass open={openSuccessCreateClass} handleClose={this.toggleSuccessClass} handleConfirm={this.toggleSuccessClass} />
                <UploadFile open={openUploadFile} activeFile={activeFile} activeFolder={activeFolder} handleClose={this.handleCloseUploadFile} handleSave={this.handleSaveUploadFile} />
                <InputTimeLive open={openInputTimeLive} activeClass={activeClass} handleClose={this.toggleInputTimeLive} handleConfirm={this.handleConfirmInputTimeLive} />
                <ConfirmationDialog
                    open={openConfirmationDialogClass}
                    handleClose={this.toggleConfirmationDialog("openConfirmationDialogClass")}
                    handleConfirm={this.handleDeleteClass}
                    title="Are you sure you want to delete this class?"
                    content="This class will be deleted immediately. You can't undo this action."
                    confirmText="Delete"
                    closeText="Batalkan"
                />
                <ConfirmationDialog
                    open={openConfirmationDialogFolder}
                    handleClose={this.toggleConfirmationDialog("openConfirmationDialogFolder")}
                    handleConfirm={this.handleDeleteFolder}
                    title="Are you sure you want to delete this folder?"
                    content="This folder will be deleted immediately. You can't undo this action."
                    confirmText="Delete"
                    closeText="Batalkan"
                />
                <ConfirmationDialog
                    open={openConfirmationDialogFile}
                    handleClose={this.toggleConfirmationDialog("openConfirmationDialogFile")}
                    handleConfirm={this.handleDeleteFile}
                    title="Are you sure you want to delete this file?"
                    content="This file will be deleted immediately. You can't undo this action."
                    confirmText="Delete"
                    closeText="Batalkan"
                />


                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

export default Class