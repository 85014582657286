import React from 'react';
import * as Icon from "react-feather";
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {

        return (
            <footer className="footer-area bg-f7fafd">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="copyright-area h-100" style={{ marginTop: 0, padding: 0, borderTop: "none", textAlign: "left", display: "flex", alignItems: "center" }}>
                                        <p>Copyright @2020 Get Tutor. All rights reserved</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4">
                                    <Grid container alignItems="center" justify="flex-end">
                                        <div className="single-footer-widget">
                                            <ul className="social-links">
                                                <li>
                                                    <a className="nav-link" className="help" href="/help">
                                                        <Icon.HelpCircle />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="facebook">
                                                        <Icon.Facebook />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="twitter"><Icon.Twitter /></a>
                                                </li>
                                                <li>
                                                    <a className="instagram"><Icon.Instagram /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default Footer;