import React from 'react';
import * as Icon from "react-feather";
import Grid from "@material-ui/core/Grid";
import PaymentSummary from '../../Dialog/PaymentSummary';
import Axios from 'axios';
import store from '../../../../store';
import moment from 'moment';
import Util from '../../../../util';
import history from '../../../../history';
import isEmpty from '../../../../validation';
import NoData from '../../../../icons/no-data';
import Select from '../../../../reusable-components/Inputs/Select';
import swal from 'sweetalert';

class RecentFile extends React.Component {
    state = {
        files: [],
        purchasedFiles: [],
        successResendEmail: false,
        searchValue: "",
        currentPage: 1,
        filesPerPage: 10,
        sortBy: "subject"
    }

    componentWillMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("XUQdHG", 10)
        formData.append("zFXUxH", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/kzBC5vUBKkBfVM32",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bTZBZz2fE2SMCNwG",
                    password: "CRPaeqRwCX38jhHv"
                }
            }
        ).then(respond => {
            if (respond.data !== "ERR") {
                this.setState({
                    files: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        var formData1 = new FormData();
        formData1.append("JbpWTc", 10)
        formData1.append("tYRYLr", 0)
        formData1.append("CLNxkE", this.props.auth.id)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Q7uBp7EeYqmwLTaS",
            formData1,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "hCbkyJ7wWfM2U3Aw",
                    password: "pFEsSc4yMxqfvMv6"
                }
            }
        ).then(respond => {
            this.setState({
                purchasedFiles: respond.data
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleDetailFile = file => () => {
        store.dispatch({
            type: "SET_ACTIVE_FILE",
            payload: file
        })

        history.push("/dashboard/detail-file")
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSearch = event => {
        this.setState({
            searchValue: event.target.value
        })
    }

    handleChangePage = number => () => {
        this.setState({
            currentPage: Number(number)
        });
    }

    handleSortBy = value => {
        this.setState({
            sortBy: value
        })
    }

    handleDownload = fileId => () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("wLFBzP", fileId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/aTSpxP27NuTtA3aw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "LepY79np2x38vZzq",
                    password: "VrSDfy2sfbxfzZWq"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "aYQCxh") {
                if (respond.data !== "ERR") {
                    window.location.href = respond.data;
                } else {
                    swal("Error!", "File not found", "error", {
                        timer: 3000,
                    })
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { files, purchasedFiles, successResendEmail, searchValue, currentPage, filesPerPage, sortBy } = this.state;
        const { auth } = this.props;
        const { is_verified } = auth;

        const filteredFiles = files
            .sort(function (a, b) {
                if (sortBy === "subject") {
                    if (a.subject < b.subject) { return -1; }
                    if (a.subject > b.subject) { return 1; }
                } else if (sortBy === "high") {
                    return b.price - a.price
                } else if (sortBy === "low") {
                    return a.price - b.price
                }

                return 0;
            })
            .filter(item => !isEmpty(searchValue) ? unescape(item.name).toLowerCase().includes(String(searchValue).toLowerCase()) : true);

        const indexOfLastFile = currentPage * filesPerPage;
        const indexOfFirstFile = indexOfLastFile - filesPerPage;
        const currentFiles = filteredFiles.slice(indexOfFirstFile, indexOfLastFile);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredFiles.length / filesPerPage); i++) {
            pageNumbers.push(i);
        }

        const optionsSortBy = [
            { value: "subject", label: "Subject" },
            { value: "high", label: "Harga termahal" },
            { value: "low", label: "Harga termurah" },
        ]

        return (
            <div className="recent-file">
                <div className="content ptb-80">
                    <div className="container">
                        {successResendEmail &&
                            <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                    <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                </span>
                            </div>
                        }
                        {!successResendEmail && isEmpty(is_verified) &&
                            <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>
                                    Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                    <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                </span>
                            </div>
                        }

                        <div className="recent-file-topbar">
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={4} md={4} container spacing={0} alignItems="center">
                                    <div className="recent-file-topbar-count">
                                        <p>Memunculkan {currentFiles.length > 0 ? 1 : 0}-{currentFiles.length} dari total {filteredFiles.length} dokumen</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={8} md={8}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} lg={6} md={6}>
                                            <div className="recent-file-topbar-search">
                                                <input
                                                    type="text"
                                                    placeholder="Cari file"
                                                    className="form-control"
                                                    onChange={this.handleSearch}
                                                    style={{
                                                        height: 35
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} md={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3} container alignItems="center" justify="flex-end">
                                                    <p>Urutkan:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Select
                                                        options={optionsSortBy}
                                                        placeholder=""
                                                        noOptionsMessage={() => {
                                                            return 'Tidak ada pilihan'
                                                        }}
                                                        value={sortBy}
                                                        onChange={this.handleSortBy}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="list-file-container">
                            {currentFiles.length === 0 &&
                                <div className="empty-state">
                                    <div className="icon-container">
                                        <NoData />
                                    </div>
                                    <p style={{ fontSize: 20 }}>
                                        File tidak tersedia
                                    </p>
                                </div>
                            }

                            <div className="row">
                                {currentFiles.map((file, idx) => {

                                    const isPurchased = purchasedFiles.filter(item => item.id === file.id).length > 0;

                                    return (
                                        <div className="col-lg-4 col-md-4" key={idx}>
                                            <div className="single-file">
                                                {isPurchased &&
                                                    <div className="purchased-item">Dokumen ini sudah dibeli</div>
                                                }
                                                <div className="file-image">
                                                    <img src={unescape(file.images[0])} alt="image" />

                                                    <ul>
                                                        <li>
                                                            <a
                                                                onClick={this.handleDetailFile({
                                                                    ...file,
                                                                    isPurchased
                                                                })}
                                                            >
                                                                <Icon.Search />
                                                            </a>
                                                        </li>
                                                    </ul>

                                                    <div className="date">
                                                        <Icon.Calendar /> {moment(file.date_uploaded).utc().tz(`Asia/Jakarta`).format("MMM DD, YYYY")}
                                                    </div>
                                                </div>

                                                <div className="file-content">
                                                    <h3>
                                                        <a>{unescape(file.name)}</a>
                                                    </h3>

                                                    <span>Rp. {Util.numberWithCommas(file.price)}</span>

                                                    <span>
                                                        Subject: {unescape(file.subject)}
                                                    </span>
                                                    {!isPurchased &&
                                                        <a
                                                            className="file-btn"
                                                            onClick={this.handleDetailFile({
                                                                ...file,
                                                                isPurchased
                                                            })}
                                                        >
                                                            Beli
                                                        </a>
                                                    }
                                                    {isPurchased &&
                                                        <a className="file-btn" onClick={this.handleDownload(file.id)}>Unduh</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                {currentFiles.length > 0 &&
                                    <div className="col-lg-12 col-md-12">
                                        <div className="pagination-area">
                                            <nav aria-label="Page navigation">
                                                <ul className="pagination justify-content-center">

                                                    <li className="page-item"><a className="page-link" onClick={currentPage - 1 > 0 ? this.handleChangePage(currentPage - 1) : () => { }}>Prev</a></li>

                                                    {pageNumbers.map(number => (
                                                        <li
                                                            key={number}
                                                            id={number}
                                                            className={`page-item ${number === currentPage ? "active" : ""}`}
                                                            onClick={this.handleChangePage(number)}
                                                        >
                                                            <a className="page-link">{number}</a>
                                                        </li>
                                                    ))}

                                                    <li className="page-item"><a className="page-link" onClick={currentPage + 1 <= pageNumbers.length ? this.handleChangePage(currentPage + 1) : () => { }}>Next</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RecentFile