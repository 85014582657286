import React from 'react';
import { connect } from 'react-redux';
import ReactWOW from 'react-wow';
import * as Icon from "react-feather";
import RegisterSuccess from "./Dialog/RegisterSuccess";
import history from "../../history";
import isEmpty from '../../validation';
import store from '../../store';
import Axios from 'axios';
import { forEach } from 'draft-js/lib/DefaultDraftBlockRenderMap';

class Homepage extends React.Component {
    state = {
        openRegisterSuccess: this.props.location.pathname.includes("registered"),
        sbTextContainers: [],
        sbImageContainers: [],
        content: []
    };

    componentWillMount() {
        if (!isEmpty(this.props.auth)) {
            if (this.props.auth.user_type === "CUSTOMER") {
                history.push("/dashboard");
            } else if (this.props.auth.user_type === "TUTOR") {
                history.push("/tutor-dashboard");
            }
        }
    }
    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2070782842)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers,
                    sbImageContainers: respond.data.consolidatedImageContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }
    handleClose = () => {
        this.setState({
            openRegisterSuccess: false
        })
    }

    render() {
        const { openRegisterSuccess, sbTextContainers, sbImageContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        });
        sbImageContainers.forEach(image => {
            content[image.id] = image.images
        });
        return (
            <React.Fragment>
                <div className="repair-main-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="repair-banner-content">
                                    <h1>{content[2032259902]}</h1>
                                    <ul className="list">
                                        <li>{content[2078991384]}</li>
                                        <li>{content[2037671700]}</li>
                                        <li>{content[2065476143]}</li>
                                        <li>{content[2031288912]}</li>
                                    </ul>
                                    <a className="nav-link" className="btn btn-primary" href="/sign-up">{content[2017717759]}</a>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="repair-banner-image">
                                    <img
                                        // src=""
                                        src={content[2063104646]}
                                        alt="image"
                                    />
                                    {/* <ReactWOW delay='0.6s' animation='zoomIn'>
                                        <img
                                            src=""
                                            className="wow zoomIn"
                                            data-wow-delay="0.6s"
                                            alt="image"
                                        />
                                    </ReactWOW>

                                    <ReactWOW delay='0.6s' animation='fadeInLeft'>
                                        <img
                                            src=""
                                            className="wow fadeInLeft"
                                            data-wow-delay="0.6s"
                                            alt="image"
                                        />
                                    </ReactWOW>

                                    <ReactWOW animation='rotateme'>
                                        <img
                                            src="/assets/images/repair-banner-image/circle.png"
                                            className="rotateme"
                                            alt="image"
                                        />
                                    </ReactWOW> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="boxes-area" style={{ marginTop: 0 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-box bg-eb6b3d" onClick={() => history.push("/sign-up")}>
                                    <div className="icon">
                                        <Icon.HelpCircle />
                                    </div>
                                    <h3>{content[2073187204]}</h3>
                                    <p>{content[2099525470]}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="single-box bg-f78acb" onClick={() => history.push("/sign-up")}>
                                    <div className="icon">
                                        <Icon.FileText />
                                    </div>
                                    <h3>{content[2058777520]}</h3>
                                    <p>{content[2072107158]}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6" onClick={() => history.push("/sign-up")}>
                                <div className="single-box">
                                    <div className="icon">
                                        <Icon.UserCheck />
                                    </div>
                                    <h3>{content[2059979034]}</h3>
                                    <p>{content[2082334149]}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6" onClick={() => history.push("/sign-up")}>
                                <div className="single-box">
                                    <div className="icon">
                                        <Icon.PenTool />
                                    </div>
                                    <h3>{content[2071847353]}</h3>
                                    <p>{content[2067869347]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <RegisterSuccess open={openRegisterSuccess} handleClose={this.handleClose} handleConfirm={this.handleClose} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth,
    }
}

export default connect(mapStateToProps)(Homepage);