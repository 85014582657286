import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import isEmpty from '../../../validation';

class AmountRefund extends React.Component {
    state = {
        amount: 0,
        showError: false
    }

    numberWithCommas = str => {
        return String(str).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    numberWithoutCommas = str => {
        if (typeof str === "number") {
            return str;
        }

        return parseInt(str.toLocaleString().split(".").join("")).toLocaleString('fullwide', { useGrouping: false });
    }

    handleChangeAmount = event => {
        this.setState({
            amount: this.numberWithoutCommas("0" + event.target.value.toString().trim())
        })
    }

    handleConfirm = () => {
        const { amount } = this.state;

        if (this.props.handleConfirm) {
            this.props.handleConfirm(amount)
        }

        this.setState({
            amount: 0
        })
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose()
        }

        this.setState({
            amount: 0
        })
    }

    render() {
        const { open, handleTopUpBalance } = this.props;
        const { showError, amount } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>MEMINTA PENARIKAN DANA</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <div className="input-group">
                            <div className="input-group-prepend" style={{ height: 35 }}>
                                <span className="input-group-text" id="currency">IDR</span>
                            </div>
                            <input
                                type="text"
                                placeholder="Masukan Jumlah"
                                value={this.numberWithCommas(amount)}
                                onChange={this.handleChangeAmount}
                                name="amount"
                                className={`form-control ${showError && isEmpty(amount) ? "is-invalid" : ""}`}
                                autoComplete="off"
                                autoFocus
                            />
                            <div className="invalid-feedback">
                                Cantumkan jumlah yang valid.
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-light" onClick={this.handleClose}>Batalkan</button>
                    <button className="btn btn-primary" onClick={this.handleConfirm}>OK</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default AmountRefund;