import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';

class TermConditionExpertTutor extends React.Component {
    render() {

        const { open, handleClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>TERMS AND CONDITIONS</h4>
                </DialogTitle>
                <DialogContent className="term-condition">
                    <div style={{ padding: 20 }}>
                        <p style={{ margin: 0 }}>Expert Tutor syarat dan ketentuan pembuatan skripsi/tesis/disertasi</p>
                        <br />
                        <ul className="list">
                            <li><span><b>Fasilitas Bimbingan Skripsi /Tesis/Disertasi.</b> Konsultasi untuk berbagai masalah yang ditemukan dalam proses pembuatan skripsi ini dapat negosiasikan waktu dan tempatnya. Bimbingan ini dimaksudkan agar mahasiswa benar-benar mengetahui tentang skripsinya, sehingga dapat dipertanggungjawabkan.</span></li>
                            <li><span><b>Jurnal dan referensi terbaru untuk Skripsi/Tesis/Disertasi.</b> Penggunaan jurnal dan referensi terbaru merupakan salah satu fasilitas yang kami berikan. Toleransi dari referensi yang digunakan dalam jasa pembuatan skripsi, tesis,diertasi kami batasi 5 tahun ke belakang, namun jika benar-benar tidak memungkinkan batasan kami perluas menjadi 10 tahun ke belakang.</span></li>
                            <li><span><b>Hasil Skripsi/Tesis/Disertasi Bukan plagiat.</b> Jaminan keaslian merupakan fasilitas kami yang berikan. Hasil pembuatan jasa kami merupakan penulisan yang dibuat dari awal, teori-teori pendukung dari buku dan jurnal, sehingga bukan merupakan hasil menjiplak dari penelitian yang telah dilakukan sebelumnya.</span></li>
                        </ul>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "20px 20px 20px 20px" }}>
                    <button className="btn btn-primary" onClick={handleClose}>OK</button>
                </DialogActions>
            </Dialog >
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default TermConditionExpertTutor;