import Axios from "axios";
import crypto from 'crypto';
import isEmpty from "../validation";
import setAuthToken from "../validation/setAuthToken";

// Set logged in user
const setCurrentUser = decoded => {
    return {
        type: "SET_AUTH",
        payload: decoded
    };
};

export default {
    setCurrentUser: decoded => setCurrentUser(decoded),

    // Log user out
    logoutUser: () => dispatch => {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user to {} which will set isAuthenticated to false
        dispatch(setCurrentUser(null));
    },

    loginCustomer: (config, callback) => {
        const hashPassword = crypto.createHash('sha256').update(config.password).digest('hex');

        var formData = new FormData();
        formData.append("fdpMJW", config.email)
        formData.append("HqUfjs", hashPassword);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/cxFP4AJVHxan6QXx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "LxtZAxR9kaYF2nwM",
                    password: "vQjGRsGgcU4j24wy"
                }
            }
        ).then(respond => {
            let err = {
                isEmpty: isEmpty(respond.data),
                customerIdNotFound: respond.data === "Wdjasg"
            }

            callback(respond.data, err)
        });
    },

    resendEmailCustomer: (config, callback) => {
        var formData = new FormData();
        formData.append("UBgQyg", config.email)
        // formData.append("PtaXcs", "http://dev.gettutor.id.atmos.id/reset/password/")
        formData.append("PtaXcs", "http://gettutor.id/reset/password/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/mgq86RUnsXtY3UJM",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "gTKFsJE3YdYVmj2S",
                    password: "KnXkKL2ntZBwvc3t"
                }
            }
        ).then(respond => {
            let err = {
                isEmpty: isEmpty(respond.data)
            };

            callback(respond.data, err)
        });
    },

    getCustomerDetail: (config, callback) => {
        var formData = new FormData();
        formData.append("UqADvv", config.uid);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WFVgZQZ6sd73R5HF",
                    password: "ef5hKtveCEpWL69q"
                }
            }
        ).then(respond => {
            let err = {
                isEmpty: isEmpty(respond.data),
                customerIdNotFound: respond.data === "VhUyMY"
            };

            callback(respond.data, err)
        });
    },
}