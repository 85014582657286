import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import isEmpty from '../../../validation';

class AcceptedQuestion extends React.Component {
    render() {
        const { open, handleClose, activeNotification } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Anda telah menyetujui pertanyaan ini!</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        {!isEmpty(activeNotification) && activeNotification.type.id === 2045482424 &&
                            <h6>
                                Anda mempunyai waktu {" "}
                                {!isEmpty(activeNotification) ? activeNotification.type.id === 2045482424 ?
                                    activeNotification.expected_duration_day > 0 ?
                                        (activeNotification.expected_duration_day + " Hari") :
                                        activeNotification.expected_duration_hour > 0 ?
                                            (activeNotification.expected_duration_hour + " Jam") :
                                            (activeNotification.expected_duration_minute + " Menit") :
                                    activeNotification.expected_duration_day > 0 ?
                                        (activeNotification.expected_duration_day + " Hari") :
                                        activeNotification.expected_duration_hour > 0 ?
                                            (activeNotification.expected_duration_hour + " Jam") :
                                            (activeNotification.expected_duration_month + " Bulan") : ""
                                }
                                {" "}
                                untuk menjawab pertanyaan!
                            </h6>
                        }
                        {!isEmpty(activeNotification) && activeNotification.type.id !== 2045482424 &&
                            <h6>
                                Anda telah menerima penawaran. Atur harga anda dengan customer. <a href="#"></a>
                                <a href={`/tutor-dashboard?question_id=${activeNotification.id}`} style={{ textDecoration: "underline", paddingLeft: 4 }}>Klik disini untuk mengatur harga mu</a>.
                            </h6>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-primary" onClick={handleClose}>OK</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default AcceptedQuestion;