import React from 'react';
import ResetPasswordTutor from './Dialog/ResetPasswordTutor';
import Axios from 'axios';
import isEmpty from '../../validation';
import store from '../../store';
import AuthActions from '../../actions/AuthActions';
import setAuthToken from '../../validation/setAuthToken';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import history from '../../history';
import * as Icon from "react-feather";
import crypto from 'crypto';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

class TutorLogin extends React.Component {
    state = {
        openResetPassword: false,
        email: "",
        password: "",
        keepLoggedIn: false,
        showError: false,
        userNotFound: false,
        notVerified: false,
        showPassword: false,
        sbTextContainers: [],
        content: []
    }

    toggleViewPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    handleOpenResetPassword = () => {
        this.setState({
            openResetPassword: true
        })
    }

    handleCloseResetPassword = () => {
        this.setState({
            openResetPassword: false
        })
    }

    handleKeepMeLoggedIn = event => {
        this.setState({
            keepLoggedIn: !this.state.keepLoggedIn
        })
    }

    handleSendEmail = email => {
        this.setState({
            openResetPassword: false,
            sentLinkSuccess: true,
            userNotFound: false,
            email
        })
    }

    componentWillMount() {
        // Check for token
        if (localStorage.jwtToken) {
            window.location.href = "/tutor-dashboard";
        }
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2062401939)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

    }

    getTutorDetail = uid => {
        const { keepLoggedIn } = this.state;

        var formData = new FormData();
        formData.append("MMgxhE", uid);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dW6Tf9RyDGWgKhgn",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "N5aqscHw5dYZtgQK",
                    password: "ww3LJEm9tvdRtSq8"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "WmjpFm" && respond.data !== "XtdUne") {
                    const payload = {
                        ...respond.data
                    }; // Create JWT Payload

                    const token = jwt.sign(
                        payload,
                        "SECRET",
                        !keepLoggedIn ? {
                            expiresIn: 3600
                        } : {}
                    );

                    localStorage.setItem("jwtToken", token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);

                    store.dispatch(AuthActions.setCurrentUser(decoded));

                    // Set token to Auth header
                    setAuthToken(token);

                    if (!isEmpty(respond.data.education_level)) {
                        history.push("/tutor-dashboard");
                    } else {
                        history.push("/tutor-dashboard/profile");
                    }
                }
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { email, password, keepLoggedIn } = this.state;

        this.setState({
            showError: isEmpty(email) || isEmpty(password),
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                const hashPassword = crypto.createHash('sha256').update(password).digest('hex');
                var formData = new FormData();
                formData.append("SMxBNK", email)
                formData.append("ZPaFLz", hashPassword)
                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/U6zJjyK8as7cvhrR",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "UEhDPb4WuNb9tvrT",
                            password: "uMXStpz6g5HWZVgR"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "wUySPg" && respond.data !== "NJnrvW") {
                            this.getTutorDetail(respond.data)
                        }

                        this.setState({ userNotFound: respond.data === "wUySPg", notVerified: respond.data === "NJnrvW" })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    handleResendEmail = () => {
        const { email } = this.state;

        if (!isEmpty(email) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {

            store.dispatch({
                type: "SET_LOADING",
                payload: true
            });

            this.setState({
                sentLinkSuccess: false,
            })

            var formData = new FormData();
            formData.append("bUxbAW", email)
            // formData.append("VxGByf", "http://dev.gettutor.id.atmos.id/reset/tutor-password/")
            formData.append("VxGByf", "http://gettutor.id/reset/tutor-password/")
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/FE7YJ9AQQv6AR6jV",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "tkDFb6Pyx2MrfDuL",
                        password: "nyvzXBS8mLBdu4Le"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    this.handleSendEmail(email)
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    render() {
        const { openResetPassword, email, password, keepLoggedIn, showError, userNotFound, sentLinkSuccess, notVerified, showPassword, sbTextContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        })
        return (
            <div className="login ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>{content[2041983172]}</h2>
                                <div className="bar"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="col-lg-6 col-md-12">

                            <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                    <div className="row">
                                        {notVerified &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.AlertTriangle /><div style={{ width: 10 }}> </div> Akun ini tidak terverifikasi.
                                                </div>
                                            </div>
                                        }
                                        {userNotFound &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.AlertTriangle /><div style={{ width: 10 }}> </div>Email dan sandi Anda tidak sesuai, mohon dicoba kembali.
                                                </div>
                                            </div>
                                        }
                                        {sentLinkSuccess &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "30%" }}>
                                                        <Icon.Info />
                                                    </div>
                                                    <div style={{ height: 10 }}></div>
                                                    <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                                        <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                                    </span>
                                                </div>
                                            </div>
                                        }

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2049911862]}<span className="required">*</span></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className={`form-control ${showError && isEmpty(email) ? "is-invalid" : ""}`}
                                                    value={email}
                                                    onChange={this.handleInputChange("email")}
                                                />
                                                <div className="invalid-feedback">
                                                    Cantumkan alamat email yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <label>{content[2035096661]} <span className="required">*</span></label>
                                                    </div>
                                                    <div className="forgot-password col-lg-6">
                                                        <a className="nav-link" onClick={this.handleOpenResetPassword}>{content[2073309774]} ?</a>
                                                    </div>
                                                </div>
                                                <div className="input-icons">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        className={`form-control ${showError && isEmpty(password) ? "is-invalid" : ""}`}
                                                        value={password}
                                                        onChange={this.handleInputChange("password")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan sandi yang valid.
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword} className="icon">
                                                        {showPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="keep-logged-in" value={keepLoggedIn} onChange={this.handleKeepMeLoggedIn} />
                                                <label className="form-check-label" htmlFor="keep-logged-in">{content[2038423325]}</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button className="btn btn-primary submit-btn" type="submit">
                                                Login
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <p className="bottom-text">{content[2033726661]}
                                            <a className="nav-link" href="/tutor-register">{content[2011253694]}</a>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="shape1">
                        <img src="/assets/images/abstract/shape1.png" alt="shape" />
                    </div>
                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>

                <ResetPasswordTutor open={openResetPassword} handleClose={this.handleCloseResetPassword} handleSendEmail={this.handleSendEmail} />
            </div>
        )
    }
}

export default TutorLogin