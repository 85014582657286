import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment-timezone";
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#05B1F1",
            }
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: "#05B1F1",
            }
        }
        ,
        MuiTabs: {
            indicator: {
                backgroundColor: "#223F63",
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#05B1F1",
            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: "#05B1F1",
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: "#05B1F1",
            },
            noPoint: {
                backgroundColor: "#05B1F1",
            }
        },
        MuiPickersClockNumber: {
            clockNumberSelected: {
                backgroundColor: "#05B1F1",
            }
        }
    }
});

ReactDOM.render(
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
