import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import store from '../../store';
import isEmpty from '../../validation';


class BecomeTutor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sbTextContainers: [],
            content: []
        };
    }
    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2058622611)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

    }
    render() {
        const { sbTextContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        })
        return (
            <div className="tutor-choice ptb-80">
                <div className="container h-100">
                    <Grid container spacing={0} alignItems="center" style={{ minHeight: "calc(100vh - 273px)" }}>
                        <Grid container spacing={5} alignItems="center" justify="center">
                            <Grid item xs={12}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section-title" style={{ marginBottom: 0 }}>
                                            <h2>{content[2040447186]}</h2>
                                            <div className="bar"></div>
                                            <p>{content[2070614517]}</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} container alignItems="center" justify="center">
                                        <a className="nav-link" className="btn btn-primary" href="/tutor-register">{content[2073760993]}</a>
                                    </Grid>
                                    <Grid item xs={12} md={6} container alignItems="center" justify="center">
                                        <a className="nav-link" className="btn btn-light" href="/tutor-login">{content[2090274075]}</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

export default BecomeTutor