import React from 'react';
import * as Icon from 'react-feather';
import { DropzoneArea } from 'material-ui-dropzone';
import { Chip, Grid, IconButton } from '@material-ui/core';
import isEmpty from '../../validation';
import history from '../../history';
import Axios from 'axios';
import crypto from 'crypto';
import jwt from "jsonwebtoken";
import setAuthToken from '../../validation/setAuthToken';
import AuthActions from '../../actions/AuthActions';
import store from '../../store';
import jwt_decode from "jwt-decode";
import Select from '../../reusable-components/Inputs/Select';
import TermAndCondition from './Dialog/TermAndCondition';

class FreeAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            fullname: "",
            password: "",
            retypePassword: "",
            school: "",
            files: [],
            showError: false,
            emailExist: false,
            schools: [],
            showPassword: false,
            showRetypePassword: false,
            openTermCondition: false,
            agreeTermCondition: false,
            sbTextContainers: [],
            content: []
        };
    }

    toggleViewPassword = password => () => {
        this.setState({
            [password]: !this.state[password]
        })
    }

    handleInputChange = name => event => [
        this.setState({
            [name]: event.target.value
        })
    ]

    handleSelectChange = value => {
        this.setState({
            school: value
        })
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = () => {
        this.setState({
            files: []
        })
    }

    handleCreateSchool = string => {
        const schools = [...this.state.schools];

        schools.push({
            id: string,
            name: string
        })

        this.setState({
            schools,
            school: string
        })
    }

    handleClickTermCondition = e => {
        e.preventDefault();

        this.setState({
            openTermCondition: true
        })
    }

    handleCloseTermAndCondition = () => {
        this.setState({
            openTermCondition: false
        })
    }

    componentWillMount() {
        // Check for token
        if (localStorage.jwtToken) {
            window.location.href = "/dashboard";
        }
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("ycAgZQ", 9999)
        formData.append("Lfdzbb", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/SVCKWKYsVny9YU9N",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "V2Wap9myX6B9eQqN",
                    password: "uLgjTBB6eCrZ84wZ"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BUSxfL" && respond.data !== "JPeUuS") {
                this.setState({
                    schools: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        var formData = new FormData();
        formData.append("fkXYgp", 2098874932)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/dR0dRO2inldriD2cim",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "stO1hlnuXuvujAspah",
                    password: "SwohufrlXePoThuPE9"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data)) {
                this.setState({
                    sbTextContainers: respond.data.textContainers
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

    }

    getUserDetail = uid => {
        var formData = new FormData();
        formData.append("UqADvv", uid);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WFVgZQZ6sd73R5HF",
                    password: "ef5hKtveCEpWL69q"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "VhUyMY") {
                    const payload = {
                        ...respond.data,
                    }; // Create JWT Payload

                    const token = jwt.sign(
                        payload,
                        "SECRET",
                        { expiresIn: 3600 }
                    );

                    localStorage.setItem("jwtToken", token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);

                    store.dispatch(AuthActions.setCurrentUser(decoded));

                    // Set token to Auth header
                    setAuthToken(token);

                    history.push("/dashboard");
                }
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { email, fullname, password, school, files, agreeTermCondition } = this.state;

        this.setState({
            showError: !agreeTermCondition || isEmpty(email) || isEmpty(fullname) || this.passwordValidation(password) || this.confirmPasswordValidation(password) || isEmpty(school) || isEmpty(files)
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                const hashPassword = crypto.createHash('sha256').update(password).digest('hex');
                var formData = new FormData();
                formData.append("xRfhRG", email)
                formData.append("gKTFus", fullname)
                formData.append("QbWkBs", hashPassword)
                formData.append("cafDBz", school)
                files.map((file, index) => {
                    formData.append(`nwRsPq${index + 1}`, file)
                })
                //formData.append("VPwkED", "http://dev.gettutor.id.atmos.id/email/verification/")
                formData.append("VPwkED", "http://gettutor.id/email/verification/")

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/2tDSwU5DgeW3D93Y",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "z9vU2ztDLHPjaJ8U",
                            password: "D4TVQgfR2bMJufVX"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "gfcMSJ") {
                            this.getUserDetail(respond.data)
                        }

                        this.setState({ emailExist: respond.data === "gfcMSJ" })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    passwordValidation = password => {
        return isEmpty(password) || String(password).length < 8 || !(/^((?=.*\d)(?=.*?[a-z])(?=.*[A-Z])(?=.*\W).*)$/.test(password));
    }

    confirmPasswordValidation = password => {
        const { retypePassword } = this.state;

        return isEmpty(retypePassword) || password !== retypePassword;
    }

    render() {
        const { openTermCondition, email, fullname, password, retypePassword, school, files, schools, showError, emailExist, showPassword, showRetypePassword, agreeTermCondition, sbTextContainers, content } = this.state;
        sbTextContainers.forEach(text => {
            content[text.id] = text.content
        })
        return (
            <div className="free-access ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>{content[2044949747]}</h2>
                                <div className="bar"></div>
                                <p>{content[2036889983]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="explanation row h-100 justify-content-center align-items-center">
                                <div>
                                    <h4></h4>
                                    <h2>{content[2039158300]}</h2>
                                    <p>{content[2052503234]}</p>
                                    <ul>
                                        <li><span><Icon.Check /> {content[2004791846]}</span></li>
                                        <li><span><Icon.Check /> {content[2074813146]}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2080528480]} <span className="required">*</span></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className={`form-control ${(showError && isEmpty(email) || emailExist) ? "is-invalid" : ""}`}
                                                    value={email}
                                                    onChange={this.handleInputChange("email")}
                                                />
                                                <div className="invalid-feedback">
                                                    {emailExist ? "Email sudah terdaftar." : "Cantumkan alamat email yang valid."}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2033140633]} <span className="required">*</span></label>
                                                <input
                                                    type="text"
                                                    name="fullname"
                                                    className={`form-control ${showError && isEmpty(fullname) ? "is-invalid" : ""}`}
                                                    value={fullname}
                                                    onChange={this.handleInputChange("fullname")}
                                                />
                                                <div className="invalid-feedback">
                                                    Cantumkan nama lengkap yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2094401294]} <span className="required">*</span></label>
                                                <div className="input-icons">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        className={`form-control ${showError && this.passwordValidation(password) ? "is-invalid" : ""}`}
                                                        value={password}
                                                        onChange={this.handleInputChange("password")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan sandi lengkap yang valid. Gunakan minimal 8 karakter dengan campuran huruf kapital, huruf kecil, angka & simbol.
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword("showPassword")} className="icon">
                                                        {showPassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2001747161]} <span className="required">*</span></label>
                                                <div className="input-icons">
                                                    <input
                                                        type={showRetypePassword ? "text" : "password"}
                                                        name="confirm-new-password"
                                                        className={`form-control ${showError && this.confirmPasswordValidation(password) ? "is-invalid" : ""}`}
                                                        value={retypePassword}
                                                        onChange={this.handleInputChange("retypePassword")}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {(!isEmpty(retypePassword) && password !== retypePassword) ?
                                                            "Konfirmasi sandi tidak sesuai" :
                                                            "Cantumkan konfirmasi sandi baru yang valid."
                                                        }
                                                    </div>
                                                    <IconButton onClick={this.toggleViewPassword("showRetypePassword")} className="icon">
                                                        {showRetypePassword ? <Icon.EyeOff /> : <Icon.Eye />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2064481120]} <span className="required">*</span></label>
                                                <div className={`select-box ${showError && isEmpty(school) ? "is-invalid" : ""}`}>
                                                    <Select
                                                        options={schools.map(school => ({ value: school.name, label: school.name }))}
                                                        placeholder=""
                                                        noOptionsMessage={() => {
                                                            return 'Tidak ada pilihan'
                                                        }}
                                                        value={school}
                                                        onChange={this.handleSelectChange}
                                                        // onCreateOption={this.handleCreateSchool}
                                                        error={showError && isEmpty(school)}
                                                    />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: showError && isEmpty(school) && "block" }}>
                                                    Cantumkan sekolah/universitas yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{content[2092361176]}</label>
                                                <DropzoneArea
                                                    dropzoneClass={`custom-dropzone ${files.length >= 10 ? "hide" : ""} ${showError && isEmpty(files) ? "error-dropzone" : ""}`}
                                                    dropzoneText={content[2026160703]}
                                                    filesLimit={10}
                                                    initialFiles={files}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewText=""
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ variant: "outlined", style: { color: "#223F63", borderColor: "#223F63" } }}
                                                    onChange={this.handleUpload.bind(this)}
                                                    showAlerts={false}
                                                />
                                                <div className="invalid-feedback" style={{ display: showError && isEmpty(files) && "block" }}>
                                                    Cantumkan file yang valid.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="agree-term-condition" value={agreeTermCondition} onChange={() => this.setState({ agreeTermCondition: !agreeTermCondition })} />
                                                <p className="bottom-text" style={{ marginBottom: 0 }}>{content[2004699681]}<a className="nav-link" className="nav-link" href="#" onClick={this.handleClickTermCondition}>{content[2044316118]}</a>.</p>
                                                <div className="invalid-feedback" style={{ display: showError && !agreeTermCondition && "block" }}>
                                                    Anda harus setuju dengan syarat dan kondisi.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button className="btn btn-primary submit-btn" type="submit" >
                                                {content[2035107934]}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <TermAndCondition open={openTermCondition} handleClose={this.handleCloseTermAndCondition} />

                    <div className="shape1">
                        <img src="/assets/images/abstract/shape1.png" alt="shape" />
                    </div>
                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>
            </div>
        )
    }
}

export default FreeAccess