import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import swal from 'sweetalert';
import isEmpty from '../../../validation';
import store from '../../../store';
import Axios from 'axios';

class Complain extends React.Component {
    state = {
        email: "",
        name: "",
        message: "",
        showError: false
    }

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    onClose = () => {
        this.setState({
            email: "",
            name: "",
            message: "",
            showError: false
        })

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        const { name, email, message } = this.state;

        this.setState({
            showError: isEmpty(name) || isEmpty(email) || isEmpty(message)
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("gaZUkK", name)
                formData.append("FVjrJq", email)
                formData.append("bGzcqp", message)

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/TXJHZmbj9pUPfUXX",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "fkd8jNwJRvrwJCTJ",
                            password: "gVghFXXjtH425JHm"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        swal(
                            "BERHASIL!",
                            "Pesan Anda telah berhasil terkirim.",
                            "success", {
                            timer: 2500,
                            buttons: false
                        })

                        if (this.props.handleClose) {
                            this.props.handleClose();
                        }

                        this.setState({
                            email: "",
                            name: "",
                            message: "",
                            showError: false
                        })
                    } else {
                        swal(
                            "Error!",
                            "Ada terjadi kesalahan!",
                            "error"
                        )
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    render() {
        const { open } = this.props;
        const { name, email, message, showError } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Tuliskan keluhan/masalah Anda</h4>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: "20px 0px" }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Nama <span className="required">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Masukan Nama"
                                        className={`form-control ${showError && isEmpty(name) ? "is-invalid" : ""}`}
                                        name="name"
                                        value={name}
                                        onChange={this.handleInputChange("name")}
                                    />
                                    <div className="invalid-feedback">
                                        Cantumkan nama yang valid.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Email <span className="required">*</span></label>
                                    <input
                                        type="email"
                                        placeholder="Masukan Email"
                                        className={`form-control ${showError && isEmpty(email) ? "is-invalid" : ""}`}
                                        name="email"
                                        value={email}
                                        onChange={this.handleInputChange("email")}
                                    />
                                    <div className="invalid-feedback">
                                        Cantumkan email yang valid.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Pesan <span className="required">*</span></label>
                                    <textarea
                                        className={`form-control description ${showError && isEmpty(message) ? "is-invalid" : ""}`}
                                        placeholder="Tulis pesan anda disini"
                                        rows="8"
                                        style={{ height: "100%" }}
                                        maxLength="140"
                                        value={message}
                                        name="message"
                                        onChange={this.handleInputChange("message")}
                                    >
                                    </textarea>
                                    <div className="invalid-feedback">
                                        Cantumkan pesan yang valid.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-primary w-100" onClick={this.handleSubmit}>Kirim</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default Complain;