import React from 'react';
import * as Icon from "react-feather";
import { IconButton, Grid, Slide, Collapse, Tooltip } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { connect } from "react-redux";
import NoData from '../../../../icons/no-data';
import Axios from 'axios';
import isEmpty from '../../../../validation';
import store from '../../../../store';
import EndLiveDialog from '../../Dialog/EndLiveDialog';
import history from '../../../../history';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import SuccessLiveDialog from '../../Dialog/SuccessGoLive';
import moment from 'moment';

class Class extends React.Component {
    state = {
        activeFile: null,
        openSuccessLive: false,
        openEndLive: false,
        openConfirmationGoLive: false,
        openConfirmationEndLive: false,
        comment: "",
        showError: false,
        openReplyComment: null,
        reply: "",
        commentInvalid: false,
        replyInvalid: false,
        countComments: 5,
        showedComments: [],
        showedReplies: {},
        countReplies: {},
        classes: []
    }

    componentWillMount() {
        const { auth } = this.props;

        if (auth.user_type === "CUSTOMER" && !auth.is_verified) {
            history.push("/dashboard/class")
        }

    }

    getNewClass = () => {
        const { auth } = this.props;
        let classes = []
        if (auth) {
            var formData = new FormData();
            formData.append("KyerbD", 99999);
            formData.append("pJppPs", 0);
            formData.append("ERgtaC", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Ge6VmLzuykB6CCPv",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "MYBwde3K92ACp8vK",
                        password: "t8uUuZ3wc92qEGZh"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "PQyyXb") {
                    respond.data.forEach(datum => {
                        classes.push(datum)
                    })

                    this.setState({
                        classes
                    })
                }

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    openTabSection = (evt, tabName) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("class-tab-content");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }


        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }

        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    handleClickFile = file => () => {
        this.setState({
            activeFile: file
        })
    }

    handleGoLive = () => {
        const { auth, activeData } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("cMnZkX", activeData.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/V8xs8VZh7mMfHcz9",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "gYS4ZrV3ycbRLrhE",
                    password: "TS8gQg6y5rbanTf5"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "nzuzVw") {
                this.setState({
                    openConfirmationGoLive: false,
                    openSuccessLive: true
                });

                store.dispatch({
                    type: "UPDATE_ACTIVE_CLASS",
                    payload: {
                        ...this.props.activeData,
                        status: 2015158996
                    }
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleEndLive = () => {
        const { auth, activeData } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("ucMgaU", activeData.id);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/cjNPVRxangEJ2gEd",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "GxmTN9QJa3dKJg2W",
                    password: "zpyZ2srJty768D4h"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "dwyuJw") {
                this.setState({
                    openConfirmationEndLive: false,
                    openEndLive: true
                })

                store.dispatch({
                    type: "UPDATE_ACTIVE_CLASS",
                    payload: {
                        ...this.props.activeData,
                        status: 2097058443
                    }
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseEndLiveDialog = () => {
        this.setState({
            openEndLive: false
        })
    }

    handleOpenConfirmationEndLive = () => {
        this.setState({
            openConfirmationEndLive: true
        })
    }

    handleCloseConfirmationEndLive = () => {
        this.setState({
            openConfirmationEndLive: false
        })
    }

    handleOpenConfirmationGoLive = () => {
        this.setState({
            openConfirmationGoLive: true
        })
    }

    handleCloseConfirmationGoLive = () => {
        this.setState({
            openConfirmationGoLive: false
        })
    }

    handleCloseSuccessLiveDialog = () => {
        this.setState({
            openSuccessLive: false
        })
    }

    handleSendComment = fromReply => e => {
        e.preventDefault();

        const { activeData, auth } = this.props;
        const { comment, reply, openReplyComment } = this.state;

        this.setState({
            showError: (!fromReply && isEmpty(comment)) || (fromReply && isEmpty(reply)),
            commentInvalid: !fromReply && isEmpty(comment),
            replyInvalid: fromReply && isEmpty(reply)
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("cdrCSB", activeData.id);
                if (auth.user_type === "TUTOR") {
                    formData.append("fRePpn", auth.id);
                } else {
                    formData.append("RCTfJg", auth.id);
                }
                formData.append("pUkTnQ", !isEmpty(comment) ? comment : reply);
                formData.append("nxYpGv", openReplyComment);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/X4ub4ND97wEYLRjp",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "BqqbTEC4Q6ZnbfJq",
                            password: "r4YSHu3LuTWZUwxe"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "WbSKBA" && respond.data !== "gTzTqd" && respond.data !== "SpgUgF") {
                        const payload = {
                            ...activeData,
                            comments: [
                                ...activeData.comments,
                                respond.data
                            ]
                        }

                        let showedComments = [
                            ...this.state.showedComments
                        ];
                        let showedReplies = {
                            ...this.state.showedReplies,
                            [respond.data.id]: []
                        };

                        if (!fromReply) {
                            showedComments = [
                                respond.data,
                                ...this.state.showedComments
                            ];

                            if (showedComments.length >= this.state.countComments) {
                                showedComments.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).shift();
                            }
                        } else {
                            showedReplies = {
                                ...this.state.showedReplies,
                                [openReplyComment]: [
                                    respond.data,
                                    ...this.state.showedReplies[openReplyComment],
                                ]
                            }

                            if (showedReplies[openReplyComment].length >= this.state.countReplies[openReplyComment]) {
                                showedReplies[openReplyComment].sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).shift();
                            }
                        }

                        store.dispatch({
                            type: "UPDATE_ACTIVE_CLASS",
                            payload
                        })

                        this.setState({
                            reply: "",
                            comment: "",
                            showError: false,
                            openReplyComment: this.state.openReplyComment ? this.state.openReplyComment : null,
                            showedComments,
                            showedReplies
                        })
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    handleClickReply = currentCommentId => () => {
        this.setState({
            openReplyComment: currentCommentId === this.state.openReplyComment ? null : currentCommentId,
            reply: "",
            replyInvalid: ""
        })
    }

    showMoreComments = () => {
        const { activeData } = this.props;

        this.setState({
            countComments: this.state.countComments + 5
        }, () => {

            let showedComments = [];
            let showedReplies = {};
            let countReplies = {};

            activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
                .filter(item => !isEmpty(item.text_content) && isEmpty(item.parent_comment)).forEach((comment, index) => {
                    if ((index + 1) <= this.state.countComments) {
                        showedComments.push(comment)
                        showedReplies[comment.id] = [];
                        countReplies[comment.id] = 0;

                        activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
                            .filter(item => !isEmpty(item.text_content) && item.parent_comment === comment.id).forEach((reply, replyIndex) => {
                                if ((replyIndex + 1) <= countReplies[comment.id]) {
                                    showedReplies[comment.id].push(reply)
                                }
                            })
                    }
                })

            this.setState({
                showedComments,
                showedReplies,
                countReplies
            })
        })
    }

    showMoreReplies = commentId => () => {
        const { activeData } = this.props;

        this.setState({
            countReplies: {
                ...this.state.countReplies,
                [commentId]: this.state.countReplies[commentId] + 3
            }
        }, () => {
            let showedReplies = [];

            activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
                .filter(item => !isEmpty(item.text_content) && isEmpty(item.parent_comment)).forEach((comment, index) => {
                    if ((index + 1) <= this.state.countComments) {
                        showedReplies[comment.id] = [];
                    }
                })

            activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
                .filter(item => !isEmpty(item.text_content) && item.parent_comment === commentId).forEach((reply, index) => {
                    if ((index + 1) <= this.state.countReplies[commentId]) {
                        showedReplies[commentId].push(reply)
                    }
                })

            this.setState({
                showedReplies
            })
        })
    }

    componentDidMount() {
        const { activeData } = this.props;

        let showedComments = [];
        let showedReplies = {};
        let countReplies = {};

        activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
            .filter(item => !isEmpty(item.text_content) && isEmpty(item.parent_comment)).forEach((comment, index) => {
                if ((index + 1) <= this.state.countComments) {
                    showedComments.push(comment)
                    showedReplies[comment.id] = [];
                    countReplies[comment.id] = 0;

                    activeData.comments.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))
                        .filter(item => !isEmpty(item.text_content) && item.parent_comment === comment.id).forEach((reply, replyIndex) => {
                            if ((replyIndex + 1) <= countReplies[comment.id]) {
                                showedReplies[comment.id].push(reply)
                            }
                        })
                }
            })

        this.setState({
            showedComments,
            showedReplies,
            countReplies
        })

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        this.getNewClass();
    }

    render() {
        const { auth, activeData } = this.props;
        const {
            activeFile,
            openSuccessLive,
            openEndLive,
            openConfirmationGoLive,
            openConfirmationEndLive,
            commentInvalid,
            replyInvalid,
            comment,
            showedComments,
            openReplyComment,
            reply,
            showedReplies,
            classes,
            countReplies
        } = this.state;

        const isTutorDashboard = auth ? auth.user_type === "TUTOR" : false;

        const isAvailableForLive = isTutorDashboard && activeData && moment().isSameOrAfter(moment(activeData.live_time)) && !isEmpty(classes) && classes.every(item => item.status !== 2015158996)

        console.log(activeFile)
        return (
            <div className="online-class-container" style={{ minHeight: "100vh", height: "auto", minWidth: 700 }}>
                <div className="content container ptb-80">
                    <div className="online-class" style={{ height: "auto" }}>
                        <div className="detail-class">
                            <div className="info">
                                <div className="display-name" style={{ marginLeft: 20 }}>
                                    <p style={{ fontWeight: 500, fontSize: 20, color: "#ffffff" }}>{activeData.subject.name} - {activeData.name}</p>
                                </div>
                                {isTutorDashboard && (activeData.status === 2097058443 || activeData.status === 2089985133) &&
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: 20 }}>
                                        {isAvailableForLive &&
                                            <button className="btn btn-primary" onClick={this.handleOpenConfirmationGoLive}>Go Live</button>

                                        }
                                        {!isAvailableForLive &&
                                            <Tooltip title="You only can have 1 live class">
                                                <button className="btn btn-primary disable-btn" onClick={() => { }}>Go Live</button>
                                            </Tooltip>
                                        }
                                    </div>
                                }

                                {isTutorDashboard && activeData.status === 2015158996 &&
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: 20 }}>
                                        <button className="btn btn-danger" onClick={this.handleOpenConfirmationEndLive}>End Live</button>
                                    </div>
                                }
                            </div>
                            <div className="lesson">
                                <div className="file-info">
                                    <div className="single-file" style={{ padding: 20 }}>
                                        {activeFile &&
                                            <>
                                                <div style={{ flex: "80%", marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                                                    <div style={{ position: "absolute", right: 10, top: 10 }}>
                                                        <a className="btn btn-primary" style={{ display: "flex", padding: 8 }} href={activeFile.file_content.url} target="_blank" download >{<Icon.Download style={{ height: 15, width: 15 }} />}</a>
                                                    </div>
                                                    <div>
                                                        <Grid style={{ height: 300 }} container alignItems="center" justify="center">
                                                            {activeFile && activeFile.file_content.type.includes("image") ?
                                                                <img src={activeFile.file_content.url} style={{ height: 300 }} />
                                                                : activeFile && activeFile.file_content.type.includes("video") ?
                                                                    <video controls style={{ height: 300 }} disablePictureInPicture controlsList="nodownload">
                                                                        <source src={activeFile.file_content.url} type="video/mp4" />
                                                                        <source src={activeFile.file_content.url} type="video/ogg" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                    : activeFile &&
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <Icon.File style={{ height: 30, width: 30 }} />
                                                                        <p>{activeFile.file_content.name}</p>
                                                                    </div>}
                                                        </Grid>
                                                    </div>
                                                </div>
                                                <div className="products-description" style={{ flex: "20%" }}>
                                                    <h6 style={{ marginBottom: 5 }}>Deskripsi File</h6>
                                                    <p>{unescape(activeFile.description)}</p>
                                                </div>
                                            </>
                                        }
                                        {!activeFile &&
                                            <div className="empty-state">
                                                <div className="icon-container">
                                                    <NoData />
                                                </div>
                                                <p style={{ fontSize: 20 }}>
                                                    Tidak ada file yang dipilih
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{ overflowY: "auto", flex: "40%", marginLeft: 20, borderRadius: "8px", padding: "20px", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)", maxWidth: 296 }}>
                                    <TreeView
                                        defaultCollapseIcon={<Icon.ChevronDown />}
                                        defaultExpandIcon={<Icon.ChevronRight />}
                                        disableSelection
                                    >
                                        {activeData.folders.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).map((folder, index) => (
                                            <TreeItem
                                                key={index}
                                                nodeId={String(folder.id)}
                                                label={<div style={{ display: "flex", alignItems: "center" }}>
                                                    <Icon.Folder style={{ marginRight: 10 }} />
                                                    {folder.name}
                                                </div>}
                                                classes={{ label: "tree-label" }}
                                            >
                                                {folder.files.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).map((file, index) => (
                                                    <TreeItem
                                                        key={file.id}
                                                        onClick={this.handleClickFile(file)}
                                                        nodeId={String(file.id)}
                                                        label={<div style={{ display: "flex", alignItems: "center" }}>
                                                            <Icon.File style={{ marginRight: 10, height: 18, width: 18 }} />
                                                            <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                                {file.name}
                                                            </span>
                                                        </div>}
                                                        classes={{ label: "tree-label", }}
                                                    />
                                                ))}
                                            </TreeItem>
                                        ))}
                                    </TreeView>
                                </div>
                            </div>
                            <div className="products-details-tabs" style={{ marginTop: 0, padding: "0px 20px 20px 20px" }}>
                                <ul id="tabs">
                                    <li
                                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab0') }}
                                        className="active"
                                    >
                                        <a href="#">
                                            Deskripsi
                                        </a>
                                    </li>

                                    <li
                                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab1') }}
                                        className=""
                                    >
                                        <a href="#">
                                            Komentar
                                        </a>
                                    </li>

                                    {/* <li
                                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab2') }}
                                        className=""
                                    >
                                        <a href="#">
                                            Live Chat
                                        </a>
                                    </li> */}
                                </ul>

                                <div id="tab0" className="class-tab-content show">
                                    <div className="products-description" style={{ padding: 20 }}>
                                        <p>{unescape(activeData.description)}</p>
                                    </div>
                                </div>

                                <div id="tab1" className="class-tab-content">
                                    <div className="blog-details-area" style={{ textAlign: "left" }}>
                                        <div className="post-comments" style={{ paddingTop: 0, boxShadow: "none", background: "#FFFFFF" }}>
                                            {showedComments.length !== activeData.comments.filter(item => !isEmpty(item.text_content) && isEmpty(item.parent_comment)).length &&
                                                <div style={{ textAlign: "center" }}>
                                                    <a style={{ fontWeight: 600 }} onClick={this.showMoreComments}>Show more comments</a>
                                                </div>
                                            }
                                            {showedComments.sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).map(comment => {
                                                const user = comment.customer_user ? comment.customer_user : comment.tutor_user ? comment.tutor_user : {}

                                                return (
                                                    <React.Fragment key={comment.id}>
                                                        <div className="single-comment">
                                                            <div className="comment-img">
                                                                <img src={user.profile_picture} alt="client" />
                                                            </div>
                                                            <div className="comment-content">
                                                                <h4>{user.name}</h4>
                                                                <p>{unescape(comment.text_content)}</p>
                                                                <span>{moment(comment.modified_at).utc().tz(`Asia/Jakarta`).format("MMM DD, YYYY - H:mm A")}</span>
                                                                <a onClick={this.handleClickReply(comment.id)}>Reply</a>
                                                            </div>
                                                        </div>
                                                        {showedReplies[comment.id].length !== activeData.comments.filter(item => !isEmpty(item.text_content) && item.parent_comment === comment.id).length &&
                                                            <div className="single-comment left-m" style={{ paddingLeft: 10 }}>
                                                                <a style={{ fontWeight: 600 }} onClick={this.showMoreReplies(comment.id)}>Tampilkan Balasan</a>
                                                            </div>
                                                        }
                                                        {showedReplies[comment.id].sort((a, b) => new Date(a.modified_at) - new Date(b.modified_at)).map(reply => {
                                                            const userReply = reply.customer_user ? reply.customer_user : reply.tutor_user ? reply.tutor_user : {}

                                                            return (
                                                                <div key={comment.id + "-" + reply.id} className="single-comment left-m">
                                                                    <div className="comment-img">
                                                                        <img src={userReply.profile_picture} alt="client" />
                                                                    </div>
                                                                    <div className="comment-content">
                                                                        <h4>{userReply.name}</h4>
                                                                        <p>{unescape(reply.text_content)}</p>
                                                                        <span>{moment(reply.modified_at).utc().tz(`Asia/Jakarta`).format("MMM DD, YYYY - H:mm A")}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                        <Collapse in={openReplyComment === comment.id}>
                                                            <form id={comment.id} onSubmit={this.handleSendComment(true)} className="single-comment left-m" style={{ paddingLeft: 0 }}>
                                                                <div className="leave-a-reply left-m" style={{ padding: 0, marginTop: 0, boxShadow: "none", background: "#FFFFFF" }}>
                                                                    <h3>Tulis komentar</h3>
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12">
                                                                            <div className="form-group">
                                                                                <textarea
                                                                                    name="comment"
                                                                                    cols="30"
                                                                                    rows="5"
                                                                                    className={`form-control ${replyInvalid && isEmpty(reply) ? "is-invalid" : ""}`}
                                                                                    placeholder="komentarmu"
                                                                                    value={this.state.reply}
                                                                                    onChange={e => this.setState({ reply: e.target.value })}
                                                                                    style={{ height: "100%" }}
                                                                                    autoFocus={openReplyComment === comment.id}
                                                                                />
                                                                                <div className="invalid-feedback">
                                                                                    Cantumkan komentar yang valid.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-12 col-md-12">
                                                                            <button type="submit" className="btn btn-primary">Kirim</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </Collapse>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                        <form onSubmit={this.handleSendComment(false)}>
                                            <div className="leave-a-reply" style={{ marginTop: 0, paddingTop: 0, boxShadow: "none", background: "#FFFFFF" }}>
                                                <h3>Tulis komentar</h3>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                name="comment"
                                                                cols="30"
                                                                rows="5"
                                                                className={`form-control ${commentInvalid && isEmpty(comment) ? "is-invalid" : ""}`}
                                                                placeholder="Komentarmu"
                                                                value={this.state.comment}
                                                                onChange={e => this.setState({ comment: e.target.value })}
                                                                style={{ height: "100%" }}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Cantumkan komentar yang valid.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12">
                                                        <button type="submit" className="btn btn-primary">Kirim</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SuccessLiveDialog open={openSuccessLive} handleClose={this.handleCloseSuccessLiveDialog} />
                <EndLiveDialog
                    open={openEndLive}
                    handleClose={this.handleCloseEndLiveDialog}
                    title="Kelas telah berakhir!"
                    content="Terima kasih atas partisipasinya!"
                />
                <ConfirmationDialog
                    open={openConfirmationEndLive}
                    handleClose={this.handleCloseConfirmationEndLive}
                    handleConfirm={this.handleEndLive}
                    title="Apakah kamu yakin untuk mengakhiri kelas?"
                    content="Kelas akan berakhir segera, aksi ini tidak dapat dibatalkan."
                    confirmText="Akhiri kelas"
                    closeText="Batalkan"
                />
                <ConfirmationDialog
                    open={openConfirmationGoLive}
                    handleClose={this.handleCloseConfirmationGoLive}
                    handleConfirm={this.handleGoLive}
                    title="Apakah kamu yakin untuk memulai kelas ini?"
                    content="Kelas akan dimulai segera, aksi ini tidak dapat dibatalkan."
                    confirmText="Mulai kelas"
                    closeText="Batalkan"
                />


                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth,
        activeData: state.classReducer.activeData
    }
}

export default connect(mapStateToProps)(Class);