import React from 'react';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, Hidden } from '@material-ui/core'
import Refund from '../../Dialog/Refund'
import AmountRefund from '../../Dialog/AmountRefund'
import isEmpty from '../../../../validation'
import moment from 'moment'
import Util from '../../../../util'
import store from '../../../../store'
import Axios from 'axios'
import swal from 'sweetalert'
import history from '../../../../history'
import _ from "lodash"
import DateRange from '../../../../reusable-components/Inputs/DateRange';

class TutorBalance extends React.Component {
    state = {
        openRefund: false,
        openAmountRefund: false,
        currentPage: 1,
        filesPerPage: 10,
        withdraws: [],
        inquiries: [],
        startDate: null,
        endDate: null
    }

    handleOpenAmountRefund = () => {
        this.setState({
            openAmountRefund: true
        })
    }

    handleCloseAmountRefund = () => {
        this.setState({
            openAmountRefund: false
        })
    }

    handleConfirmAmountRefund = amount => {
        const { auth } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VzuNHQ", auth.id)
        formData.append("NxpyRu", amount)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/awz92fLys2CdgA9G",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "LCSjcz69MVDRsfGg",
                    password: "K2h9XY9XCa73Y3mb"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "bZrsZk" && respond.data !== "aNGBHh") {
                this.getWithdraw();

                this.setState({
                    openAmountRefund: false,
                    openRefund: true
                })
            }

            if (respond.data === "aNGBHh") {

                swal("Error!", "Saldo tidak cukup", "error", {
                    timer: 3000,
                }).then((value) => {
                    history.push(this.props.location.pathname)

                    this.setState({
                        openAmountRefund: false
                    })
                });
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleOpenRefund = () => {
        this.setState({
            openRefund: true
        })
    }

    handleCloseRefund = () => {
        this.setState({
            openRefund: false
        })
    }

    handleChangePage = number => () => {
        this.setState({
            currentPage: Number(number)
        });
    }

    handleChangeDate = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        })
    }

    handleDeleteDate = e => {
        e.preventDefault();

        this.setState({
            startDate: null,
            endDate: null
        })
    }

    getWithdraw = () => {
        const { auth } = this.props;

        if (auth) {
            var formData = new FormData();
            formData.append("zqFQZt", 999);
            formData.append("CpZqyE", 0);
            formData.append("kRVGnP", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/EjCNZj68FYCZXvJa",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "LSjxXKFZ6CZfFL4v",
                        password: "khhEhZbD472bYZmL"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "KZkZyT" && respond.data !== "tUfgFR" && respond.data !== "SUzDrx") {
                        this.setState({
                            withdraws: respond.data
                        })
                    }
                }
            });
        }
    }

    getInquiries = () => {
        const { auth } = this.props;
        let inquiries = this.state.inquiries;

        var formData = new FormData();
        formData.append("kWTHLe", auth.id);
        formData.append("KzLwhe", 999);
        formData.append("CYcmMz", 0);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zjKVHjtyMW4hD5LP",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Yt7MYPX2sn72rvsA",
                    password: "Bbf9h6vmQYAxpBfc"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "BbcgeL") {
                const newInquiries = respond.data;

                if (!(_.isEqual(newInquiries, inquiries))) {
                    inquiries = [
                        ...newInquiries
                    ];
                }

                this.setState({
                    inquiries
                })
            }
        });
    }

    componentDidMount() {
        this.getWithdraw()
        this.getInquiries();
    }

    render() {
        const { openRefund, openAmountRefund, currentPage, filesPerPage, withdraws, inquiries, startDate, endDate } = this.state;
        const { auth } = this.props;
        const transactions = []

        withdraws.filter(item => item.is_completed === 1).map(withdraw => {
            transactions.push({
                id: withdraw.id,
                date: moment(moment(withdraw.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')),
                type: "Withdraw",
                username: "-",
                amount: `IDR ${!isEmpty(withdraw.amount) ? Util.numberWithCommas(withdraw.amount) : 0}`
            })
        })

        inquiries.filter(item => item.status_id === 2008383465).map(inquiry => {
            transactions.push({
                id: inquiry.id,
                date: moment(moment(inquiry.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')),
                type: inquiry.type.id === 2045482424 ? "Standard Question" : "Expert Question",
                username: inquiry.customer_user.name,
                amount: `IDR ${!isEmpty(inquiry.bid_price) ? Util.numberWithCommas(inquiry.bid_price) : 0}`
            })
        })


        const indexOfLastFile = currentPage * filesPerPage;
        const indexOfFirstFile = indexOfLastFile - filesPerPage;
        const currentTransactions = transactions
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .slice(indexOfFirstFile, indexOfLastFile)
            .filter(transaction => !isEmpty(startDate) && !isEmpty(endDate) ? moment(transaction.date).isBetween(moment(startDate), moment(endDate), null, []) : true);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(transactions.length / filesPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="tutor-balance">
                <div className="content ptb-80">
                    <div className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <p>Saldo saat ini</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={7} md={8} lg={9} container alignItems="center">
                                                <span style={{ fontWeight: 600, fontSize: 24, color: "#223F63", width: "100%", borderBottom: "1px black solid", lineHeight: 2 }}>IDR {!isEmpty(auth.balance) ? Util.numberWithCommas(auth.balance) : 0}</span>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={4} lg={3} container justify="flex-end">
                                                <button style={{ padding: "15px 20px", width: "100%" }} className={`btn btn-primary ${(isEmpty(this.props.auth.balance) || this.props.auth.balance <= 0) ? "disable-btn" : ""}`} disabled={isEmpty(this.props.auth.balance) || this.props.auth.balance <= 0} onClick={!isEmpty(this.props.auth.balance) && this.props.auth.balance > 0 ? this.handleOpenAmountRefund : () => { }}>MEMINTA PENARIKAN DANA</button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="table-transaction-container">
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div style={{ padding: "20px 40px" }}>
                                                <span style={{ fontWeight: 600, fontSize: 24, color: "#223F63", }}>Pencatatan Transaksi Saya</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center" justify="flex-end">
                                            <div style={{ padding: "20px 40px" }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={2} container alignItems="center" justify="space-between">
                                                        <p style={{ marginBottom: 0 }}>Sortir:</p>

                                                        <Hidden smUp>
                                                            {(!isEmpty(startDate) || !isEmpty(endDate)) &&
                                                                <a href="#" onClick={this.handleDeleteDate} style={{ textDecoration: "underline" }}>Hapus</a>
                                                            }
                                                        </Hidden>
                                                    </Grid>
                                                    <Grid item sm={(!isEmpty(startDate) || !isEmpty(endDate)) ? 8 : 10} container alignItems="center" justify="flex-end">
                                                        <DateRange
                                                            placeholderStart="Start Date"
                                                            placeholderEnd="Start End"
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            onChange={this.handleChangeDate}
                                                            enablePastDays
                                                        />
                                                    </Grid>
                                                    <Hidden only="xs">
                                                        {(!isEmpty(startDate) || !isEmpty(endDate)) &&
                                                            <Grid item sm={2} container alignItems="center">
                                                                <a href="#" onClick={this.handleDeleteDate} style={{ textDecoration: "underline" }}>Hapus</a>.
                                                        </Grid>
                                                        }
                                                    </Hidden>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper} elevation={0} square className="table-container">
                                                <Table style={{ minWidth: 800 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="custom-head-table-cell">Tanggal</TableCell>
                                                            <TableCell className="custom-head-table-cell">Jenis</TableCell>
                                                            <TableCell className="custom-head-table-cell">Nama User</TableCell>
                                                            <TableCell className="custom-head-table-cell">Jumlah</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {currentTransactions.map((row) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell className="custom-row-table-cell">{moment(row.date).format("DD-MM-YYYY")}</TableCell>
                                                                <TableCell className="custom-row-table-cell">{row.type}</TableCell>
                                                                <TableCell className="custom-row-table-cell">{row.username}</TableCell>
                                                                <TableCell className="custom-row-table-cell">{row.amount}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        {currentTransactions.length <= 0 &&
                                                            <TableRow >
                                                                <TableCell className="custom-row-table-cell" align="center" colSpan={12}>Data tidak ditemukan</TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="pagination-area">
                                                {currentTransactions.length > 0 &&
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item"><a className="page-link" onClick={currentPage - 1 > 0 ? this.handleChangePage(currentPage - 1) : () => { }}>Prev</a></li>

                                                        {pageNumbers.map(number => (
                                                            <li
                                                                key={number}
                                                                id={number}
                                                                className={`page-item ${number === currentPage ? "active" : ""}`}
                                                                onClick={this.handleChangePage(number)}
                                                            >
                                                                <a className="page-link" href="#">{number}</a>
                                                            </li>
                                                        ))}

                                                        <li className="page-item"><a className="page-link" onClick={currentPage + 1 <= pageNumbers.length ? this.handleChangePage(currentPage + 1) : () => { }}>Next</a></li>
                                                    </ul>
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <AmountRefund open={openAmountRefund} handleClose={this.handleCloseAmountRefund} handleConfirm={this.handleConfirmAmountRefund} />
                <Refund open={openRefund} handleClose={this.handleCloseRefund} handleConfirm={this.handleCloseRefund} />

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/assets/images/abstract/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div>
        )
    }
}

export default TutorBalance