import React from 'react';
import * as Icon from 'react-feather';
import Select from 'react-select/creatable'
import { DropzoneArea } from 'material-ui-dropzone';
import { Chip, Grid } from '@material-ui/core';
import isEmpty from '../../validation';
import history from '../../history';
import Axios from 'axios';
import crypto from 'crypto';
import jwt from "jsonwebtoken";
import setAuthToken from '../../validation/setAuthToken';
import AuthActions from '../../actions/AuthActions';
import store from '../../store';
import jwt_decode from "jwt-decode";
import VerificationInvalid from './Dialog/VerificationInvalid';

class EmailVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationKeyNotFound: false,
            verificationKeyInvalid: false
        };
    }

    getUserDetail = uid => {
        var formData = new FormData();
        formData.append("UqADvv", uid);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WFVgZQZ6sd73R5HF",
                    password: "ef5hKtveCEpWL69q"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "VhUyMY") {
                    const payload = {
                        ...respond.data,
                    }; // Create JWT Payload

                    if (respond.data.is_verified) {
                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: 3600 }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        history.push("/dashboard/profile")
                    }
                }
            }
        });
    }


    componentWillMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        if (!isEmpty(this.props.match.params)) {
            var formData = new FormData();
            formData.append("EhUMym", this.props.match.params.id)
            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/BXUuh8TDb44Eb3Sb",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "k726sJfwF4RbtftZ",
                        password: "T5vf96yaF4Msh893"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "rZaHzc" && respond.data !== "NWazss") {
                        this.getUserDetail(respond.data)
                    }
                }

                this.setState({
                    verificationKeyNotFound: isEmpty(respond.data) || respond.data === "rZaHzc",
                    verificationKeyInvalid: isEmpty(respond.data) || respond.data === "NWazss" || respond.data.is_verified === 0,
                })

                store.dispatch({
                    type: "SET_LOADING",
                    payload: false
                });
            });
        }
    }

    handleClose = () => {
        history.push("/")
    }

    render() {
        const { verificationKeyNotFound, verificationKeyInvalid } = this.state;

        return (
            <div className="sign-up ptb-80">
                <div className="container">

                    <div className="row justify-content-md-center">
                        {(verificationKeyInvalid || verificationKeyNotFound) &&
                            <VerificationInvalid open={verificationKeyInvalid || verificationKeyNotFound} verificationKeyInvalid={verificationKeyInvalid} verificationKeyNotFound={verificationKeyNotFound} handleClose={this.handleClose} errorMessage="This activation link is not valid" />
                        }
                    </div>

                    <div className="shape1">
                        <img src="/assets/images/abstract/shape1.png" alt="shape" />
                    </div>
                    <div className="shape5">
                        <img src="/assets/images/abstract/shape5.png" alt="shape" />
                    </div>
                    <div className="shape6 rotateme">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailVerification