import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import isEmpty from '../../validation';


class Select extends React.Component {
    handleCreateData = string => {
        if (this.props.onCreateOption) {
            this.props.onCreateOption(string);
        }
    };

    handleChange = value => {
        if (this.props.onChange) {
            if (this.props.isMulti) {
                this.props.onChange(!isEmpty(value) ? value.map(item => item.value) : null);
            } else {
                this.props.onChange(!isEmpty(value) ? value.value : null);
            }
        }
    }

    render() {
        const { options, value, error, isCreateable, placeholder, isMulti, isClearable, isDisabled, noOptionsMessage, limitValue } = this.props;

        const CustomSelect = isCreateable ? CreatableSelect : ReactSelect;

        return (
            <CustomSelect
                options={isMulti && !isEmpty(limitValue) ? value.length !== limitValue ? options : [] : options}
                placeholder={placeholder}
                value={isMulti ? options.filter(item => value.some(datum => datum == item.value)) : options.filter(item => item.value == value)}
                onChange={this.handleChange}
                onCreateOption={this.handleCreateData}
                menuPortalTarget={document.getElementById("root")}
                styles={{
                    container: (styles) => ({
                        ...styles,
                        width: "100%"
                    }),
                    control: (styles, { isDisabled, isFocused, isSelected }) => ({
                        ...styles,
                        transition: "0.5s",
                        background: isFocused ? "#FBFBFB" : "transparent",
                        height: 35,
                        border: `1px ${error ? "#dc3545" : isFocused ? "#05B1F1" : "rgba(189, 204, 215, 0.5)"} solid`,
                        boxShadow: isFocused && error ? "0 0 0 0.2rem rgba(220,53,69,.25)" : "unset",
                        borderRadius: 0,
                        minHeight: 35,
                        "&:hover": {
                            cursor: "text",
                            border: `1px ${error ? "#dc3545" : isFocused ? "#05B1F1" : "rgba(189, 204, 215, 0.5)"} solid`,
                        },
                    }),
                    valueContainer: (styles) => ({
                        ...styles,
                        padding: "0px 19px",
                        position: "unset"
                    }),
                    dropdownIndicator: (styles) => ({
                        ...styles,
                        padding: "5px 8px"
                    }),
                    menu: (styles) => ({
                        ...styles,
                        backgroundColor: "#FBFBFB",
                        borderRadius: 0,
                        overflow: "hidden",
                        zIndex: 999
                    }),
                    menuList: (styles) => ({
                        ...styles,
                        padding: 0,
                    }),
                    menuPortal: (styles) => ({
                        ...styles,
                        zIndex: 1500,
                    }),
                    option: (styles, { isDisabled, isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? "#05B1F1" : isFocused ? "#BDCCD7" : "transparent",
                        color: (isSelected || isFocused) ? "#ffffff" : "#000000",
                        ':active': {
                            ...styles[':active'],
                            backgroundColor: isSelected ? "#05B1F1" : "#A5BDCA"
                        },
                    }),
                }}
                isMulti={isMulti}
                menuPlacement="auto"
                isClearable={isClearable}
                isDisabled={isDisabled}
                noOptionsMessage={noOptionsMessage}
            />
        )
    }
}

export default Select