class Util {
    static numberWithCommas = str => {
        return String(str).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    static numberWithoutCommas = str => {
        if (typeof str === "number") {
            return str;
        }
        return parseInt(str.split(".").join("")).toLocaleString('fullwide', { useGrouping: false });
    }

    static currencyFormat(value) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value)
    }
}

export default Util;
