import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, DialogTitle } from '@material-ui/core';
import Util from '../../../util';
import isEmpty from '../../../validation';
class BalanceInsufficient extends React.Component {
    handleRetry = () => {
    }

    render() {
        const { open, handleClose, handleTopUpBalance } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle
                    style={{
                        background: "linear-gradient(135deg, #223F63 0%, #05B1F1 100%)",
                        textAlign: "center"
                    }}
                    disableTypography
                >
                    <h4 style={{ color: "#ffffff", minHeight: 26.4, fontSize: 20 }}>Saldo tidak cukup</h4>
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
                    <button className="btn btn-danger" onClick={handleTopUpBalance}>Top Up Saldo</button>
                    <div style={{ padding: 8 }}></div>
                    <button className="btn btn-primary" onClick={handleClose}>Batalkan</button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default BalanceInsufficient;