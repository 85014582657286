import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Grid from "@material-ui/core/Grid";
import SetPrice from '../../Dialog/SetPrice';
import TopUpBalance from '../../Dialog/TopUpBalance';
import NotFindTutor from '../../Dialog/NotFindTutor';
import Report from '../../Dialog/Report';
import GetInTouch from '../../Dialog/GetInTouch';
import isEmpty from '../../../../validation';
import * as Icon from "react-feather";
import store from '../../../../store';
import Axios from 'axios';
import draftjsToMarkdown from 'draftjs-to-markdown';
import SuccessCreateInquiry from '../../Dialog/SuccessCreateInquiry';
import history from "../../../../history";
import AuthActions from '../../../../actions/AuthActions';
import setAuthToken from '../../../../validation/setAuthToken';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import Select from '../../../../reusable-components/Inputs/Select';
import moment from 'moment';
import FindTutor from '../../Dialog/FindTutor';
import { DropzoneArea } from 'material-ui-dropzone';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import PaymentPending from '../../Dialog/PaymentPending';

class AskTutor extends React.Component {
    state = {
        editorState: EditorState.createEmpty(),
        openSetPrice: false,
        openSuccessCreateInquiry: false,
        openTopUpBalance: false,
        openFindTutor: false,
        activeTutorId: null,
        openNotFindTutor: false,
        openReport: false,
        openGetInTouch: false,
        retry: false,
        successResendEmail: false,
        subjects: [],
        subject: null,
        subjectInvalid: false,
        time: "",
        unit: "minute",
        showError: false,
        balanceInsufficient: false,
        topUpBalanceSuccess: false,
        openBeginSearchDialog: false,
        searchTutorCount: 0,
        files: []
    }

    componentWillMount() {
        clearTimeout(this.intervalSearchTutor)
    }

    componentDidMount() {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kkLdrk", 9999)
        formData.append("wqWWWe", 0)
        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/X7asVxHWTYTEeGmV",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "RRq6sjw9j3AWrbYf",
                    password: "zm8YBRvkMVkTQawm"
                }
            }
        ).then(respond => {

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "CktZKk" && respond.data !== "JrasLA") {
                this.setState({
                    subjects: respond.data
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    onEditorChange = editorState => {
        this.setState({
            editorState,
        });
    }

    handleCloseSetPrice = () => {
        this.setState({
            openSetPrice: false,
            retry: false
        })
    }

    handleOpenSetPrice = () => {
        const { editorState, time, subject } = this.state;

        const rawContent = draftjsToMarkdown(convertToRaw(editorState.getCurrentContent()));

        this.setState({
            showError: isEmpty(time) || isEmpty(subject) || isEmpty(rawContent)
        }, () => {
            if (!this.state.showError) {
                this.setState({
                    openSetPrice: true
                })
            }
        })
    }

    handleOpenTopUpBalance = () => {
        this.setState({
            openSetPrice: false,
            openTopUpBalance: true,
            balanceInsufficient: false
        })
    }

    handleTopUpBalance = amount => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VwxCYt", this.props.auth.id);
        formData.append("EzEZnB", amount);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/UqbHAbH3En55aHam",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p5Hym9fAguNh8FxK",
                    password: "TWEDHGZn7Fmx3wfW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ERR") {
                const payload = {
                    ...this.props.auth,
                    balance: this.props.auth.balance + amount,
                }; // Create JWT Payload

                const _this = this;

                window.snap.pay(respond.data.token, {
                    onSuccess: async function (result) {
                        /* You may add your own implementation here */
                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            {
                                ...oldDecoded.exp
                            }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload
                        })

                        _this.setState({
                            topUpBalanceSuccess: true,
                            openTopUpBalance: false,
                            openSetPrice: true,
                        })
                    },
                    onPending: function (result) {
                        _this.setState({
                            openPaymentPending: true,
                        })
                    },
                    onError: function (result) {
                        _this.setState({
                            openTopUpBalance: true,
                        })
                    },
                    onClose: function () {
                    }
                })
            } else {
                this.setState({
                    openTopUpBalance: true,
                })
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });

        this.setState({
            openTopUpBalance: false,
        })
    }

    handleOpen = () => {
        this.setState({
            openTopUpBalance: true
        })
    }

    handleClose = () => {
        this.setState({
            openTopUpBalance: false,
            openSetPrice: true,
            retry: false
        })
    }

    handleCloseSearchTutor = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: false
        });

        this.setState({
            recentlyCreatedInquiryId: this.state.inquiryIdActive,
            // inquiryIdActive: null,
            openBeginSearchDialog: false,
        })

        clearInterval(this.intervalSearchTutor)
    }

    handleRetrySearchTutor = () => {
        const { searchTutorCount } = this.state;

        if (searchTutorCount < 2) {
            this.setState({
                openNotFindTutor: false,
            });

            this.handleOpenSetPrice()
        } else {
            this.setState({
                openNotFindTutor: false,
                openReport: true
            })
        }
    }

    handleCloseNotFindTutor = () => {
        const { inquiryIdActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryIdActive);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getBalance();
                this.setState({
                    openNotFindTutor: false,
                    retry: false,
                    inquiryIdActive: null,
                    recentlyCreatedInquiryId: null
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleConfirmNotFindTutor = () => {
        this.setState({
            openNotFindTutor: false,
            openReport: !this.state.retry,
            openSetPrice: this.state.retry
        })
    }

    handleCloseFindTutor = () => {
        history.push(`/dashboard/chat?question_id=${this.state.activeTutorId}`)

        this.setState({
            openFindTutor: false,
            activeTutorId: null
        })
    }

    handleCloseReport = () => {
        const { inquiryIdActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryIdActive);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getBalance();
                this.setState({
                    openReport: false,
                    retry: false
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleConfirmReport = () => {
        const { inquiryIdActive } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("bvWrdC", inquiryIdActive);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Lcnyrz877GY24qyx",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VUYtyuc9nyCWfBhR",
                    password: "XqtKNkxGN3WRaUt2"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "rMJZZb") {
                this.getBalance();
                this.setState({
                    openReport: false,
                    openGetInTouch: true
                })

                clearInterval(this.intervalSearchTutor)
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleCloseGetInTouch = () => {
        this.setState({
            openGetInTouch: false,
            retry: false
        })
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleSelectChange = name => value => {
        const { time } = this.state;

        this.setState({
            [name]: value
        })

        if (name === "unit") {
            this.setState({
                time: value === "day" ? time <= 3 ? time : 3 : value === "hour" ? time <= 24 ? time : 24 : time <= 60 ? time : 60
            })
        }
    }

    handleInputChange = name => e => {
        const re = /^[0-9\b]+$/;
        const value = e.target.value;
        const unit = String(this.state.unit).toLowerCase();

        if (value === '' || re.test(value)) {
            this.setState({
                [name]: unit === "day" ? value <= 3 ? value : 3 : unit === "hour" ? value <= 24 ? value : 24 : value <= 60 ? value : 60
            })
        }
    }

    handleCloseSuccessCreateInquiry = () => {
        history.push("/dashboard");

        this.setState({
            openSuccessCreateInquiry: false
        })
    }

    handleCloseBeginSearchDialog = () => {
        this.setState({
            openBeginSearchDialog: false
        })
    }

    getBalance = () => {
        const { auth } = this.props;

        if (auth) {
            var formData = new FormData();
            formData.append("UqADvv", auth.id);

            Axios.post(
                "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/zZXxhhpJ477qYStz",
                formData,
                {
                    headers: {
                        'Content-Type': "multiple/form-data"
                    },
                    auth: {
                        username: "WFVgZQZ6sd73R5HF",
                        password: "ef5hKtveCEpWL69q"
                    }
                }
            ).then(respond => {
                if (!isEmpty(respond.data) && respond.data !== "ERR") {
                    if (respond.data !== "VhUyMY") {

                        const payload = {
                            ...this.props.auth,
                            ...respond.data,
                        }; // Create JWT Payload

                        delete payload["exp"];

                        const oldDecoded = jwt_decode(localStorage.jwtToken);

                        const token = jwt.sign(
                            payload,
                            "SECRET",
                            { expiresIn: oldDecoded.exp }
                        );

                        localStorage.setItem("jwtToken", token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);

                        store.dispatch(AuthActions.setCurrentUser(decoded));

                        // Set token to Auth header
                        setAuthToken(token);

                        store.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: respond.data
                        })
                    }
                }
            });
        }
    }

    handleCreateInquiry = rate => {
        const { subject, files, time, unit, editorState } = this.state;
        const rawContent = editorState.getCurrentContent().getPlainText();

        var formData = new FormData();
        formData.append("YFqcas", this.props.auth.id);
        formData.append("sfPxKm", subject);
        formData.append("KPhWwa", rawContent);
        formData.append("rqkBGJ", parseInt(rate));
        formData.append("cVfmZS", String(unit).toLowerCase() === "day" ? parseInt(time) : 0);
        formData.append("zQmgSj", String(unit).toLowerCase() === "hour" ? parseInt(time) : 0);
        formData.append("YkHGeP", String(unit).toLowerCase() === "minute" ? parseInt(time) : 0);

        formData.append("VdanVH", files[0]);
        formData.append("PDzpaH", files[1]);
        formData.append("wZBfhY", files[2]);
        formData.append("qVUThn", files[3]);
        formData.append("NxDDeS", files[4]);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/QYt83wwa6VCbe58m",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "mU86eCCArUuNdcRA",
                    password: "exbvpnbcdc6snLu4"
                }
            }
        ).then(respond => {
            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "ZKXwUT" && respond.data !== "eaYQvf" && respond.data !== "YfWwDx" && respond.data !== "reWpEg") {
                this.getBalance();
                this.setState({
                    openSuccessCreateInquiry: true
                })
            }

            this.setState({
                openSetPrice: respond.data === "YfWwDx",
                subjectInvalid: respond.data === "eaYQvf",
                showError: respond.data === "eaYQvf",
                balanceInsufficient: respond.data === "YfWwDx",
                topUpBalanceSuccess: false,
            })
        });
    }

    handleSearchTutor = () => {
        const { inquiryIdActive } = this.state;

        this.intervalSearchTutor = setInterval(() => {
            if (this.props.auth) {
                var formData = new FormData();
                formData.append("nRLkxq", this.props.auth.id);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/vDJE3ykpMpApqw9k",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "DR7LpCF5GN26wVhn",
                            password: "V4UwWvftU8fTRSCZ"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SpLtDH") {
                        respond.data.filter(item => item.id === parseInt(inquiryIdActive)).forEach(datum => {
                            if (datum.status_id === 2083370574) {
                                this.handleCloseSearchTutor();
                                this.setState({
                                    openFindTutor: true,
                                    activeTutorId: datum._id
                                })
                            }

                            if (moment().diff(moment(moment(datum.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") >= 5) {
                                this.handleCloseSearchTutor();

                                this.setState({
                                    openNotFindTutor: true,
                                    searchTutorCount: this.state.searchTutorCount + 1
                                })
                            }
                        })
                    }
                });
            }
        }, 10000)
    }

    retryCreateInquiry = () => {
        const { inquiryIdActive } = this.state;

        var formData = new FormData();
        formData.append("XavcBP", inquiryIdActive);

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Nr88vyJL7pBdSq65",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "Uv7ypRqrLAqh7g8H",
                    password: "V4GUZxQDp6hg4ALG"
                }
            }
        ).then(respond => {
            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });

            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "snYuJQ") {
                this.setState({
                    inquiryIdActive: respond.data,
                    openBeginSearchDialog: true,
                    openSetPrice: false
                }, () => {
                    store.dispatch({
                        type: "SET_LOADING",
                        payload: true
                    });

                    this.handleSearchTutor()
                })
            }
        });
    }

    handleSendInquiry = rate => {
        const { searchTutorCount } = this.state;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        // if (searchTutorCount === 0) {
        this.handleCreateInquiry(rate);
        // } else {
        //     this.retryCreateInquiry();
        // }
    }

    handleUpload = files => {
        this.setState({
            files: files
        });
    }

    handleDelete = currentIndex => () => {
        this.setState({
            files: this.state.files.filter((item, index) => index !== currentIndex)
        })
    }

    render() {
        const {
            editorState,
            openTopUpBalance,
            openSetPrice,
            openSuccessCreateInquiry,
            openFindTutor,
            openNotFindTutor,
            openReport,
            openGetInTouch,
            successResendEmail,
            subjects,
            showError,
            files,
            subject,
            subjectInvalid,
            time,
            unit,
            balanceInsufficient,
            topUpBalanceSuccess,
            openBeginSearchDialog,
            openPaymentPending
        } = this.state;

        const { auth } = this.props;
        const { is_verified } = auth;

        const units = [
            { value: 'minute', label: 'Menit' },
            { value: 'hour', label: 'Jam' },
            { value: 'day', label: 'Hari' },
        ];

        return (
            <div className="container">
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <h2>Tanya Tutor</h2>
                            </div>
                        </div>
                    </div>

                    <div className="shape1"><img src="/assets/images/abstract/shape1.png" alt="shape" /></div>
                    <div className="shape2 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                    <div className="shape3"><img src="/assets/images/abstract/shape3.svg" alt="shape" /></div>
                    <div className="shape4"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape5"><img src="/assets/images/abstract/shape5.png" alt="shape" /></div>
                    <div className="shape6 rotateme"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape7"><img src="/assets/images/abstract/shape4.svg" alt="shape" /></div>
                    <div className="shape8 rotateme"><img src="/assets/images/abstract/shape2.svg" alt="shape" /></div>
                </div>
                <div className="content ptb-80">
                    <div className="container">
                        {successResendEmail &&
                            <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                    <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                </span>
                            </div>
                        }
                        {!successResendEmail && isEmpty(is_verified) &&
                            <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "30%", maxWidth: 50 }}>
                                    <Icon.Info />
                                </div>
                                <div style={{ height: 10 }}></div>
                                <span>
                                    Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                    <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                </span>
                            </div>
                        }

                        <div className="row">
                            <div className="col-12">
                                <h4 style={{ marginBottom: 15 }}>Tanyakan kepada Tutor kami:</h4>
                            </div>
                            <div className="col-12">
                                <div style={{ border: "1px rgba(189, 204, 215, 0.8) solid", padding: 15, background: "#FFFFFF" }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Editor
                                                toolbarHidden
                                                placeholder="Tuliskan pertanyaanmu secara detail untuk mempermudah Tutor kami menjawab"
                                                editorState={editorState}
                                                wrapperClassName={`wrapper-editor ${showError && isEmpty(editorState.getCurrentContent().getPlainText()) ? "text-editor-invalid" : ""}`}
                                                editorClassName={`text-area-editor ${showError && isEmpty(editorState.getCurrentContent().getPlainText()) ? "text-editor-invalid" : ""}`}
                                                toolbarClassName="toolbar-editor"
                                                onEditorStateChange={this.onEditorChange}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history']
                                                }}
                                            />
                                            {/* <div id="editor-equation"></div> */}
                                            <div className="invalid-feedback" style={{ display: showError && isEmpty(editorState.getCurrentContent().getPlainText()) && "block" }}>
                                                Cantumkan konten yang valid.
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div style={{ marginTop: 10 }}>
                                                <label>Files: </label>
                                                <DropzoneArea
                                                    dropzoneClass={`custom-dropzone ${files.length >= 5 ? "hide" : ""}`}
                                                    dropzoneText="Unduh File/dokumen disini"
                                                    filesLimit={5}
                                                    initialFiles={files}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewText=""
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ variant: "outlined", style: { color: "#223F63", borderColor: "#223F63" } }}
                                                    onChange={this.handleUpload.bind(this)}
                                                    showAlerts={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div style={{ marginTop: 30 }}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={12} sm={12} md={9}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <div className={`select-box ${showError && isEmpty(subject) || subjectInvalid ? "is-invalid" : ""}`}>
                                                                    <Select
                                                                        options={subjects.map(subject => ({ value: subject.id, label: subject.name }))}
                                                                        placeholder="Subject"
                                                                        noOptionsMessage={() => {
                                                                            return 'Tidak ada pilihan'
                                                                        }}
                                                                        value={subject}
                                                                        onChange={this.handleSelectChange("subject")}
                                                                        error={showError && isEmpty(subject) || subjectInvalid}
                                                                    />
                                                                </div>
                                                                <div className="invalid-feedback" style={{ display: showError && (isEmpty(subject) || subjectInvalid) && "block" }}>
                                                                    Cantumkan subject yang valid.
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <div className="form-group" style={{ marginBottom: 0, width: "100%" }}>
                                                                            <input
                                                                                type="text"
                                                                                name="time"
                                                                                placeholder="Waktu"
                                                                                className={`form-control ${(showError && isEmpty(time)) ? "is-invalid" : ""}`}
                                                                                value={time}
                                                                                onChange={this.handleInputChange("time")}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback" style={{ display: showError && isEmpty(time) && "block" }}>
                                                                                Cantumkan waktu yang valid.
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Select
                                                                            options={units}
                                                                            placeholder="Unit"
                                                                            noOptionsMessage={() => {
                                                                                return 'Tidak ada pilihan'
                                                                            }}
                                                                            value={unit}
                                                                            onChange={this.handleSelectChange("unit")}
                                                                            error={showError && isEmpty(unit)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} container justify="flex-end">
                                                        <button onClick={this.handleOpenSetPrice} className={`btn btn-primary ${isEmpty(is_verified) ? "disable-btn" : ""}`} disabled={isEmpty(is_verified)}>KIRIM</button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ marginTop: 10 }}>
                                <span style={{ fontWeight: 500 }}>
                                    <i>
                                        Butuh konsultasi skripsi/tesis/disertasi? Kontak
                                    </i>
                                </span>
                                <i>
                                    <a className="nav-link" style={{ display: "unset", padding: 5 }} href="/dashboard/ask-expert-tutor"> expert tutor</a>
                                </i>
                                <span style={{ fontWeight: 500 }}>
                                    <i>
                                        kami
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <SetPrice
                    open={openSetPrice}
                    topUpBalanceSuccess={topUpBalanceSuccess}
                    balanceInsufficient={balanceInsufficient}
                    handleClose={this.handleCloseSetPrice}
                    handleSendInquiry={this.handleSendInquiry}
                    handleOpenTopUpBalance={this.handleOpenTopUpBalance}
                />
                <TopUpBalance open={openTopUpBalance} handleClose={this.handleClose} handleTopUpBalance={this.handleTopUpBalance} />
                <SuccessCreateInquiry open={openSuccessCreateInquiry} handleClose={this.handleCloseSuccessCreateInquiry} />

                <FindTutor open={openFindTutor} handleClose={this.handleCloseFindTutor} />
                <NotFindTutor open={openNotFindTutor} handleClose={this.handleCloseNotFindTutor} handleConfirm={this.handleRetrySearchTutor} />
                <Report open={openReport} handleClose={this.handleCloseReport} handleConfirm={this.handleConfirmReport} />
                <GetInTouch open={openGetInTouch} handleClose={this.handleCloseGetInTouch} />
                <PaymentPending open={openPaymentPending} handleClose={() => this.setState({ openPaymentPending: false })} />
            </div>
        )
    }
}

export default AskTutor