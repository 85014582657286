import React from 'react';
import * as Icon from "react-feather";
import history from '../../history';
import { Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener, Grid, FormControlLabel, Switch, FormGroup, Checkbox, Slide, Collapse, IconButton } from '@material-ui/core';
import AuthActions from '../../actions/AuthActions';
import store from '../../store';
import { connect } from "react-redux";
import isEmpty from '../../validation';
import Axios from 'axios';
import Util from '../../util';
import moment from 'moment';
import setAuthToken from '../../validation/setAuthToken';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import 'moment/locale/id'


class Header extends React.Component {
    state = {
        collapsed: true,
        open: false,
        openProfileMenu: false,
        openComplain: false,
        openNotifQuestion: false,
        menuMobileProfile: false,
        openNotifQuestionMobile: false,
        openNotifMobile: false,
        menuTutorMobile: false
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClickProfile = () => {
        this.setState({ openProfileMenu: !this.state.openProfileMenu });
    };

    handleCloseProfile = () => event => {
        if (this.anchorElProfile.contains(event.target)) {
            return;
        }

        this.setState({ openProfileMenu: false });
    }

    handleClose = () => event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };

    handleCloseProfile = () => event => {
        if (this.anchorElProfile.contains(event.target)) {
            return;
        }

        this.setState({ openProfileMenu: false });
    }

    handleClickNotification = notification => event => {
        if (this.props.handleClickNotification) {
            this.props.handleClickNotification(notification);
        }

        if (this.anchorElQuestion.contains(event.target)) {
            return;
        }

        this.setState({ openNotifQuestion: false });
    }

    handleClickNotifMobile = event => {
        this.setState({ openNotifMobile: !this.state.openNotifMobile })
    }

    handleClickNotifQuestionMobile = event => {
        this.setState({ openNotifQuestionMobile: !this.state.openNotifQuestionMobile });
    }

    handleClickNotifQuestion = event => {
        this.setState({ openNotifQuestion: !this.state.openNotifQuestion });
    }

    handleChangeFilter = type => event => {
        const { auth } = this.props;

        var formData = new FormData();
        formData.append("byyGbc", auth.id);
        if (type === "expert") {
            formData.append("bZjwrT", event.target.checked ? 1 : 0);
        } else if (type === "standard") {
            formData.append("kUjwpc", event.target.checked ? 1 : 0);
        }

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/fyTp9eLM7tabBN7K",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "aDcJD8JEaPuEaA6c",
                    password: "TN3VWbM66LHJzL3b"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR") {
                if (respond.data !== "aKrABp" && respond.data !== "NAKeVu" && respond.data !== "yeQhjr" && respond.data !== "dgMZAt") {
                    const updatedData = {};

                    respond.data.forEach(data => {
                        updatedData[data[0]] = data[1]
                    })

                    const payload = {
                        ...this.props.auth,
                        ...updatedData,
                    }; // Create JWT Payload

                    delete payload["exp"];

                    const oldDecoded = jwt_decode(localStorage.jwtToken);

                    const token = jwt.sign(
                        payload,
                        "SECRET",
                        { expiresIn: oldDecoded.exp }
                    );

                    localStorage.setItem("jwtToken", token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);

                    store.dispatch(AuthActions.setCurrentUser(decoded));

                    // Set token to Auth header
                    setAuthToken(token);

                    store.dispatch({
                        type: "UPDATE_PROFILE",
                        payload: updatedData
                    })
                }
            }
        });
    }

    handleLogOut = () => {
        const { auth } = this.props;

        if (auth) {
            store.dispatch(AuthActions.logoutUser());
        }

        history.push("/");
    }

    numberWithCommas = str => {
        return String(str).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    unreadUserNotificationFilter = inquiry => {
        const { messages } = this.props;

        let result = false;

        const last_read_customer_user = moment(moment(inquiry.last_read_customer_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
        const last_read_tutor_user = moment(moment(inquiry.last_read_tutor_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));

        let unit = !isEmpty(inquiry.expected_duration_day) ? "days" : !isEmpty(inquiry.expected_duration_hour) ? "hours" : !isEmpty(inquiry.expected_duration_minute) ? "minutes" : "months";
        let time = !isEmpty(inquiry.expected_duration_day) ? inquiry.expected_duration_day : !isEmpty(inquiry.expected_duration_hour) ? inquiry.expected_duration_hour : !isEmpty(inquiry.expected_duration_minute) ? inquiry.expected_duration_minute : inquiry.expected_duration_month;

        const isOutdated = moment().diff(moment(moment(inquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time

        const modified_at = moment(moment(inquiry.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));


        if (inquiry.status_id !== 2070045802) {
            if (inquiry.status_id === 2083370574 && isOutdated && isEmpty(inquiry.rate)) {
                if (inquiry.last_read_customer_user === null ?
                    inquiry.last_read_tutor_user !== null :
                    last_read_tutor_user.isBefore(modified_at, "second") &&
                    last_read_customer_user.isBefore(modified_at, "second")
                ) {
                    result = true;
                }
            } else if (
                (inquiry.type_id === 2045482424 && inquiry.status_id === 2083370574 && isEmpty(inquiry.rate)) ||
                inquiry.status_id === 2096469076 ||
                inquiry.status_id === 2061809634 ||
                inquiry.status_id === 2079997237 ||
                inquiry.status_id === 2076844205 ||
                inquiry.status_id === 2059677011 ||
                inquiry.status_id === 2005449352 ||
                inquiry.status_id === 2080985413
            ) {
                if (inquiry.last_read_customer_user === null ?
                    inquiry.last_read_tutor_user !== null :
                    last_read_tutor_user.isBefore(modified_at, "second") &&
                    last_read_customer_user.isBefore(modified_at, "second")
                ) {
                    result = true;
                }
            }
        } else if (inquiry.status_id === 2070045802) {
            if (inquiry.last_read_customer_user === null) {
                if (inquiry.type_id === 2045482424) {
                    if (moment().diff(moment(moment(inquiry.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 5) {
                        result = true;
                    }
                } else if (moment().diff(moment(moment(inquiry.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 60) {
                    result = true;
                } else {
                    result = true;
                }
            }
        }

        return result;
    }

    unreadTutorNotificationFilter = inquiry => {
        const { messages } = this.props;

        let result = false;

        const last_read_tutor_user = moment(moment(inquiry.last_read_tutor_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
        const last_read_customer_user = moment(moment(inquiry.last_read_customer_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
        const modified_at = moment(moment(inquiry.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));

        let unit = !isEmpty(inquiry.expected_duration_day) ? "days" : !isEmpty(inquiry.expected_duration_hour) ? "hours" : !isEmpty(inquiry.expected_duration_minute) ? "minutes" : "months";
        let time = !isEmpty(inquiry.expected_duration_day) ? inquiry.expected_duration_day : !isEmpty(inquiry.expected_duration_hour) ? inquiry.expected_duration_hour : !isEmpty(inquiry.expected_duration_minute) ? inquiry.expected_duration_minute : inquiry.expected_duration_month;

        const isOutdated = moment().diff(moment(moment(inquiry.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time

        if (!inquiry.classNotif) {
            if (inquiry.status_id === 2083370574 && isOutdated) {
                if (
                    inquiry.last_read_tutor_user === null ?
                        inquiry.last_read_customer_user !== null :
                        last_read_tutor_user.isBefore(modified_at, "second") &&
                        (inquiry.last_read_customer_user !== null ?
                            last_read_customer_user.isBefore(modified_at, "second") : true)
                ) {
                    result = true;
                }
            } else if (
                (inquiry.type_id !== 2045482424 && inquiry.status_id === 2083370574) ||
                inquiry.status_id === 2083370574 ||
                inquiry.status_id === 2038862711 ||
                inquiry.status_id === 2063870995 ||
                inquiry.status_id === 2034577039 ||
                inquiry.status_id === 2077351391 ||
                inquiry.status_id === 2044457826 ||
                inquiry.status_id === 2098716420 ||
                inquiry.status_id === 2008383465
            ) {
                if (
                    inquiry.last_read_tutor_user === null ?
                        inquiry.last_read_customer_user !== null :
                        last_read_tutor_user.isBefore(modified_at, "second") &&
                        last_read_customer_user.isBefore(modified_at, "second")
                ) {
                    result = true;
                }
            }
        } else {
            result = true;
        }

        return result;
    }

    handleMarkAsNotifyUser = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("jGRDfN", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/hZy7CpZN5Whnh92b",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "VB8SQfWMqAD3pJVT",
                    password: "d27R8bep2WFejtNQ"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "LtSBHB") {
                if (this.props.removeInquiryFromNotification) {
                    this.props.removeInquiryFromNotification(inquiryId)
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleMarkAsReadUser = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("VHcEjb", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/VH7qbM9wRjbDpGRd",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "EuWd5mX86rEYTvdx",
                    password: "dsSFJm6DNmRVQDkW"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "SkSkuT") {
                if (this.props.removeInquiryFromNotification) {
                    this.props.removeInquiryFromNotification(inquiryId)
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleMarkAsNotifyTutor = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("FDFmtR", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/VPTDfSEeKuSGj54n",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "p8H4f7RkefHSazEM",
                    password: "EXFEVysPc9cXKeXX"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "vCzVak") {
                if (this.props.removeInquiryFromNotification) {
                    this.props.removeInquiryFromNotification(inquiryId)
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleMarkAsReadTutor = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("kEeQBf", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Eqkw6fQ9WLGNEyDb",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "WnJMSgcjLxY9H3AW",
                    password: "gss6xQ9hEASBZ3YT"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "RtjhyW") {
                if (this.props.removeInquiryFromNotification) {
                    this.props.removeInquiryFromNotification(inquiryId)
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleMarkAsReadClass = inquiryId => e => {
        e.stopPropagation();

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("eBtVTW", inquiryId)

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/Kq75uXJjnfwj3Dtj",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "S2gZ74c4XnkAxj7a",
                    password: "cuJ3SegxvZFLz5DE"
                }
            }
        ).then(respond => {
            if (!isEmpty(respond.data) && respond.data !== "ERR" && respond.data !== "hgrGEr") {
                if (this.props.removeInquiryFromNotification) {
                    this.props.removeInquiryFromNotification(inquiryId)
                }
            }

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    handleClickSingleNotification = inquiryId => e => {
        e.stopPropagation();

        const { auth } = this.props;

        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        if (auth.user_type === "CUSTOMER") {
            this.handleMarkAsReadUser(inquiryId)(e);
            window.location.href = `/dashboard/chat?question_id=${inquiryId}`;
        } else if (auth.user_type === "TUTOR") {
            this.handleMarkAsReadTutor(inquiryId)(e);
            window.location.href = `/tutor-dashboard?question_id=${inquiryId}`;
        }

    }

    render() {
        const { pathname, auth, notifications, questionNotifications, messages } = this.props;
        const { collapsed, open, openProfileMenu, openComplain, openNotifQuestion, menuMobileProfile, openNotifQuestionMobile, openNotifMobile, menuTutorMobile } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        const isTutorDashboard = auth ? auth.user_type === "TUTOR" : false;
        const activeQuestions = auth && auth.user_type === "TUTOR" ? questionNotifications
            .sort(function (a, b) { return new Date(b.modified_at) - new Date(a.modified_at); })
            .filter(item => auth && auth.subjects.some(subject => subject.id === item.subject.id))
            .filter(item =>
                auth && auth.status === 2031196204 ?
                    auth.expert_inquiry_only == 1 && auth.standard_inquiry_only == 1 ?
                        true :
                        auth.expert_inquiry_only == 1 && isEmpty(auth.standard_inquiry_only) ?
                            item.type.id !== 2045482424
                            : isEmpty(auth.expert_inquiry_only) && auth.standard_inquiry_only == 1 ?
                                item.type.id === 2045482424 :
                                false
                    : item.type.id === 2045482424
            )
            .filter(item => item.type.id === 2045482424 ? moment().diff(moment(moment(item.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") <= 5 : moment().diff(moment(moment(item.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") <= 60)
            : [];

        const activeNotifications = auth ? notifications
            .sort(function (a, b) { return new Date(b.modified_at) - new Date(a.modified_at); })
            .filter(auth.user_type === "CUSTOMER" ? this.unreadUserNotificationFilter : this.unreadTutorNotificationFilter) : []

        return (
            <>
                <header id="header">
                    <div id="navbar" className={`startp-nav ${!auth ? "not-auth" : ""}`}>
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <a className="nav-link"
                                    className="navbar-brand"
                                    href={auth && auth.user_type === "TUTOR" ? "/tutor-dashboard" : auth && auth.user_type === "CUSTOMER" ? "/dashboard" : "/"}
                                >
                                    <img src="/assets/images/logo/logo.svg" alt="logo" style={{ width: 100 }} />
                                </a>

                                <button
                                    onClick={this.toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="mobile-nav" id="navbarSupportedContent">
                                    <Collapse in={!collapsed} className="collapse-navbar" >
                                        <div className="menu-container">
                                            <Grid container>
                                                {auth &&
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} alignItems="center" justify="space-between" style={{ padding: "10px 0px 0px 0px" }}>
                                                            <Grid item xs={9} container spacing={0} alignItems="center" wrap="nowrap" onClick={() => this.setState({ menuMobileProfile: !menuMobileProfile })}>
                                                                <a className="nav-link" style={{ paddingRight: 0 }}>
                                                                    <div className="profile-image" >
                                                                        <img src={auth.profile_picture ? auth.profile_picture : "/assets/images/default-image/profile.png"} alt="image" style={{ minHeight: 50, minWidth: 50 }} />
                                                                    </div>
                                                                </a>
                                                                <div className="user-info">
                                                                    <a className="nav-link" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} >{auth.name}</a>
                                                                    {auth.status === 2031196204 &&
                                                                        <p style={{ marginLeft: "1rem" }}><i>Certified Expert Tutor</i></p>
                                                                    }
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Grid container spacing={1} alignItems="center" justify="flex-end" style={{ paddingRight: 15 }}>
                                                                    <Grid item xs={12} container spacing={0} alignItems="center" justify="flex-end">
                                                                        <a
                                                                            className="nav-link"
                                                                            ref={node => this.anchorElMobile = node}
                                                                            onClick={this.handleClickNotifMobile}
                                                                            style={{ padding: 0, position: "relative" }}
                                                                        >
                                                                            <div className="notification">
                                                                                <Icon.Bell />
                                                                            </div>
                                                                            {activeNotifications.length > 0 &&
                                                                                <div className="notification-badge">
                                                                                    {activeNotifications.length}
                                                                                </div>
                                                                            }
                                                                        </a>
                                                                    </Grid>
                                                                    {isTutorDashboard &&
                                                                        <Grid item xs={12} container spacing={0} alignItems="center" justify="flex-end">
                                                                            <a
                                                                                className="nav-link"
                                                                                ref={node => this.anchorElQuestionMobile = node}
                                                                                style={{ position: "relative", padding: 0 }}
                                                                                onClick={this.handleClickNotifQuestionMobile}
                                                                            >
                                                                                <div className="notification">
                                                                                    <Icon.MessageCircle />
                                                                                </div>
                                                                                {activeQuestions.length > 0 &&
                                                                                    <div className="notification-badge" style={{ top: 7, right: 5 }}>
                                                                                        {activeQuestions.length}
                                                                                    </div>
                                                                                }
                                                                            </a>
                                                                            <Popper
                                                                                open={openNotifQuestionMobile}
                                                                                anchorEl={this.anchorElQuestionMobile}
                                                                                placement="bottom-end"
                                                                                transition
                                                                                modifiers={{
                                                                                    flip: {
                                                                                        enabled: false,
                                                                                    },
                                                                                    preventOverflow: {
                                                                                        enabled: true,
                                                                                        boundariesElement: 'scrollParent',
                                                                                    },
                                                                                }}
                                                                                className={`popper ${!openNotifQuestionMobile ? "popper-close" : ""} popper-responsive`}
                                                                            >
                                                                                {({ TransitionProps, placement }) => (
                                                                                    <Grow
                                                                                        in={openNotifQuestionMobile}
                                                                                        style={{ transformOrigin: "0 0 0" }}
                                                                                    >
                                                                                        <ClickAwayListener onClickAway={this.handleClickNotifQuestionMobile}>
                                                                                            <div className="question-notification-list">
                                                                                                {(auth.status === 2031196204 && !isEmpty(auth.education_level)) &&
                                                                                                    <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
                                                                                                        <FormControlLabel
                                                                                                            control={
                                                                                                                <Checkbox
                                                                                                                    name="expert"
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            }
                                                                                                            classes={{
                                                                                                                label: "label-checkbox",
                                                                                                                root: "label-checkbox-root"
                                                                                                            }}
                                                                                                            label="Pertanyaan Expert"
                                                                                                            checked={auth.expert_inquiry_only === 1}
                                                                                                            onClick={this.handleChangeFilter("expert")}
                                                                                                        />
                                                                                                        <FormControlLabel
                                                                                                            control={<Checkbox name="random"
                                                                                                                color="primary"
                                                                                                            />}
                                                                                                            classes={{
                                                                                                                label: "label-checkbox",
                                                                                                                root: "label-checkbox-root"
                                                                                                            }}
                                                                                                            label="Pertanyaan Normal"
                                                                                                            checked={auth.standard_inquiry_only === 1}
                                                                                                            onClick={this.handleChangeFilter("standard")}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="overflow-questions">
                                                                                                    {activeQuestions.map(notification => {
                                                                                                        const modified_at = moment(moment(notification.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                                                                                        return (
                                                                                                            <div key={notification.id} className="single-notification" onClick={this.handleClickNotification(notification)}>
                                                                                                                <p className="title"><a>{notification.subject_name}</a>{unescape(notification.content)}</p>
                                                                                                                <p className="rate"><b>Rate</b>: {notification.type.id === 2045482424 ? Util.currencyFormat(notification.bid_price) : isEmpty(notification.type_maximum_price) ? Util.currencyFormat(notification.type_minimum_price) : Util.currencyFormat(notification.type_minimum_price) + " - " + Util.currencyFormat(notification.type_maximum_price)}</p>
                                                                                                                <p className="rate">{moment().locale("id").from(modified_at)}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                    {activeQuestions.length <= 0 &&
                                                                                                        <p style={{ padding: 10, textAlign: "center" }}>Tidak ada pertanyaan yang tersedia</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </ClickAwayListener>
                                                                                    </Grow>
                                                                                )}
                                                                            </Popper>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Popper
                                                                open={openNotifMobile}
                                                                anchorEl={this.anchorElMobile}
                                                                placement="bottom-end"
                                                                transition
                                                                modifiers={{
                                                                    flip: {
                                                                        enabled: false,
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: true,
                                                                        boundariesElement: 'scrollParent',
                                                                    },
                                                                }}
                                                                className={`popper ${!openNotifMobile ? "popper-close" : ""} popper-responsive`}
                                                            >
                                                                {({ TransitionProps, placement }) => (
                                                                    <Grow
                                                                        in={openNotifMobile}
                                                                        style={{ transformOrigin: "0 0 0" }}
                                                                    >
                                                                        <ClickAwayListener onClickAway={this.handleClickNotifMobile}>
                                                                            <div className="notification-list">
                                                                                {activeNotifications.map(notification => {
                                                                                    const modified_at = moment(moment(notification.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                                                                    const last_notified = auth.user_type === "CUSTOMER" ?
                                                                                        notification.last_read_customer_user ? moment(moment(notification.last_read_customer_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) : moment() :
                                                                                        notification.last_read_tutor_user ? moment(moment(notification.last_read_tutor_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) : moment();
                                                                                    let unit = !isEmpty(notification.expected_duration_day) ? "days" : !isEmpty(notification.expected_duration_hour) ? "hours" : !isEmpty(notification.expected_duration_minute) ? "minutes" : "months";
                                                                                    let time = !isEmpty(notification.expected_duration_day) ? notification.expected_duration_day : !isEmpty(notification.expected_duration_hour) ? notification.expected_duration_hour : !isEmpty(notification.expected_duration_minute) ? notification.expected_duration_minute : notification.expected_duration_month;

                                                                                    const isOutdated = moment().diff(moment(moment(notification.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time

                                                                                    if (notification.classNotif) {
                                                                                        return (
                                                                                            <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                                <p className="title">Kelas {notification.name} disetujui</p>
                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    } else if (notification.status_id === 2083370574 && isOutdated) {
                                                                                        return (
                                                                                            <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                                <p className="title">Pertanyaan {notification.id} selesai</p>
                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                    <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        )

                                                                                    } else if (notification.status_id === 2070045802) {

                                                                                        if (notification.type_id === 2045482424) {
                                                                                            if (moment().diff(moment(moment(notification.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 5) {
                                                                                                return (
                                                                                                    <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                                        <p className="title">Pertanyaan {notification.id} tidak bisa menemukan tutor</p>
                                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                            <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                            <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        } else if (moment().diff(moment(moment(notification.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 60) {
                                                                                            return (
                                                                                                <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                                    <p className="title">Pertanyaan {notification.id} tidak bisa menemukan tutor, pertanyaan akan dicarikan oleh admin</p>
                                                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                        <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                        <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    }

                                                                                    return (
                                                                                        <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                            <p className="title">Pertanyaan {notification.id} telah diperbarui</p>
                                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                {activeNotifications.length <= 0 &&
                                                                                    <p style={{ padding: 10, textAlign: "center" }}>Tidak ada pemberitahuan yang tersedia</p>
                                                                                }
                                                                            </div>
                                                                        </ClickAwayListener>
                                                                    </Grow>
                                                                )}
                                                            </Popper>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {auth &&
                                                    <Grid item xs={12} style={{ padding: 0 }}>
                                                        <Collapse in={menuMobileProfile}>
                                                            <ul className="navbar-nav nav ml-auto">
                                                                <li className="nav-item first-nav-item">
                                                                    <div className="balance">
                                                                        <p style={{ margin: 0 }}>Saldo</p>
                                                                        <a className="nav-link" href={isTutorDashboard ? "/tutor-dashboard/balance" : "/dashboard/balance"} className="nav-link" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} >IDR {!isEmpty(auth.balance) ? this.numberWithCommas(auth.balance) : 0}</a>
                                                                    </div>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" style={{ fontWeight: 400 }} to={isTutorDashboard ? "/tutor-dashboard/profile" : "/dashboard/profile"}>Profile</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" style={{ fontWeight: 400 }} onClick={this.handleLogOut}>Keluar</a>
                                                                </li>
                                                            </ul>
                                                        </Collapse>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <ul className="navbar-nav nav ml-auto">
                                                        {!auth &&
                                                            <>
                                                                <li className="nav-item first-nav-item">
                                                                    <a className="nav-link" href="/login">Login</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/sign-up">Daftar</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/become-tutor">Daftar menjadi tutor</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/free-access">Akses gratis</a>
                                                                </li>
                                                            </>
                                                        }
                                                        {auth && !isTutorDashboard &&
                                                            <>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/dashboard/find">Pencarian</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="#" style={{ display: "flex", alignItems: "center" }} onClick={() => this.setState({ menuTutorMobile: !menuTutorMobile })}>Tanya Tutor  <Icon.ChevronDown style={{ marginLeft: 5 }} /></a>

                                                                    <Collapse in={menuTutorMobile}>
                                                                        <ul className="navbar-nav nav ml-auto">
                                                                            <li className="nav-item first-nav-item">
                                                                                <a className="nav-link" href="/dashboard/ask-expert-tutor">Tanya Expert Tutor</a>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <a className="nav-link" href="/dashboard/ask-tutor">24/7 Bantu Tugasmu </a>
                                                                            </li>
                                                                        </ul>
                                                                    </Collapse>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/dashboard/class">Kelas online</a>
                                                                </li>
                                                            </>
                                                        }
                                                        {auth && isTutorDashboard &&
                                                            <>
                                                                <li className="nav-item first-nav-item">
                                                                    <a className="nav-link" className="nav-link" href="/tutor-dashboard/class">Kelas online</a>
                                                                </li>
                                                            </>
                                                        }
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Collapse>
                                </div>

                                <div className="desktop-nav ml-auto">
                                    <div style={{ flexGrow: 1 }}>
                                        <ul className="navbar-nav nav ml-auto">
                                            {!auth &&
                                                <>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/become-tutor">Daftar menjadi tutor</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/free-access">Akses gratis</a>
                                                    </li>
                                                </>
                                            }
                                            {auth && !isTutorDashboard &&
                                                <>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/dashboard/find">Pencarian</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="#" style={{ display: "flex", alignItems: "center" }}>Tanya Tutor  <Icon.ChevronDown style={{ marginLeft: 5 }} /></a>
                                                        <ul className="dropdown_menu">
                                                            <li className="nav-item">
                                                                <a className="nav-link" href="/dashboard/ask-expert-tutor">Tanya Expert Tutor</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" href="/dashboard/ask-tutor">24/7 Bantu Tugasmu </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/dashboard/class">Kelas online</a>
                                                    </li>
                                                </>
                                            }
                                            {auth && isTutorDashboard &&
                                                <>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/tutor-dashboard/class">Kelas online</a>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>

                                    <div className="others-option">
                                        {!auth &&
                                            <>
                                                <a className="nav-link" className="btn btn-light" href="/sign-up">Daftar</a>
                                                <a className="nav-link" className="btn btn-primary" href="/login">Login</a>
                                            </>
                                        }

                                        {auth &&
                                            <ul className="navbar-nav nav">
                                                {!isTutorDashboard &&
                                                    <>
                                                        <li className="nav-item">
                                                            <div className="balance">
                                                                <p>Saldo</p>
                                                                <a className="nav-link" href="/dashboard/balance" className="nav-link" >IDR {!isEmpty(auth.balance) ? this.numberWithCommas(auth.balance) : 0}</a>
                                                            </div>
                                                        </li>
                                                    </>
                                                }

                                                {auth && isTutorDashboard &&
                                                    <>
                                                        <li className="nav-item">
                                                            <div className="balance">
                                                                <p>Saldo</p>
                                                                <a className="nav-link" href="/tutor-dashboard/balance" className="nav-link" >IDR {!isEmpty(auth.balance) ? this.numberWithCommas(auth.balance) : 0}</a>
                                                            </div>
                                                        </li>
                                                        <li className="nav-item" style={{ marginLeft: 0 }}>
                                                            <div className="user-info">
                                                                <a className="nav-link" >{auth.name}</a>
                                                                {auth.status === 2031196204 &&
                                                                    <p style={{ marginLeft: "1rem" }}><i>Certified Expert Tutor</i></p>
                                                                }
                                                            </div>
                                                        </li>
                                                    </>
                                                }

                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        ref={node => this.anchorElProfile = node}
                                                        onClick={this.handleClickProfile}
                                                    >
                                                        <div className="profile-image">
                                                            <img src={auth.profile_picture ? auth.profile_picture : "/assets/images/default-image/profile.png"} alt="image" />
                                                        </div>
                                                    </a>
                                                    <Popper
                                                        open={openProfileMenu}
                                                        anchorEl={this.anchorElProfile}
                                                        placement="bottom-end"
                                                        transition
                                                        modifiers={{
                                                            flip: {
                                                                enabled: false,
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                boundariesElement: 'scrollParent',
                                                            },
                                                        }}
                                                        className={`popper ${!openProfileMenu ? "popper-close" : ""} popper-responsive`}
                                                    >
                                                        {({ TransitionProps, placement }) => (
                                                            <Grow
                                                                in={openProfileMenu}
                                                                style={{ transformOrigin: "0 0 0" }}
                                                            >
                                                                <ClickAwayListener onClickAway={this.handleCloseProfile()}>
                                                                    <div className="notification-list" style={{ width: 150 }}>
                                                                        <div className="single-notification" onClick={event => { this.handleCloseProfile()(event); history.push(isTutorDashboard ? "/tutor-dashboard/profile" : "/dashboard/profile") }}>
                                                                            Profil
                                                                        </div>
                                                                        <div className="single-notification" onClick={this.handleLogOut}>
                                                                            Keluar
                                                                        </div>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </Grow>
                                                        )}
                                                    </Popper>
                                                    <a
                                                        className="nav-link"
                                                        style={{ position: "relative" }}
                                                        ref={node => this.anchorEl = node}
                                                        onClick={this.handleClick}
                                                    >
                                                        <div className="notification">
                                                            <Icon.Bell />
                                                        </div>
                                                        {activeNotifications.length > 0 &&
                                                            <div className="notification-badge">
                                                                {activeNotifications.length}
                                                            </div>
                                                        }
                                                    </a>
                                                    <Popper
                                                        open={open}
                                                        anchorEl={this.anchorEl}
                                                        placement="bottom-end"
                                                        transition
                                                        modifiers={{
                                                            flip: {
                                                                enabled: false,
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                boundariesElement: 'scrollParent',
                                                            },
                                                        }}
                                                        className={`popper ${!open ? "popper-close" : ""} popper-responsive`}
                                                    >
                                                        {({ TransitionProps, placement }) => (
                                                            <Grow
                                                                in={open}
                                                                style={{ transformOrigin: "0 0 0" }}
                                                            >
                                                                <ClickAwayListener onClickAway={this.handleClose()}>
                                                                    <div className="notification-list">
                                                                        {activeNotifications.map(notification => {
                                                                            const modified_at = moment(moment(notification.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                                                            const last_notified = auth.user_type === "CUSTOMER" ?
                                                                                notification.last_read_customer_user ? moment(moment(notification.last_read_customer_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) : moment() :
                                                                                notification.last_read_tutor_user ? moment(moment(notification.last_read_tutor_user).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')) : moment();
                                                                            let unit = !isEmpty(notification.expected_duration_day) ? "days" : !isEmpty(notification.expected_duration_hour) ? "hours" : !isEmpty(notification.expected_duration_minute) ? "minutes" : "months";
                                                                            let time = !isEmpty(notification.expected_duration_day) ? notification.expected_duration_day : !isEmpty(notification.expected_duration_hour) ? notification.expected_duration_hour : !isEmpty(notification.expected_duration_minute) ? notification.expected_duration_minute : notification.expected_duration_month;

                                                                            const isOutdated = moment().diff(moment(moment(notification.active_timestamp).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), unit) >= time

                                                                            if (notification.classNotif) {
                                                                                return (
                                                                                    <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                        <p className="title">Kelas {notification.name} disetujui</p>
                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                            <a onClick={this.handleMarkAsReadClass(notification.id)}>Tandai sudah dibaca</a>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else if (notification.status_id === 2083370574 && isOutdated) {
                                                                                return (
                                                                                    <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                        <p className="title">Pertanyaan {notification.id} selesai</p>
                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                            <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                            {/* <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a> */}
                                                                                        </div>
                                                                                    </div>
                                                                                )

                                                                            } else if (notification.status_id === 2070045802) {
                                                                                if (notification.type_id === 2045482424) {
                                                                                    if (moment().diff(moment(moment(notification.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 5) {
                                                                                        return (
                                                                                            <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                                <p className="title">Pertanyaan {notification.id} tidak bisa menemukan tutor</p>
                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                    <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                    <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                } else if (moment().diff(moment(moment(notification.created_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss')), "minutes") > 60) {
                                                                                    return (
                                                                                        <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                            <p className="title">Pertanyaan {notification.id} tidak bisa menemukan tutor, pertanyaan akan dicarikan oleh admin</p>
                                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                                <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                                <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }

                                                                            return (
                                                                                <div key={notification.id} className="single-notification" style={{ padding: 20 }} onClick={this.handleClickSingleNotification(notification.id)}>
                                                                                    <p className="title">Pertanyaan {notification.id} telah diperbarui</p>
                                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5 }}>
                                                                                        <p className="rate">{last_notified.locale("id").from(modified_at)}</p>
                                                                                        <a onClick={auth.user_type === "CUSTOMER" ? this.handleMarkAsReadUser(notification.id) : this.handleMarkAsReadTutor(notification.id)}>Tandai sudah dibaca</a>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        {activeNotifications.length <= 0 &&
                                                                            <p style={{ padding: 10, textAlign: "center" }}>Tidak ada pemberitahuan yang tersedia</p>
                                                                        }
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </Grow>
                                                        )}
                                                    </Popper>
                                                    {isTutorDashboard &&
                                                        <>
                                                            <a
                                                                className="nav-link"
                                                                ref={node => this.anchorElQuestion = node}
                                                                style={{ position: "relative" }}
                                                                onClick={this.handleClickNotifQuestion}
                                                            >
                                                                <div className="notification">
                                                                    <Icon.MessageCircle />
                                                                </div>
                                                                {activeQuestions.length > 0 &&
                                                                    <div className="notification-badge">
                                                                        {activeQuestions.length}
                                                                    </div>
                                                                }
                                                            </a>
                                                            <Popper
                                                                open={openNotifQuestion}
                                                                anchorEl={this.anchorElQuestion}
                                                                placement="bottom-end"
                                                                transition
                                                                modifiers={{
                                                                    flip: {
                                                                        enabled: false,
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: true,
                                                                        boundariesElement: 'scrollParent',
                                                                    },
                                                                }}
                                                                className={`popper ${!openNotifQuestion ? "popper-close" : ""} popper-responsive`}
                                                            >
                                                                {({ TransitionProps, placement }) => (
                                                                    <Grow
                                                                        in={openNotifQuestion}
                                                                        style={{ transformOrigin: "0 0 0" }}
                                                                    >
                                                                        <ClickAwayListener onClickAway={this.handleClickNotifQuestion}>
                                                                            <div className="question-notification-list">
                                                                                {(auth.status === 2031196204 && !isEmpty(auth.education_level)) &&
                                                                                    <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    name="expert"
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            classes={{
                                                                                                label: "label-checkbox",
                                                                                                root: "label-checkbox-root"
                                                                                            }}
                                                                                            label="Pertanyaan Expert"
                                                                                            checked={auth.expert_inquiry_only === 1}
                                                                                            onClick={this.handleChangeFilter("expert")}
                                                                                        />
                                                                                        <FormControlLabel
                                                                                            control={<Checkbox name="random"
                                                                                                color="primary"
                                                                                            />}
                                                                                            classes={{
                                                                                                label: "label-checkbox",
                                                                                                root: "label-checkbox-root"
                                                                                            }}
                                                                                            label="Pertanyaan Normal"
                                                                                            checked={auth.standard_inquiry_only === 1}
                                                                                            onClick={this.handleChangeFilter("standard")}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                <div className="overflow-questions">
                                                                                    {activeQuestions.map(notification => {
                                                                                        const modified_at = moment(moment(notification.modified_at).utc().tz(`Asia/Jakarta`).format('YYYY-MM-DDTHH:mm:ss'));
                                                                                        return (
                                                                                            <div key={notification.id} className="single-notification" onClick={this.handleClickNotification(notification)}>
                                                                                                <p className="title"><a>{notification.subject_name}</a> {unescape(notification.content)}</p>
                                                                                                <p className="rate"><b>Rate</b>: {notification.type.id === 2045482424 ? Util.currencyFormat(notification.bid_price) : isEmpty(notification.type_maximum_price) ? Util.currencyFormat(notification.type_minimum_price) : Util.currencyFormat(notification.type_minimum_price) + " - " + Util.currencyFormat(notification.type_maximum_price)}</p>
                                                                                                <p className="rate">{moment().locale("id").from(modified_at)}</p>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                    {activeQuestions.length <= 0 &&
                                                                                        <p style={{ padding: 10, textAlign: "center" }}>Tidak ada pertanyaan yang tersedia</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </ClickAwayListener>
                                                                    </Grow>
                                                                )}
                                                            </Popper>
                                                        </>
                                                    }
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header >
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth
    }
}

export default connect(mapStateToProps)(Header);