import React from 'react';
import * as Icon from "react-feather";
import { connect } from "react-redux";
import isEmpty from '../../../../validation';
import store from '../../../../store';
import Axios from 'axios';
import setAuthToken from '../../../../validation/setAuthToken';
import AuthActions from '../../../../actions/AuthActions';
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

let isChange = false;

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props.auth,
            description: (!isEmpty(this.props.auth.description) && this.props.auth.description != "null") ? this.props.auth.description : "",
            showError: false,
            profileSaveSuccess: false,
            successResendEmail: false,
        }
    }

    onUnload = e => { // the method that will be used for both add and remove event
        e.preventDefault();

        if (isChange) {
            e.returnValue = '';
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }


    handleUpload = event => {
        isChange = true;

        this.setState({
            photo: event.target.files[0]
        })

        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let _this = this;

        if (file) {
            reader.onloadend = () => {
                _this.setState({
                    profile_picture: reader.result
                });
            }

            reader.readAsDataURL(file)
        }
    }

    handleClickUpload = () => {
        document.getElementById("profile-img").click();
    }

    handleChange = name => event => {
        isChange = true;

        this.setState({
            [name]: event.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        const { name, academic_type, graduation_date, photo, description, school } = this.state;

        this.setState({
            showError: isEmpty(name) || isEmpty(academic_type) || isEmpty(graduation_date),
        }, () => {
            if (!this.state.showError) {
                store.dispatch({
                    type: "SET_LOADING",
                    payload: true
                });

                var formData = new FormData();
                formData.append("ebVnkW", this.props.auth.id);
                formData.append("GqAkDj", name);
                formData.append("Kfsyxt", academic_type);
                formData.append("pEzVAf", graduation_date);
                formData.append("JvyNnS", description);
                formData.append("CNTAvj", school.id);
                formData.append("jrdHGq", photo);

                Axios.post(
                    "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/jHU8NJPyy78NQvhS",
                    formData,
                    {
                        headers: {
                            'Content-Type': "multiple/form-data"
                        },
                        auth: {
                            username: "WQh8wmHbUE23Udvb",
                            password: "StqQc3BtPcpAAfbw"
                        }
                    }
                ).then(respond => {
                    if (!isEmpty(respond.data) && respond.data !== "ERR") {
                        if (respond.data !== "mFzBqf" && respond.data !== "nUgRyV" && respond.data !== "eEySAt") {
                            isChange = false;
                            const updatedData = {};

                            respond.data.forEach(data => {
                                updatedData[data[0]] = data[1]
                            })

                            const payload = {
                                ...this.props.auth,
                                ...updatedData,
                            }; // Create JWT Payload

                            delete payload["exp"];

                            const oldDecoded = jwt_decode(localStorage.jwtToken);

                            const token = jwt.sign(
                                payload,
                                "SECRET",
                                { expiresIn: oldDecoded.exp }
                            );

                            localStorage.setItem("jwtToken", token);
                            // Decode token to get user data
                            const decoded = jwt_decode(token);

                            store.dispatch(AuthActions.setCurrentUser(decoded));

                            // Set token to Auth header
                            setAuthToken(token);

                            store.dispatch({
                                type: "UPDATE_PROFILE",
                                payload: updatedData
                            })

                            this.setState({
                                ...updatedData
                            })

                            this.setState({ profileSaveSuccess: true })
                        }
                    }

                    store.dispatch({
                        type: "SET_LOADING",
                        payload: false
                    });
                });
            }
        })
    }

    handleResendEmail = () => {
        store.dispatch({
            type: "SET_LOADING",
            payload: true
        });

        var formData = new FormData();
        formData.append("YJfFBt", this.props.auth.id)
        // formData.append("HyqMKs", "http://dev.gettutor.id.atmos.id/email/verification/")
        formData.append("HyqMKs", "http://gettutor.id/email/verification/")

        Axios.post(
            "https://skyinfra08.atmos.id:11695/Z6sJr6AYdPZ6cVUZka6Dcx/m/72634/r/KGRvh7PX5gMtuvZw",
            formData,
            {
                headers: {
                    'Content-Type': "multiple/form-data"
                },
                auth: {
                    username: "bBUv7dNzG5acLLwP",
                    password: "rNaU9fFQbZS8Sp86"
                }
            }
        ).then(respond => {
            this.setState({
                successResendEmail: respond.data !== "GsjDZh"
            })

            store.dispatch({
                type: "SET_LOADING",
                payload: false
            });
        });
    }

    render() {
        const { email, name, school, graduation_date, academic_type, description, showError, profileSaveSuccess, successResendEmail, profile_picture } = this.state;
        const { auth } = this.props;
        const { is_verified } = auth;

        return (
            <div className="profile ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title">
                                <h2>Profil Saya</h2>
                                <div className="bar"></div>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="form-container">
                            {successResendEmail &&
                                <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>Kami telah mengirimkan link untuk me-reset sandi Anda. Link ini hanya berlaku selama 40 menit! Jika belum mendapatkan link, kirim lagi
                                        <a href="#" onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>“klik disini”</a>.
                                    </span>
                                </div>
                            }
                            {!successResendEmail && isEmpty(is_verified) &&
                                <div className="alert alert-warning" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "30%", maxWidth: 50 }}>
                                        <Icon.Info />
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <span>
                                        Verifikasi email dibutuhkan untuk mengunakan fitur-fitur lain. Jika belum mendapat email, klik
                                        <a onClick={this.handleResendEmail} style={{ textDecoration: "underline", paddingLeft: 4 }}>disini</a>.
                                    </span>
                                </div>
                            }

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <div className="profile-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="profile-image">
                                                    <input id="profile-img" type="file" name="profile-img" style={{ display: "none" }} onChange={this.handleUpload} accept="image/*" />
                                                    <div className="upload-profile-img" onClick={this.handleClickUpload} style={{ position: isEmpty(profile_picture) ? "relative" : "absolute" }}>
                                                        <Icon.Camera />
                                                    </div>
                                                    {!isEmpty(profile_picture) &&
                                                        <img src={profile_picture}
                                                            alt="image"
                                                            onClick={this.handleClickUpload}
                                                        />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="profile-content">
                                                    <textarea
                                                        className="form-control description"
                                                        placeholder="Deskripsikan diri Anda"
                                                        rows="8"
                                                        style={{ height: "100%" }}
                                                        maxLength="140"
                                                        onChange={this.handleChange("description")}
                                                        value={description}
                                                        disabled={isEmpty(is_verified)}
                                                    >
                                                    </textarea>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <a className="nav-link" href="/change-password" style={{ textDecoration: "underline" }}>
                                                    GANTI SANDI
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8}>
                                    <div className="data-container">
                                        <div className="row">
                                            {profileSaveSuccess &&
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="alert alert-success" role="alert" style={{ display: "flex", alignItems: "center" }}>
                                                        <Icon.Info /><div style={{ width: 10 }}> </div>Update profile berhasil!
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label >Pekerjaan <span className="required">*</span></label>
                                                    <div className="who-you-are">
                                                        <div className="row">
                                                            <div className="radio col-lg-2">
                                                                <input type="radio" id="student" name="radio-group" value={2028490125} checked={academic_type == 2028490125} onChange={this.handleChange("academic_type")} disabled={!isEmpty(this.props.auth.academic_type) || isEmpty(is_verified)} />
                                                                <label htmlFor="student">Murid</label>
                                                            </div>
                                                            <div className="radio col-lg-3">
                                                                <input type="radio" id="former-student" name="radio-group" value={2051331416} checked={academic_type == 2051331416} onChange={this.handleChange("academic_type")} disabled={!isEmpty(this.props.auth.academic_type) || isEmpty(is_verified)} />
                                                                <label htmlFor="former-student">Mahasiswa</label>
                                                            </div>
                                                            <div className="radio col-lg-2">
                                                                <input type="radio" id="educator" name="radio-group" value={2000760850} checked={academic_type == 2000760850} onChange={this.handleChange("academic_type")} disabled={!isEmpty(this.props.auth.academic_type) || isEmpty(is_verified)} />
                                                                <label htmlFor="educator">Pengajar</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!showError && isEmpty(academic_type) &&
                                                        <label style={{ marginTop: -5, fontSize: 10, color: "red" }}>Anda tidak dapat mengubah data ini setelah disimpan</label>
                                                    }
                                                    <div className="invalid-feedback" style={{ display: showError && isEmpty(academic_type) ? "block" : "none", marginTop: -5, fontSize: 10, color: "red" }}>
                                                        Cantumkan pekerjaan Anda yang valid.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Email <span className="required">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        value={email}
                                                        disabled
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan email Anda yang valid.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Universitas/Sekolah </label>
                                                    <input
                                                        type="text"
                                                        value="Bakti Mulya 400"
                                                        name="school"
                                                        className="form-control"
                                                        value={school.name}
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Nama Lengkap <span className="required">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="fullname"
                                                        className={`form-control ${showError && isEmpty(name) ? "is-invalid" : ""}`}
                                                        value={name}
                                                        onChange={this.handleChange("name")}
                                                        disabled={isEmpty(is_verified)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Cantumkan nama lengkap Anda yang valid.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Tanggal Graduasi <span className="required">*</span></label>
                                                    <input
                                                        type="date"
                                                        name="graduation_date"
                                                        className={`form-control ${showError && isEmpty(graduation_date) ? "is-invalid" : ""}`}
                                                        value={moment(graduation_date).utc().tz(`Asia/Jakarta`).format("YYYY-MM-DD")}
                                                        onChange={this.handleChange("graduation_date")}
                                                        disabled={!isEmpty(this.props.auth.graduation_date) || isEmpty(is_verified)}
                                                    />
                                                    {!showError && isEmpty(graduation_date) &&
                                                        <label style={{ marginTop: 5, fontSize: 10, color: "red" }}>Anda tidak dapat mengubah data ini setelah disimpan</label>
                                                    }
                                                    <div className="invalid-feedback">
                                                        Cantumkan tanggal graduasi Anda yang valid.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <button className={`btn btn-primary submit-btn ${isEmpty(is_verified) ? "disable-btn" : ""}`} disabled={isEmpty(is_verified)} onClick={this.handleSubmit} >
                                                    SIMPAN
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>

                <div className="shape1">
                    <img src="/assets/images/abstract/shape1.png" alt="shape" />
                </div>
                <div className="shape5">
                    <img src="/assets/images/abstract/shape5.png" alt="shape" />
                </div>
                <div className="shape6 rotateme">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/assets/images/abstract/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/assets/images/abstract/shape2.svg" alt="shape" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.masterData.auth
    }
}

export default connect(mapStateToProps)(Profile);